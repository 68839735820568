import React, { useContext } from 'react';
import {
  Box, Card, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdAccountBalance } from 'react-icons/md';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { OrgansII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listOrgans: OrgansII;
}

export default function OrgansList({
  listOrgans,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    subBox: {
      width: '100%',
      maxWidth: '1440px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    card: {
      width: '100%',
      minHeight: 160,
      height: 'auto',
      marginBottom: 24,
      borderRadius: 4,
      color: theme.palette.primary.main,
      backgroundColor: colors.colorBackgroundSecundary,
      border: `1px solid ${colors.borderShadowsColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
      },
      cursor: 'pointer',
    },
    textTitle: {
      marginTop: 24,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'uppercase',
      color: colors.accentColor,
    },
    textSubTitle: {
      marginTop: 8,
      fontFamily: 'Roboto',
      textTransform: 'capitalize',
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: colors.textBody,
      '&:hover': {
        color: colors.accentColor,
      },
    },
    tag: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.secondary.main,
      color: '#fff',
      padding: '2px 14px',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    boxIcon: {
      width: 112,
      margin: 24,
      height: 112,
      borderRadius: 5,
      backgroundColor: 'rgba(15, 111, 183, 0.05)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 0,
      },
    },
    iconStyle: {
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
      fontSize: 31,
    },
    boxInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    organUpdateText: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textBody,
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.main}>
      <Box
        className={classes.subBox}
      >
        {listOrgans.results.map((organ) => (
          <Card
            onClick={(): void => history.push(`/orgao/${organ.slug}`)}
            key={organ.id}
            className={classes.card}
          >
            <Box className={classes.boxIcon}>
              <MdAccountBalance className={classes.iconStyle} />
            </Box>
            <Box className={classes.boxInfo}>
              <Typography
                className={classes.textTitle}
              >
                {organ.sigla.toUpperCase()}
              </Typography>
              <Typography
                className={classes.textSubTitle}
              >
                {organ.nome.toLowerCase()}
              </Typography>
              <Typography
                className={classes.organUpdateText}
              >
                <span className="material-icons" style={{ color: colors.textBody, marginRight: 5 }}>
                  edit_calendar
                </span>
                Última modificação:
                {' '}
                {organ.updated_at ? format(new Date(organ.updated_at), 'dd/MM/yyyy') : 'Nunca'}
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
