import React, { useContext, useMemo, useState } from 'react';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import colorContext from '../../../../Context/colorContext';
import BoxPerfil from '../../../../Components/BoxPerfil';
import { ServiceCountProfileI } from '../..';

interface Props {
  services: ServiceCountProfileI;
}

export default function Search({ services }: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 600,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      fontFamily: 'Roboto',
      padding: '80px 5px',
    },
    container: {
      minHeight: 670,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: colors.colorBackgroundSearch,
    },
    contentWelcome: {
      marginTop: '2.5rem',
    },
    titleWelcome: {
      fontSize: theme.typography.pxToRem(40),
      color: colors.textBody,
      fontWeight: 500,
      lineHeight: '3rem',
      textAlign: 'center',
    },
    subTitleWelcome: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      lineHeight: '1.8rem',
      textAlign: 'center',
      color: colors.textBody,
      margin: '12px 0px',
    },
    boxInput: {
      width: '100%',
      display: 'flex',
    },
    iconButton: {
      borderRadius: 0,
      color: colors.textBody,
      padding: 20,
      minWidth: 50,
      textAlign: 'center',
      marginRight: 13,
    },
    inputSearch: {
      width: '100%',
      height: 75,
      outline: 'none',
      paddingLeft: 20,
      paddingTop: 10,
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 700,
      borderRadius: 20,
      border: 'none',
      color: colors.textBody,
      backgroundColor: colors.colorBackgroundSecundary,
      '&::placeholder': {
        color: isActiveContrast ? colors.textAccentColor : '#C0B9B9',
        fontSize: theme.typography.pxToRem(30),
        fontFamily: 'Roboto',
        fontWeight: 600,
        lineHeight: '2.188rem',
      },
    },
    titleSearch: {
      fontWeight: 700,
      letterSpacing: 0.2,
      textAlign: 'center',
      color: colors.textAccentColor,
    },
    textLegend: {
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      minWidth: 'fit-content',
      margin: '0px 9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(30),
        textAlign: 'center',
      },
    },
    containerPerfil: {
      width: '100%',
      marginTop: 38,
    },
    contentSearchInput: {
      maxWidth: 947,
      borderRadius: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.colorBackground,
      border: `2px solid ${isActiveContrast ? colors.textAccentColor : '#F2F2F2'}`,
    },
    contentLegend: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
    },
    barSeparate: {
      width: '100%',
      height: 0,
      border: '0.5px solid #555555',
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    containerCards: {
      display: 'flex',
      justifyContent: 'center',
      gap: 15,
      flexWrap: 'wrap',
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const [textSearch, setTextSearch] = useState<string>('');
  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '');
      history.push(`/buscar/q=${formattedSearchText}`);
      setTextSearch('');
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.contentWelcome}>
        <Typography className={classes.titleWelcome}>
          Bem-vindo ao
          AP Digital
        </Typography>
        <Typography className={classes.subTitleWelcome}>
          O portal de serviços do Governo do Amapá
        </Typography>
      </Box>
      <form
        onSubmit={submitSearch}
        className={classes.contentSearchInput}
      >
        <div className={classes.boxInput}>
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            placeholder="O que você procura?"
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
          >
            <FiSearch size={30} />
          </IconButton>
        </div>
      </form>
      <Box className={classes.containerPerfil}>
        <Box className={classes.contentLegend}>
          <div className={classes.barSeparate} />
          <Typography className={classes.textLegend}>
            Selecione abaixo seu perfil para encontrar seus serviços
          </Typography>
          <div className={classes.barSeparate} />
        </Box>
        <Box className={classes.containerCards}>
          <BoxPerfil
            type="cidadao"
            count={services?.cidadao}
          />
          <BoxPerfil
            type="empresa"
            count={services?.empresa}
          />
          <BoxPerfil
            type="servidor"
            count={services?.servidor}
          />
        </Box>
      </Box>
    </Box>
  );
}
