import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getDataUserSso, getSSOToken } from '../../services/auth';

interface ParamsProps {
  id: string;
  tokenSSO: string;
}

export interface UserDataProps {
  contato: {
    id: number;
    email: string;
    email_verificado: boolean;
    celular: string
  };
  cpf: string;
  nome: string;
  nome_social: string;
}

export default function AttendanceOnlineMobileRedirect(): JSX.Element {
  const { id, tokenSSO } = useParams<ParamsProps>();
  const history = useHistory();

  function handleRedirect(tokenSiseci: string, user: UserDataProps) {
    history.push({
      pathname: `/mobile/atendimento_online/${id}`,
      state: { tokenSiseci, user },
    });
  }

  async function fetchUsersData() {
    try {
      const { data } = await getSSOToken(tokenSSO);

      const { data: user } = await getDataUserSso(tokenSSO);

      handleRedirect(
        data.key,
        user[0],
      );
    } catch (error) {
      history.replace('/');
      throw new Error('Algo deu errado', error);
    }
  }

  useEffect(() => {
    if (id && tokenSSO) {
      fetchUsersData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tokenSSO]);

  return (
    <></>
  );
}
