import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../Context/colorContext';

export default function Licence(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 61,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#333333',
      color: colors.textBody,
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      color: colors.textAccentColor,
      fontFamily: 'Roboto',
      lineHeight: '1.313rem',
      textAlign: 'start',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        width="100%"
        padding="0px 15px"
      >
        <Typography
          className={classes.title}
        >
          {(window as any)._env_.REACT_APP_LICENCE}
        </Typography>
      </Box>
    </Box>
  );
}
