import React, { useContext } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { SearchII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listSearch: SearchII;
}

export default function ServiceList({
  listSearch,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    containerList: {
      display: 'grid',
      maxWidth: '1380px',
      width: '100%',
      columnGap: 40,
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    icon: {
      color: colors.textBody,
    },
    itemList: {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      gap: 20,
      margin: '15px 0px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: '2.1rem',
      cursor: 'pointer',
      maxWidth: 412,
      width: '100%',
      color: colors.textBody,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.main}>
      <Box
        className={classes.containerList}
      >
        {listSearch?.results?.map((service) => (
          <Typography
            key={service.slug}
            className={classes.itemList}
            component="span"
            onClick={(): void => history.push(`/servico/${service.slug}`)}
            paragraph
          >
            <div className={classes.icon}>
              <HiOutlineChevronDoubleRight size={35} />
            </div>
            {service.titulo}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
