import React, {
  useEffect, useState, useContext,
} from 'react';
import { apisiseci } from '../../services/api';
import { getServiceTypeUser, searchServiceForPublic } from '../../services/servico';
import { getOrder } from '../../utils/managerContents';
import {
  ChannelAttendante,
  Indicators,
  Search,
  ServiceProfile,
} from './components';
import RefContext from '../../Context/refContext';

export interface ES {
  count: number;
  next: number;
  previous: number;
  current: number;
  total_pages: number;
}
export interface Results {
  id: number;
  slug: string;
  titulo: string;
  icone: string;
}
export interface Category extends ES {
  results: Results[];
}

export interface ChannelAttendanteI {
  icone: string;
  titulo: string;
  url: string;
}

export interface AppsI extends ES {
  results: [
    {
      id: number;
      nome: string;
      descricao: string;
      url_apple: string;
      url_banner: string;
      url_google: string;
      url_logo: string;
    },
  ]
}

export interface Service {
  id: number;
  publico_especifico: string;
  slug: string;
  titulo: string;
  categoria_nome: string;
  categoria_icone: string;
}

export interface ServiceProfileI {
  cidadao?: Service[];
  servidor?: Service[];
  empresa?: Service[];
  gestao_publica?: Service[];
}

export interface ServiceCountProfileI {
  cidadao?: number;
  servidor?: number;
  empresa?: number;
}

export interface EstatisticI {
  agendamentos: number;
  agendamentos_realizados: number;
  atendimentos: number;
  atendimentos_realizados: number;
  servicos_agendamento: number;
  servicos_online: number;
  servicos_total: number;
  total_solicitacoes: number;
}
export interface VideoProps extends ES {
  results: [
    {
      titulo: string;
      resumo: string;
      video_id: string;
    },
  ]
}
export interface ListBanners {
  titulo: string;
  url_imagem: string;
  url: string;
}

export default function Home(): JSX.Element {
  // const [, setCategories] = useState<Category>();
  // const [, setApps] = useState<AppsI>();
  // const [, setBanners] = useState<ListBanners[]>();
  // const [, setVideos] = useState<VideoProps>();
  // const [newsList, setNewsList] = useState<News>();
  // const [channels, setChannels] = useState<ChannelAttendanteI[]>([]);
  const [serviceProfile, setServiceProfile] = useState<ServiceProfileI>();
  const [serviceCountForProfile, setServiceCountForProfile] = useState<ServiceCountProfileI>();
  const [estatistic, setEstatistic] = useState<EstatisticI>();

  const {
    refService, refEstatistic, refAttendance,
  } = useContext(RefContext);

  // const refApps = useRef(null);

  // const externalNoticiasLink = (window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL_LINK ?? '';
  // const externalNoticiasBanner = (window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL_BANNER ?? '';
  // const haveNews = (window as any)._env_.REACT_APP_HAVE_NOTICIAS;

  // const getVideos = async () => {
  //   const { data } = await apinoticias.get('/cms/videos');
  //   setVideos(data);
  // };

  // const getCategories = async () => {
  //   const { data } = await apisiseci.get('/cms/temas/');
  //   setCategories(data);
  // };

  // const getBanners = async () => {
  //   const { data } = await apinoticias.get('/cms/banners');
  //   setBanners(data);
  // };

  // const getChannelAttendances = async () => {
  //   const { data } = await apisiseci.get('/cms/canais_atendimento/');
  //   setChannels(data);
  // };

  // const getApps = async () => {
  //   const { data } = await apisiseci.get('/cms/aplicativos/');
  //   setApps(data);
  // };

  const getServiceProfile = async (userType: 'Cidadão' | 'Servidor' | 'Empresa' | 'Gestão Pública') => {
    const { data } = await getServiceTypeUser(userType);
    if (userType === 'Cidadão') {
      setServiceProfile((oldState) => ({
        ...oldState,
        cidadao: [...data],
      }));
    }
    if (userType === 'Servidor') {
      setServiceProfile((oldState) => ({
        ...oldState,
        servidor: [...data],
      }));
    }
    if (userType === 'Empresa') {
      setServiceProfile((oldState) => ({
        ...oldState,
        empresa: [...data],
      }));
    }
    if (userType === 'Gestão Pública') {
      setServiceProfile((oldState) => ({
        ...oldState,
        gestao_publica: [...data],
      }));
    }
  };

  const getAllServicesForPublic = async (type: 'Cidadão' | 'Servidor' | 'Empresa') => {
    try {
      const { data } = await searchServiceForPublic(type);
      if (type === 'Cidadão') {
        setServiceCountForProfile((oldService) => ({
          ...oldService,
          cidadao: data?.count,
        }));
      }

      if (type === 'Servidor') {
        setServiceCountForProfile((oldService) => ({
          ...oldService,
          servidor: data?.count,
        }));
      }

      if (type === 'Empresa') {
        setServiceCountForProfile((oldService) => ({
          ...oldService,
          empresa: data?.count,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEstatistic = async () => {
    const { data } = await apisiseci.get('estatisticas/');
    setEstatistic(data);
  };

  // const getNews = async () => {
  //   const { data } = await getAllNews(1);
  //   const newsData = adapterNews(data);
  //   setNewsList(newsData);
  // };

  useEffect(() => {
    getServiceProfile('Cidadão');
    getServiceProfile('Servidor');
    getServiceProfile('Empresa');
    getServiceProfile('Gestão Pública');
    getEstatistic();
    getAllServicesForPublic('Cidadão');
    getAllServicesForPublic('Servidor');
    getAllServicesForPublic('Empresa');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Options
        refCategory={refCategory}
        refService={refService}
        refEstatistic={refEstatistic}
        refAttendance={refAttendance}
        refApps={refApps}
        refNoticias={refNoticies}
      /> */}
      <Search services={serviceCountForProfile} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* {categories?.results && getOrder('categorias') !== -1 && categories.results.length > 0 && (
          <div ref={refCategory} style={{ order: getOrder('categorias') }}>
            <Categories listCategories={categories} />
          </div>
        )} */}
        {(serviceProfile?.cidadao || serviceProfile?.empresa || serviceProfile?.servidor || serviceProfile?.gestao_publica) && getOrder('servicos_destaque') !== -1 && (
        <div ref={refService} style={{ order: getOrder('servicos_destaque') }}>
          <ServiceProfile listServiceProfile={serviceProfile} />
        </div>
        )}
        {estatistic && getOrder('indicadores') !== -1 && (
        <div ref={refEstatistic} style={{ order: getOrder('indicadores') }}>
          <Indicators indicators={estatistic} />
        </div>
        )}
        {/* {banners?.length > 0 && getOrder('banners') !== -1 && (
          <div style={{ order: getOrder('banners') }}>
            <Banners listBanners={banners} />
          </div>
        )} */}
        {getOrder('canais_atendimento') !== -1 ? (
          <div ref={refAttendance} style={{ order: getOrder('canais_atendimento') }}>
            <ChannelAttendante />
          </div>
        ) : null}
        {/* {haveNews === 'true' && newsList?.results?.length && externalNoticiasBanner !== 'true' && getOrder('noticias') !== -1 && (
        <div ref={refNoticias} style={{ order: getOrder('noticias') }}>
          <NewsComponent newsList={newsList} />
        </div>
        )} */}
        {/* {externalNoticiasLink && externalNoticiasBanner === 'true' && getOrder('noticias') !== -1 && (
        <div ref={refNoticias} style={{ order: getOrder('noticias') }}>
          <NewsWithBanner link={externalNoticiasLink} banner={externalNoticiasBanner} />
        </div>
        )}
        {videos?.results?.length > 0 && (
          <div style={{ order: 5 }}>
            <Videos videoList={videos.results} />
          </div>
       )} */}
        {/* {apps?.results?.length && getOrder('app_governo') !== -1 ? (
          <div ref={refApps} style={{ order: getOrder('app_governo') }}>
            <Apps listApps={apps} />
          </div>
        ) : null} */}
      </div>
    </>
  );
}
