import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Card, CardActionArea, CardContent, CircularProgress, IconButton, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { FiHome, FiSearch } from 'react-icons/fi';
import { MdArrowForwardIos } from 'react-icons/md';
import colorContext from '../../Context/colorContext';
import { CardMostAccessedServices } from './components';
import { getServiceTypeUser } from '../../services/servico';
import { listAllCategory } from '../../services/categoria';
import { Category, EstatisticI } from '../Home';
import { ChannelAttendante, Indicators } from '../Home/components';
import { apisiseci } from '../../services/api';
import typeServices from '../../utils/typeServiceOfProfile';

import RenderIcons from '../../utils/renderIcons';

type Profile = 'cidadao' | 'empresa' | 'servidor';
type ProfileName = 'Cidadão' | 'Empresa' | 'Servidor';

export interface Service {
  id: number;
  publico_especifico: string;
  slug: string;
  titulo: string;
  categoria_nome: string;
  categoria_icone: string;
}

export interface ServiceProfileI {
  cidadao?: Service[];
  servidor?: Service[];
  empresa?: Service[];
}

export default function ServiceByProfile(): JSX.Element {
  const [serviceProfile, setServiceProfile] = useState<ServiceProfileI>();
  const [listCategory, setListCategory] = useState<Category>();
  const [estatistic, setEstatistic] = useState<EstatisticI>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const history = useHistory();
  const { pathname } = useLocation();
  const currentUrl = pathname.split('/')[3] as Profile;
  const { colors, typeTheme } = useContext(colorContext);

  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      backgroundColor: colors.colorBackground,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    containerSearch: {
      width: '100%',
      maxWidth: 1380,
      minHeight: 357,
      background: isActiveContrast ? colors.colorBackgroundSecundary : typeServices[currentUrl].background,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      width: 130,
      height: 130,
    },
    containerInfo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 26,
    },
    title: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      textAlign: 'center',
      color: colors.textBody,
    },
    boxActions: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '14px 14px 0px 14px',
      borderRadius: 4,
      border: `1px solid ${colors.accentColor}`,
      maxWidth: 400,
      marginBottom: 44,
    },
    titleMain: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      lineHeight: '38px',
      color: isActiveContrast ? colors.textAccentColor : '#BDBDBD',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    textTitle: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(128),
      fontWeight: 500,
      color: isActiveContrast ? colors.textAccentColor : typeServices[currentUrl].color,
      marginLeft: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(32),
      },
    },
    boxActivy: {
      width: '100%',
      textAlign: 'center',
    },
    boxFavorite: {
      width: '100%',
      textAlign: 'center',
    },
    contentSearchInput: {
      maxWidth: 947,
      borderRadius: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.colorBackgroundSecundary,
      border: `2px solid ${isActiveContrast ? colors.textAccentColor : '#032876'}`,
      margin: '20px 0px',
    },
    boxInput: {
      width: '100%',
      display: 'flex',
    },
    inputSearch: {
      width: '100%',
      height: 75,
      outline: 'none',
      paddingLeft: 20,
      paddingTop: 10,
      fontFamily: 'Roboto',
      fontSize: 14,
      borderRadius: 20,
      border: 'none',
      color: colors.textBody,
      backgroundColor: colors.colorBackgroundSecundary,
      '&::placeholder': {
        color: isActiveContrast ? colors.textAccentColor : '#C0B9B9',
        fontSize: theme.typography.pxToRem(30),
        fontFamily: 'Roboto',
        fontWeight: 600,
        lineHeight: '2.188rem',
      },
    },
    iconButton: {
      borderRadius: 0,
      color: colors.textBody,
      padding: 20,
      minWidth: 50,
      textAlign: 'center',
      marginRight: 13,
    },
    breadcrumb: {
      margin: '30px 0px',
      display: 'flex',
      width: '100%',
      maxWidth: 1380,
      alignItems: 'center',
    },
    contentLegend: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    barSeparate: {
      width: '100%',
      height: 0,
      border: '0.5px solid #555555',
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    textLegend: {
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      minWidth: 'fit-content',
      margin: '0px 9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    boxMostServices: {
      width: '100%',
      maxWidth: '1380px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '1.188rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'initial',
        padding: '0px 10px',
      },
    },
    button: {
      padding: 0,
      color: colors.textBody,
    },
    listCategories: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: 26,
      maxWidth: '1380px',
      width: '100%',
      paddingBottom: '6px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'initial',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      width: 313,
      height: 248,
      border: '1px solid #FFFFFF',
      backgroundColor: colors.colorBackgroundSecundary,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    titleCard: {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: 700,
      color: colors.textBody,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    arrowIcon: {
      fontSize: 32,
      color: isActiveContrast ? colors.textAccentColor : '#BDBDBD',
    },
  }));
  const classes = useStyles();

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '');
      history.push(`/buscar/q=${formattedSearchText}`);
      setTextSearch('');
    }
  };

  const getServiceProfile = async (userType: ProfileName) => {
    setIsLoading(true);
    const { data } = await getServiceTypeUser(userType);
    setIsLoading(false);
    if (userType === 'Cidadão') {
      setServiceProfile((oldState) => ({
        ...oldState,
        cidadao: [...data],
      }));
    }
    if (userType === 'Servidor') {
      setServiceProfile((oldState) => ({
        ...oldState,
        servidor: [...data],
      }));
    }
    if (userType === 'Empresa') {
      setServiceProfile((oldState) => ({
        ...oldState,
        empresa: [...data],
      }));
    }
  };

  const getAllCategories = async () => {
    setIsLoadingCategories(true);
    try {
      const { data } = await listAllCategory();
      setListCategory(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const getEstatistic = async () => {
    const { data } = await apisiseci.get('estatisticas/');
    setEstatistic(data);
  };

  useEffect(() => {
    if (currentUrl) {
      getServiceProfile(typeServices[currentUrl].title as ProfileName);
      getAllCategories();
      getEstatistic();
    }
  }, [currentUrl]);

  return (
    <Box className={classes.main}>
      <Box className={classes.containerSearch}>
        <Box className={classes.containerInfo}>
          <img
            alt={typeServices[currentUrl].title}
            src={typeServices[currentUrl].img}
            width="100%"
            height="auto"
            className={classes.img}
          />
          <Typography className={classes.textTitle}>
            {typeServices[currentUrl].title}
          </Typography>
        </Box>
        <Typography className={classes.title}>
          O que você procura?
        </Typography>
        <form
          onSubmit={submitSearch}
          className={classes.contentSearchInput}
        >
          <div className={classes.boxInput}>
            <input
              value={textSearch}
              onChange={(e): void => setTextSearch(e.target.value)}
              className={classes.inputSearch}
              placeholder="O que você procura?"
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
            >
              <FiSearch size={30} />
            </IconButton>
          </div>
        </form>
      </Box>
      <Box className={classes.breadcrumb}>
        <Box
          display="flex"
          gridGap={10}
        >
          <IconButton
            onClick={() => history.push('/')}
            className={classes.button}
          >
            <FiHome size={32} />
          </IconButton>
          <MdArrowForwardIos className={classes.arrowIcon} size={32} />
        </Box>
        <Typography className={classes.titleMain}>
          {`Serviços para o ${typeServices[currentUrl].title}`}
        </Typography>
      </Box>
      <Box id="servicos" className={classes.contentLegend}>
        <div className={classes.barSeparate} />
        <Typography className={classes.textLegend}>
          Serviços em destaque
        </Typography>
        <div className={classes.barSeparate} />
      </Box>
      {(isLoading && !serviceProfile) ? <CircularProgress /> : (
        <Box
          className={classes.boxMostServices}
        >
          {serviceProfile && serviceProfile[currentUrl].map((service) => (
            <CardMostAccessedServices
              key={service.slug}
              service={service}
              type={currentUrl}
            />
          ))}
        </Box>
      )}
      <Box className={classes.contentLegend}>
        <div className={classes.barSeparate} />
        <Typography className={classes.textLegend}>
          Categorias de serviço
        </Typography>
        <div className={classes.barSeparate} />
      </Box>
      {(isLoadingCategories && !listCategory?.results) ? <CircularProgress /> : (
        <Box
          className={classes.listCategories}
        >
          {listCategory?.results?.map((category) => (
            <Card
              key={category.slug}
              className={classes.card}
              onClick={(): void => history.push(`/categoria/${category.slug}/${currentUrl}`)}
            >
              <CardActionArea
                style={{
                  height: '100%',
                }}
              >
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    style={{ fontSize: 150, color: isActiveContrast ? colors.textAccentColor : typeServices[currentUrl].color }}
                    className="material-icons"
                  >
                    {category.icone}
                  </span>
                  <Typography
                    align="center"
                    variant="h5"
                    className={classes.titleCard}
                  >
                    {category.titulo}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      )}
      <ChannelAttendante />
      {estatistic && (
        <Indicators indicators={estatistic} />
      )}
    </Box>
  );
}
