// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
} from '@material-ui/core';
import {
  createGenerateClassName,
  makeStyles, StylesProvider, Theme, ThemeProvider,
} from '@material-ui/core/styles';
import { MdClose, MdEvent, MdCheckCircle } from 'react-icons/md';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid, subDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FileObject } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';
import { Steps } from 'intro.js-react';
import { SearchI } from '../../../SearchService';
import {
  agendar, checarHorarios, incluirDependente,
} from '../../../../services/agendamento';
// eslint-disable-next-line import/extensions
import { UserData } from '../../../../services/user';
import { ES } from '../../../Home';
import { cpfMask, removeCpfMask, validateCpf } from '../../../../utils/cpfFormat';
import dateFormatToBR from '../../../../utils/dateFormat';
import colorContext from '../../../../Context/colorContext';
import themeCalendarProvider from '../../../../services/calendarTheme';

interface Props {
  open: boolean;
  service: SearchI;
  handleClose: () => void;
}

interface SavedDependenteProps {
  agendamento: number;
  cpf: string;
  cpf_solicitante: string;
  data_nascimento: string;
  grau_parentesco: string;
  id: number;
  nome: string;
}

interface SavedScheduleProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

export interface Schedule {
  horarios: string[];
}

interface ScheduleUser {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  prioridade: boolean;
  servico: number;
  setor: number;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: string;
}

export interface Holliday extends ES {
  results: [{
    agendamento: boolean;
    atendimento: boolean;
    data: string;
    id: number;
    titulo: string;
  }]
}

export default function ModalAttendancePresential({
  open,
  service,
  handleClose,
}: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const generateClassName = createGenerateClassName({ productionPrefix: 'online', disableGlobal: true });
  const materialTheme = themeCalendarProvider(colors, typeTheme);
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& label.Mui-focused': {
        color: colors.textBody,
      },
      '& .MuiFormControl-root': {
        '& .MuiInputLabel-outlined': {
          color: colors.textBody,
        },
        '& .MuiIconButton-root': {
          color: colors.textBody,
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.accentColor,
        },
        '& label': {
          color: colors.textBody,
        },
        '&:hover fieldset': {
          borderColor: colors.accentColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.accentColor,
        },
        '& .MuiIconButton-root-1737': {
          color: colors.textBody,
        },
        '& .MuiSelect-iconOutlined': {
          color: colors.textBody,
          height: 24,
          width: 24,
        },
      },
    },
    rootPicker: {
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: 'red',
      },
    },
    rootDialog: {
      '& .MuiDialogContent-root': {
        overflowY: 'hidden',
      },
    },
    header: {
      background: colors.accentColor,
    },
    textHeader: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      width: '90%',
      color: colors.colorBackground,
    },
    formControl: {
      marginTop: 20,
      marginBottom: 20,
      // margin: theme.spacing(1),
      width: '100%',
    },
    menuItem: {
      input: {
        color: colors.accentColor,
      },
      color: colors.accentColor,
      backgroundColor: colors.colorBackgroundSecundary,
      '&:hover': {
        backgroundColor: colors.colorBackground,
        color: colors.accentColor,
      },
      '&.Mui-selected': {
        backgroundColor: colors.menuItemColor,
        color: colors.accentColor,
        '&:hover': {
          backgroundColor: colors.menuItemColor,
          color: colors.accentColor,
        },
      },
    },
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: colors.colorBackground,
    },
    textSchedule: {
      color: colors.textBody,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20),
      marginTop: 30,
    },
    textSubtitleSchedule: {
      color: colors.textBody,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      marginTop: 20,
    },
    textSubtitleScheduleBox: {
      width: 165,
      height: 30,
      borderRadius: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(225, 230, 239, 0.3)',
      color: colors.textBody,
    },
    iconUpload: {
      color: '#9E9E9E',
    },
    rootUpload: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 140,
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    inputCheckbox: {
      color: colors.textBody,
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    inputCheckboxText: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      textAlign: 'left',
      color: colors.textBody,
    },
    colorInputText: {
      color: colors.accentColor,
    },
    inputForm: {
      margin: '24px 0px',
    },
    box: {
      borderRadius: 4,
      border: `1px solid ${colors.borderShadowsColor}`,
      margin: '51px 32px',
      backgroundColor: colors.colorBackground,
    },
    titleBox: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      margin: '2px 0px 30px 0px',
    },
    boxInfoConcluded: {
      margin: '20px 32px',
      borderRadius: 4,
      padding: '16px 24px',
      border: '1px solid #0BB07B',
      backgroundColor: 'rgba(11, 176, 123, 0.05)',
      minHeight: 109,
    },
    infoConcluded: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textInfoUnity: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginTop: 16,
      marginBottom: 8,
    },
    textInfoDateTime: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginBottom: 2,
    },
    buttonPrimary: {
      margin: '10px 0px',
      backgroundColor: colors.badgeColor,
      color: colors.textAccentColor,
      width: 186,
      height: 44,
      '&:hover': {
        backgroundColor: colors.favoriteBoxColor,
        color: colors.textAccentColor,
      },
    },
    textButtonPrimary: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    buttonOutlined: {
      width: 186,
      height: 44,
      padding: '14px 16px',
      marginLeft: '0px !important',
    },
    textButtonOutlined: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      fontFamily: 'Roboto',
      fontWeight: 700,
      textTransform: 'uppercase',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    boxMain: {
      backgroundColor: colors.colorBackgroundSecundary,
    },
    textLabel: {
      color: colors.textBody,
    },
    cssFocused: {},
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: colors.accentColor,
      },
      '&:hover $notchedOutline': {
        borderColor: colors.accentColor,
      },
    },
    notchedOutline: {
      borderColor: colors.accentColor,
      '&:hover': {
        borderColor: colors.accentColor,
      },
    },
    boxConcludedMain: {
      backgroundColor: colors.colorBackgroundSecundary,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [checkedDependente, setCheckedDependente] = useState(false);
  const [unitySelected, setUnitySelected] = useState < string >('');
  const [cpfDependente, setCpfDependente] = useState<string>('');
  const [errorCpfDependente, setErrorCpfDependente] = useState<undefined | string>(undefined);
  const [nameDependente, setNameDependente] = useState<string>('');
  const [errorNameDependente, setErrorNameDependente] = useState<undefined | string>(undefined);
  const [birthDateDependente, setBirthDateDependente] = useState<Date>(subDays(new Date(), 30));
  const [dependenteSelected, setDependenteSelected] = useState<string>('');
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [scheduleSelected, setScheduleSelected] = useState < string >('');
  const [scheduleService, setScheduleService] = useState < string | Schedule | undefined >();
  const [scheduleServiceUser, setScheduleServiceUser] = useState<ScheduleUser | string | undefined>();
  const [savedSchedule, setSavedSchedule] = useState<SavedScheduleProps>(undefined);
  const [savedDependente, setSavedDependente] = useState<SavedDependenteProps>(undefined);
  const [showBoxData, setShowBoxData] = useState(false);
  const [hiddenInfoShedule, setHiddenInfoSchedule] = useState(true);
  // const [hollidays, setHolliday] = useState < Holliday >();
  const [loading, setLoading] = useState < boolean >(false);
  const [loadingDepente, setLoadingDepedente] = useState(false);
  const [showIntro, setShowIntro] = useState<boolean>(false);

  const [, setFiles] = useState < FileObject[] >([]);

  const [, setOpenUploads] = useState < boolean >(false);

  /* const disableWeekends = (date: MaterialUiPickersDate): boolean => (date
    ? date.getDay() === 0 || date.getDay() === 6 || format(date, 'yyyy-MM-dd') === '2021-02-16'
    : false); */

  // const getHollidays = async () => {
  //   const { data } = await feriados();
  //   setHolliday(data);
  // };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, service.id);
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else {
          setScheduleService('Algo inesperado ocorreu');
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    const dataStorage: string | null = localStorage.getItem('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData) {
      try {
        const { data, status } = await agendar(
          token,
          userData.cpf,
          service.id,
          unitySelected,
          service.setor_id,
          'Portal',
          selectedDate,
          scheduleSelected,
          checkedDependente,
        );
        setScheduleServiceUser(data);

        if (status === 201) {
          setSavedSchedule(data);
        }

        if (data.para_dependente) {
          setHiddenInfoSchedule(true);
          setShowBoxData(false);
        } else {
          setHiddenInfoSchedule(false);
          setShowBoxData(true);
        }

        setLoading(false);
      } catch (err) {
        if (err.response.data.message) {
          setScheduleServiceUser(err.response.data.message);
        } else if (err?.response?.data.length > 0) {
          setScheduleServiceUser(err?.response?.data[0]);
        } else {
          setScheduleServiceUser('Algo inesperado ocorreu.');
        }
        setLoading(false);
      }
    }
  };

  const handleDependente = async () => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    const dataStorage: string | null = localStorage.getItem('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    const cpfDependenteWithoutMask = removeCpfMask(cpfDependente);

    if (nameDependente && nameDependente.length < 6) {
      setErrorNameDependente('Nome deve ter no mínimo 6 caracteres.');
      return;
    }

    if (cpfDependenteWithoutMask) {
      if (!validateCpf(cpfDependenteWithoutMask) || cpfDependenteWithoutMask.length < 11) {
        setErrorCpfDependente('CPF inválido.');
        return;
      }
    }
    if (savedSchedule.para_dependente) {
      try {
        setLoadingDepedente(true);
        const { data, status } = await incluirDependente(
          savedSchedule.id,
          cpfDependenteWithoutMask,
          nameDependente,
          birthDateDependente,
          userData.cpf,
          dependenteSelected,
          token,
        );
        if (status === 201) {
          setSavedDependente(data);
          setShowBoxData(true);
          setHiddenInfoSchedule(false);
        }
        setLoadingDepedente(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDepedente(false);
      }
    }
  };

  const options = {
    nextLabel: 'Próximo',
    prevLabel: 'Anterior',
    doneLabel: 'Finalizar',
    autoPosition: false,
  };

  const optionsDependentes = [
    {
      id: 0,
      option: 'Filho',
    },
    {
      id: 1,
      option: 'Filha',
    },
    {
      id: 2,
      option: 'Enteado',
    },
    {
      id: 3,
      option: 'Enteada',
    },
    {
      id: 4,
      option: 'Pai',
    },
    {
      id: 5,
      option: 'Mãe',
    },
    {
      id: 6,
      option: 'Avó',
    },
    {
      id: 7,
      option: 'Avô',
    },
    {
      id: 8,
      option: 'Tutor',
    },
    {
      id: 9,
      option: 'Tutora',
    },
  ];

  const steps = [
    {
      intro: '<h3>Como agendar seu atendimento presencial.</h3>',
      position: 'bottom',
      tooltipClass: 'boxToolSmall',
    },
    {
      element: '#input-unidade',
      intro: '<h3 style="align-text: center">Selecione a unidade.</h3>',

    },
    {
      element: '#input-data',
      intro: '<h3>Selecione o dia.</h3>',

    },
    {
      title: 'Escolher o horário',
      element: '#input-horario',
      intro: 'Se não existir conflitos nos horários disponíveis, será exibido uma lista e ao selecionar um dos horários, se o usuário não tiver agendado para aquela unidade e serviço, será possível confirmar o agendamento, caso contrário, serão exibidos alguns textos de erro.',
      position: 'top',
    },
    {
      element: '#confirm-agendamento',
      intro: '<h3>Confirmação do agendamento.</h3>',
      position: 'top',
      tooltipClass: 'boxConfirmTool',
    },
    {
      title: 'Janela de confirmação de agendamentos',
      intro: '<p>Ao ser confirmado seu agendamento, será exibido a janela em seguida.</p><br/><img src="/assets/images/agendamento.png" width="450px"/><p>Clicando em ver agendamentos, será redirecionado para lista de agendamentos.</p>',
      // position: 'bottom',
      tooltipClass: 'boxToolSmall',
    },
    {
      title: 'Lista dos meus agendamentos',
      intro: '<p>Na lista de agendamentos é possível filtrar, imprimir o comprovante ou cancelar o mesmo.</p><br/><img src="/assets/images/agendamentos.png" width="550px"/>',
      // position: 'bottom',
      tooltipClass: 'boxToolList',
    },
    {
      title: 'Filtrar o status do agendamento',
      intro: '<p>O usuário poderá filtrar seus agendamentos, se for necessário.</p><br/><img src="/assets/images/filtro.png" width="auto" id="img-filtro"/>',
      tooltipClass: 'boxToolSmall',
    },
    {
      title: 'Imprimir comprovante',
      intro: '<p>Caso queira imprimir o comprovante será exibido a janela a seguir.</p><br/><img src="/assets/images/card-imprimir.png" width="450px"/>',
      // position: 'bottom',
      tooltipClass: 'boxToolLarge',
    },
    {
      title: 'Cancelar o agendamento',
      intro: '<p>Caso queira cancelar o agendamento será exibido a janela a seguir.</p><br/><img src="/assets/images/card-cancelar.png" width="450px"/>',
      tooltipClass: 'boxToolLarge',
    },
  ];

  const handleCheckDependente = () => {
    setCheckedDependente(!checkedDependente);
  };

  useEffect(() => {
    setUnitySelected('');
    setScheduleSelected('');
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    setFiles([]);
    setOpenUploads(false);
    // getHollidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    <>

      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          handleClose();
          history.go(0);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: classes.rootDialog }}
      >
        <IconButton
          onClick={handleClose}
          className={classes.buttonClose}
        >
          <MdClose style={{ width: 24, height: 24 }} />
        </IconButton>
        <Steps
          enabled={showIntro}
          initialStep={0}
          steps={steps}
          options={options}
          onExit={() => setShowIntro(false)}
        />

        <div hidden={showBoxData} className={classes.boxMain}>
          <DialogTitle
            className={classes.header}
          >
            <Typography className={classes.textHeader}>
              <MdEvent style={{ marginRight: 15, width: 24, height: 24 }} />
              Agendamento presencial
            </Typography>
          </DialogTitle>
          {loading ? (
            <Box style={{ marginTop: 20, textAlign: 'center' }}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              {!savedSchedule ? (
                <Box className={classes.box}>
                  <DialogContent>
                    <Typography className={classes.titleBox}>Faça seu agendamento</Typography>
                    <div id="input-check">
                      <FormControlLabel
                        className={classes.inputCheckbox}
                        control={(
                          <Checkbox
                            checked={checkedDependente}
                            onChange={handleCheckDependente}
                            name="dependente"
                            style={{ color: colors.accentColor }}
                          />
                        )}
                        label="Dependente"
                      />
                      <Typography className={classes.inputCheckboxText}>Selecione caso este agendamento seja para seu dependente.</Typography>
                    </div>
                    <div id="input-unidade">
                      <FormControl
                        classes={{ root: classes.root }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.textLabel} id="demo-simple-select-outlined-label">Selecione a unidade</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          onChange={(event): void => {
                            setUnitySelected(event.target.value as string);
                            getSchedulers(selectedDate, event.target.value as string);
                          }}
                          label="Selecione a unidade"
                          classes={{
                            select: classes.menuItem,
                          }}
                          MenuProps={{
                            MenuListProps: {
                              disablePadding: true,
                            },
                          }}
                        >
                          {service.servicosUnidade.map((unity) => (
                            <MenuItem
                              key={unity.id}
                              className={classes.menuItem}
                              value={unity.unidade.slug_unidade}
                            >
                              {unity.unidade.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div id="input-data">
                      <FormControl
                        classes={{ root: classes.root }}
                        error={typeof (scheduleService) === 'string' || typeof (scheduleServiceUser) === 'string'}
                        disabled={!unitySelected}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <StylesProvider generateClassName={generateClassName}>
                          <ThemeProvider theme={materialTheme}>
                            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                                disableFuture={false}
                                error={typeof (scheduleService) === 'string'
                                || typeof (scheduleServiceUser) === 'string'}
                                minDateMessage="Você não pode agendar em dias anteriores"
                                invalidDateMessage="A data informada é inválida"
                                minDate={new Date()}
                              // className={classes.rootPicker}
                                // InputProps={{
                                //   className: classes.menuItem,
                                //   classes: {
                                //     adornedEnd: classes.adornedEnd,
                                //   },
                                // }}
                                // PopoverProps={{
                                //   PaperProps: {
                                //     style: {
                                //       backgroundColor: '#CED2DB',
                                //     },
                                //   },
                                // }}
                                autoOk
                                disabled={!unitySelected}
                                variant="inline"
                                inputVariant="outlined"
                                label="Selecione o dia"
                                format="dd/MM/yyyy"
                                placeholder="00/00/0000"
                                value={selectedDate || null}
                                InputAdornmentProps={{ position: 'end', color: colors.textBody }}
                                onChange={(date) => {
                                  if (date && isValid(date)) {
                                    handleDateChange(date);
                                    if (unitySelected) {
                                      getSchedulers(date, unitySelected);
                                    }
                                  }
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </StylesProvider>
                        {typeof (scheduleService) === 'string' && (
                        <FormHelperText>{scheduleService}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div id="input-horario">
                      <FormControl
                        classes={{ root: classes.root }}
                        disabled={!unitySelected || !selectedDate || typeof (scheduleService) === 'string'}
                        variant="outlined"
                        className={classes.formControl}
                        error={typeof (scheduleServiceUser) === 'string'}
                      >
                        <InputLabel className={classes.textLabel} id="demo-simple-select-filled-label">Escolha o horário</InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          classes={{
                            select: classes.menuItem,
                          }}
                          value={scheduleSelected || ''}
                          MenuProps={{
                            MenuListProps: {
                              disablePadding: true,
                            },
                          }}
                          onChange={(event): void => {
                            setScheduleSelected(event.target.value as string);
                          }}
                          label="Escolha o horário"
                        >
                          {typeof (scheduleService) !== 'string' && scheduleService?.horarios?.map((schedule) => (
                            <MenuItem key={schedule} className={classes.menuItem} value={schedule}>
                              {schedule}
                            </MenuItem>
                          ))}
                        </Select>
                        {typeof (scheduleServiceUser) === 'string' && (
                        <FormHelperText>{scheduleServiceUser}</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    <Box style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    >
                      <Button
                        style={{
                          textTransform: 'uppercase',
                          color: colors.textAccentColor,
                          borderRadius: 4,
                          marginTop: 20,
                          marginBottom: 20,
                          backgroundColor: colors.cardAccentColor,
                        }}
                        disabled={
                            !unitySelected
                            || !selectedDate
                            || !scheduleSelected
                            || typeof (scheduleService) === 'string'
                            || typeof (scheduleService) === 'undefined'
                          }
                        color="primary"
                        id="salvar-agendamento"
                        variant="contained"
                        onClick={schedulerService}
                      >
                        Salvar
                      </Button>
                    </Box>
                  </DialogContent>
                </Box>
              ) : (
                <Box className={classes.boxInfoConcluded}>
                  <Box className={classes.infoConcluded}>
                    <Typography className={classes.titleBox} style={{ margin: 0 }}>Agendamento Salvo com sucesso</Typography>
                    <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
                  </Box>
                  <Box>
                    <Typography className={classes.textInfoDateTime}>
                      {dateFormatToBR(savedSchedule?.data, false)}
                      {' '}
                      -
                      {' '}
                      {savedSchedule?.hora}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}

          {savedSchedule?.para_dependente && (
            <>
              {loadingDepente ? (
                <Box style={{ marginTop: 20, textAlign: 'center' }}>
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <>
                  {!savedDependente ? (
                    <Box className={classes.box}>
                      <DialogContent>
                        <Typography className={classes.titleBox}>dados do dependente</Typography>
                        <div className="input-name">
                          <FormControl
                            variant="outlined"
                            fullWidth
                            error={typeof (errorNameDependente) === 'string'}
                            className={classes.formControl}
                            classes={{ root: classes.root }}
                          >
                            <InputLabel className={classes.textLabel} htmlFor="outlined-name-dependente">
                              Nome Completo
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-name-dependente"
                              type="text"
                              label="Nome Completo"
                              autoFocus
                              className={classes.colorInputText}
                              value={nameDependente}
                              onChange={(event) => {
                                setNameDependente(event.target.value);
                                setErrorNameDependente(undefined);
                              }}
                              labelWidth={70}
                              inputProps={{ minLength: 6, maxLength: 100 }}
                            />
                            {typeof (errorNameDependente) === 'string' && (
                            <FormHelperText>{errorNameDependente}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div id="input-cpf">
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.formControl}
                            classes={{ root: classes.root }}
                            disabled={!nameDependente}
                            error={typeof (errorCpfDependente) === 'string'}
                          >
                            <InputLabel className={classes.textLabel} htmlFor="outlined-name-dependente">
                              CPF
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-name-dependente"
                              type="text"
                              label="cpf"
                              autoFocus
                              className={classes.colorInputText}
                              value={cpfDependente}
                              onChange={(event) => {
                                setCpfDependente(cpfMask(event.target.value));
                                setErrorCpfDependente(undefined);
                              }}
                              labelWidth={70}
                              inputProps={{ maxLength: 14, minLength: 14 }}
                            />
                            {typeof (errorCpfDependente) === 'string' && (
                            <FormHelperText>{errorCpfDependente}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div id="input-date">
                          <FormControl
                            classes={{ root: classes.root }}
                            error={typeof (scheduleService) === 'string' || typeof (scheduleServiceUser) === 'string'}
                            disabled={!cpfDependente}
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <StylesProvider generateClassName={generateClassName}>
                              <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                                    InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Data de nascimento"
                                    minDateMessage="A data não pode ser superior ao mês atual"
                                    invalidDateMessage="A data informada é inválida"
                                    format="dd/MM/yyyy"
                                    maxDate={subDays(new Date(), 30)}
                                    disabled={!cpfDependente}
                                    value={birthDateDependente}
                                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                                    onChange={(date) => {
                                      if (date && isValid(date)) {
                                        setBirthDateDependente(date);
                                      }
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </ThemeProvider>
                            </StylesProvider>
                            {typeof (scheduleService) === 'string' && (
                            <FormHelperText>{scheduleService}</FormHelperText>
                            )}
                          </FormControl>
                        </div>

                        <div id="input-parentesco">
                          <FormControl
                            classes={{ root: classes.root }}
                            variant="outlined"
                            className={classes.formControl}
                            disabled={!birthDateDependente || typeof (scheduleService) === 'string'}
                          >
                            <InputLabel className={classes.textLabel} id="demo-simple-select-outlined-label">Grau de parentesco</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              onChange={(event): void => {
                                setDependenteSelected(event.target.value as string);
                              }}
                              value={dependenteSelected || ''}
                              label="Grau de parentesco"
                              classes={{
                                select: classes.menuItem,
                              }}
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                              }}
                            >
                              {optionsDependentes.map((unity) => (
                                <MenuItem
                                  key={unity.id}
                                  className={classes.menuItem}
                                  value={unity.option}
                                >
                                  {unity.option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                        >
                          <Button
                            onClick={handleDependente}
                            style={{
                              textTransform: 'uppercase',
                              color: colors.textAccentColor,
                              borderRadius: 4,
                              marginTop: 20,
                              backgroundColor: colors.cardAccentColor,
                            }}
                            disabled={
                                  !nameDependente || !cpfDependente || !birthDateDependente || !dependenteSelected
                                }
                            color="primary"
                            id="salvar-dependente"
                            variant="contained"
                          >
                            Salvar
                          </Button>
                        </Box>
                      </DialogContent>
                    </Box>
                  ) : (
                    <Box className={classes.boxInfoConcluded}>
                      <Box className={classes.infoConcluded}>
                        <Typography className={classes.titleBox} style={{ margin: 0 }}>Informações salva com sucesso</Typography>
                        <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
                      </Box>
                      <Box>
                        <Typography className={classes.textInfoUnity}>{savedDependente.nome}</Typography>
                        <Typography className={classes.textInfoDateTime}>{savedDependente.cpf}</Typography>
                        <Typography className={classes.textInfoDateTime}>{dateFormatToBR(savedDependente.data_nascimento, false)}</Typography>
                        <Typography className={classes.textInfoDateTime}>{savedDependente.grau_parentesco}</Typography>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </>
          )}

          {/* <DialogActions
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginBottom: 15,
            }}
          >
            <Hidden xsDown>
              <Button disabled />
            </Hidden>
            <Button
              style={{
                textTransform: 'none',
                color: '#fff',
                borderRadius: 0,
                minWidth: 200,
                marginTop: 20,
              }}
              disabled={
              !unitySelected
              || !selectedDate
              || !scheduleSelected
              || typeof (scheduleService) === 'string'
              || typeof (scheduleService) === 'undefined'
              || (checkedDependente) ? (!checkedDependente || !nameDependente || !cpfDependente || !birthDateDependente || !dependenteSelected) : false
            }
              color="primary"
              id="confirm-agendamento"
              variant="contained"
              onClick={() => setFinallyIsOk(true)}
            >
              Finalizar
            </Button>
            <Hidden xsDown>
              <Button
                style={{
                  marginTop: 20,
                }}
                onClick={handleAttendanceHelp}
              >
                <LiveHelp color="primary" />
              </Button>
            </Hidden>
          </DialogActions> */}
        </div>
        <div
          className={classes.boxConcludedMain}
          hidden={hiddenInfoShedule}
        >
          <DialogContent style={{ textAlign: 'center' }}>
            <Box>
              <MdCheckCircle style={{
                color: '#3CD278', width: 80, height: 80, fontSize: 24,
              }}
              />
            </Box>
            <Typography className={classes.textSchedule}>
              Agendamento concluído, enviamos para você um comprovante, verifique no seu email.
            </Typography>
            <Typography className={classes.textSubtitleSchedule}>
              Não esqueça de levar um documento com foto no dia marcado.
            </Typography>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                className={classes.textSubtitleScheduleBox}
                style={{ fontWeight: 600, marginTop: 30 }}
              >
                {(typeof selectedDate?.getMonth === 'function') && format(selectedDate, 'dd/MM/yyyy')}
                {' '}
                às
                {' '}
                {scheduleSelected}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{
            margin: '10px 0px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <Button
              className={classes.buttonPrimary}
              onClick={(): void => history.push('/workspace/meus_agendamentos')}
            >
              <Typography className={classes.textButtonPrimary}>Meus agendamentos</Typography>
            </Button>
            <Button
              onClick={() => {
                handleClose();
                history.push('/');
              }}
              className={classes.buttonOutlined}
            >
              <Typography className={classes.textButtonOutlined}>Voltar para o início</Typography>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
