import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Box, CircularProgress, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { ES, EstatisticI } from '../Home';
import { searchService } from '../../services/servico';
import colorContext from '../../Context/colorContext';
import ListTypeProfile from './components/ListTypeProfile';
import { ChannelAttendante, Indicators } from '../Home/components';
import { apisiseci } from '../../services/api';

interface Params {
  slug: string;
}

export interface ServicoUnidadeI {
  id: number;
  agendamento: boolean;
  atendimento: boolean;
  unidade: {
    ativo: boolean;
    bairro: string;
    complemento: string;
    endereco: string;
    nome: string;
    slug_unidade: string;
    source: string;
  };
}

interface JornadaI {
  id: number;
  titulo: string;
  ordem: number;
  conteudo: string;
}

export interface SearchI {
  acesso_externo: boolean;
  agendavel: boolean;
  categoria_icone: string;
  categoria_nome: string;
  categoria_slug: string;
  created_at: string;
  custo: string;
  descricao: string;
  id: number;
  informacoes_extra: string;
  jornada: JornadaI[];
  online: boolean;
  orgao_nome: string;
  orgao_sigla: string;
  orgao_slug: string;
  publico: string;
  publico_especifico: ['Cidadão' | 'Servidor' | 'Empresa'];
  requisitos: string;
  servicosUnidade: ServicoUnidadeI[];
  setor_id: string;
  slug: string;
  tempo: number;
  tempo_online: number;
  tempo_total: number;
  tipo_tempo: string;
  titulo: string;
  total_avaliacao: number;
  url_externo: string;
  updated_at: string;
}

export interface SearchII extends ES {
  results: SearchI[];
}

type ProfilePublicServiceOption = 'Cidadão' | 'Empresa' | 'Servidor';

export default function SearchService(): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
    boxActions: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '14px 14px 0px 14px',
      borderRadius: 4,
      border: `1px solid ${colors.accentColor}`,
      maxWidth: 400,
      marginBottom: 44,
    },
    textTitleSelected: {
      cursor: 'pointer',
      padding: '15px 40px',
      color: colors.colorBackgroundSecundary,
      backgroundColor: colors.accentColor,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.accentColor,
      textTransform: 'uppercase',
      padding: '15px 40px',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
    },
    boxActivy: {
      width: '100%',
      textAlign: 'center',
    },
    boxFavorite: {
      width: '100%',
      textAlign: 'center',
    },
    boxSearch: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1440,
    },
    searchMain: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: isActiveContrast ? colors.colorBackgroundSecundary : '#F2F2F2',
      padding: '32px 52px',
    },
    contentSearchInput: {
      maxWidth: 1440,
      borderRadius: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.colorBackgroundSecundary,
      border: `2px solid ${isActiveContrast ? colors.textAccentColor : '#0C326F'}`,
    },
    inputSearch: {
      width: '100%',
      height: 75,
      outline: 'none',
      paddingLeft: 20,
      paddingTop: 10,
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 700,
      borderRadius: 20,
      border: 'none',
      color: colors.textBody,
      backgroundColor: colors.colorBackgroundSecundary,
      '&::placeholder': {
        color: isActiveContrast ? colors.textAccentColor : '#C0B9B9',
        fontSize: theme.typography.pxToRem(30),
        fontFamily: 'Roboto',
        fontWeight: 600,
        lineHeight: '2.188rem',
      },
    },
    titleSearch: {
      fontWeight: 700,
      letterSpacing: 0.2,
      textAlign: 'center',
      color: colors.textAccentColor,
    },
    boxInput: {
      width: '100%',
      display: 'flex',
    },
    iconButton: {
      borderRadius: 0,
      color: colors.textBody,
      padding: 20,
      minWidth: 50,
      textAlign: 'center',
      marginRight: 13,
    },
    textResult: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      color: isActiveContrast ? colors.textAccentColor : '#828282',
      marginTop: 10,
      padding: '0px 52px',
    },
    contentLegend: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    textLegend: {
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      minWidth: 'fit-content',
      margin: '0px 9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    barSeparate: {
      width: '100%',
      height: 0,
      border: '0.5px solid #555555',
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    barSeparateInput: {
      width: 3,
      height: 50,
      backgroundColor: colors.textBody,
      margin: '12px 0px',
      display: 'block',
    },
    containerList: {
      padding: '0px 15px',
      width: '100%',
      maxWidth: 1440,
    },
    containerSearch: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      backgroundColor: isActiveContrast ? colors.colorBackgroundSecundary : '#F2F2F2',
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const history = useHistory();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [textSearch, setTextSearch] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [listServicesOfPerson, setListServicesOfPerson] = useState<SearchI[]>();
  const [listServicesOfCompany, setListServicesOfCompany] = useState<SearchI[]>();
  const [listServicesOfServer, setListServicesOfServer] = useState<SearchI[]>();
  const [estatistic, setEstatistic] = useState<EstatisticI>();

  const onFilterOfTypePublicService = (type: ProfilePublicServiceOption, listOfServices: SearchI[]) => listOfServices.filter((currentService) => currentService.publico_especifico[0] === type);

  const handlePublicProfileSave = (data: SearchII) => {
    if (data && data?.results?.length > 0) {
      const servicesForCidadao = onFilterOfTypePublicService('Cidadão', data.results);
      const servicesForEmpresa = onFilterOfTypePublicService('Empresa', data.results);
      const servicesForServidor = onFilterOfTypePublicService('Servidor', data.results);

      setListServicesOfPerson(servicesForCidadao);
      setListServicesOfCompany(servicesForEmpresa);
      setListServicesOfServer(servicesForServidor);
    } else {
      setListServicesOfPerson(undefined);
      setListServicesOfCompany(undefined);
      setListServicesOfServer(undefined);
    }
  };

  const getSearch = (page: number) => {
    setListSearch(undefined);
    setTextSearch(params.slug);
    try {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha.execute((window as any)._env_.REACT_APP_RECAPTCHA, { action: 'submit' }).then(async (token) => {
          if (token) {
            setLoadingSearch(true);
            const { data } = await searchService(params.slug, page);
            handlePublicProfileSave(data);
            setListSearch(data);
            setLoadingSearch(false);
          }
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const submitSearch = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '');
      history.push(`/buscar/q=${formattedSearchText}`);
    }
  }, [history, textSearch]);

  const onResetInput = useCallback(() => setTextSearch(''), []);

  const getEstatistic = async () => {
    const { data } = await apisiseci.get('estatisticas/');
    setEstatistic(data);
  };

  useEffect(() => {
    const keyDownHandle = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        submitSearch(event);
      }
    };

    document.addEventListener('keydown', keyDownHandle);

    return () => {
      document.removeEventListener('keydown', keyDownHandle);
    };
  }, [submitSearch]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${(window as any)._env_.REACT_APP_RECAPTCHA}`;
    script.addEventListener('load', () => {
      getSearch(1);
      // getNews(1);
    });
    document.body.appendChild(script);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    getEstatistic();
  }, []);

  return (
    <Box className={classes.main}>
      <Box className={classes.containerSearch}>
        <Box className={classes.boxSearch}>
          <Box className={classes.searchMain}>
            <form
              onSubmit={submitSearch}
              className={classes.contentSearchInput}
            >
              <div className={classes.boxInput}>
                <input
                  value={textSearch}
                  onChange={(e): void => setTextSearch(e.target.value)}
                  className={classes.inputSearch}
                  placeholder="O que você procura?"
                />
                {textSearch !== '' && (
                <>
                  <IconButton
                    type="submit"
                    onClick={onResetInput}
                    className={classes.iconButton}
                  >
                    <MdClose size={30} />
                  </IconButton>
                  <span className={classes.barSeparateInput} />
                </>
                )}
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                >
                  <FiSearch size={30} />
                </IconButton>
              </div>
            </form>
          </Box>
          {listSearch && (
          <Typography className={classes.textResult}>
            A pesquisa retornou
            {' '}
            <strong>{listSearch.results.length}</strong>
            {' '}
            resultados
          </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.contentLegend}>
        <div className={classes.barSeparate} />
        <Typography className={classes.textLegend}>
          Resultados
        </Typography>
        <div className={classes.barSeparate} />
      </Box>
      {loadingSearch ? <CircularProgress /> : (
        <Box className={classes.containerList}>
          <ListTypeProfile
            list={listServicesOfPerson || undefined}
            type="cidadao"
          />
          <ListTypeProfile
            list={listServicesOfCompany || undefined}
            type="empresa"
          />
          <ListTypeProfile
            list={listServicesOfServer || undefined}
            type="servidor"
          />
        </Box>
      )}
      <ChannelAttendante />
      {estatistic && (
        <Indicators indicators={estatistic} />
      )}
    </Box>
  );
}
