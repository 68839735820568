import React, { useContext, useMemo } from 'react';
import {
  Card, CardActionArea, CardContent, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import colorContext from '../../../../Context/colorContext';
import { Results } from '../../../Home';
import typeServiceOfProfile from '../../../../utils/typeServiceOfProfile';

interface Props {
  category: Results;
  profile: 'cidadao' | 'empresa' | 'servidor';
  categorySelected: undefined | string;
}

export default function CategoriesList({
  category,
  profile,
  categorySelected,
}: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const useStyles = makeStyles((theme: Theme) => ({
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      width: 313,
      height: 248,
      border: '1px solid #FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      backgroundColor: isActiveContrast ? colors.colorBackgroundSecundary : 'initial',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    cardSelected: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      width: 313,
      height: 248,
      // height: '100%',
      backgroundColor: isActiveContrast ? colors.colorBackgroundSecundary : '#F2F2F2',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    titleCard: {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: 700,
      color: colors.textBody,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card
      key={category.slug}
      className={categorySelected === category.slug ? classes.cardSelected : classes.card}
      onClick={(): void => history.push(`/categoria/${category.slug}/${profile}`)}
    >
      <CardActionArea
        style={{
          height: '100%',
        }}
      >
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{ fontSize: 150, color: isActiveContrast ? colors.textAccentColor : typeServiceOfProfile[profile].color }}
            className="material-icons"
          >
            {category.icone}
          </span>
          <Typography
            align="center"
            variant="h5"
            className={classes.titleCard}
          >
            {category.titulo}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
