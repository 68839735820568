/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useEffect, useState, useContext, useCallback, useMemo,
} from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Link,
  Popover,
  MenuItem,
  useMediaQuery,
  Avatar,
  Snackbar,
  CircularProgress,
  Modal,
  Drawer,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare, FaPhoneAlt,
} from 'react-icons/fa';
import { FiLogIn, FiMenu } from 'react-icons/fi';
import { useHistory, useLocation, Link as LinkRouter } from 'react-router-dom';
import { BsFileWordFill, BsFillGridFill } from 'react-icons/bs';
import { FaSquareXTwitter, FaCalendarDay } from 'react-icons/fa6';
import {
  MdAccessibility,
  MdExitToApp, MdExpandMore, MdHeadsetMic, MdHome, MdOutlineEmail,
} from 'react-icons/md';
import Alert from '@material-ui/lab/Alert';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { cpfMask } from '../../utils/cpfFormat';
import {
  getUserData as getUserDataRequest, clearStorage, loginRedirectPrivider, logout, getImageProfile,
} from '../../services/auth';
// eslint-disable-next-line import/extensions
import { UserData } from '../../services/user';
// import TermoDialog from '../TermoDialog';
// import ModalAlertsDialog from '../ModalAlertsDialog';
// import AlertAcceptCookies from '../AlertAcceptCookies';
import { getInitialsFromName } from '../../utils/nameFormat';
import ColorContext from '../../Context/colorContext';
import Close from './icon/Close';

export default function Header(): JSX.Element {
  const { colors, setTypeTheme, typeTheme } = useContext(ColorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: '7.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 10px',
      background: colors.colorBackground,
      color: colors.accentColor,
    },
    text: {
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      margin: '0px 15px',
      color: colors.textBody,
      cursor: 'pointer',
    },
    textButton: {
      fontWeight: 500,
      fontStyle: 'normal',
      marginLeft: 3,
      fontSize: 14,
      color: colors.accentColor,
    },
    textName: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.accentColor,
    },
    avatar: {
      height: 32,
      width: 32,
      backgroundColor: colors.colorBackground,
      marginRight: 10,
      border: `1px solid ${colors.accentColor}`,
    },
    menuItem: {
      minHeight: 42,
      color: '#737B7D',
      minWidth: 236,
      backgroundColor: colors.colorBackgroundSecundary,
      '&:hover': {
        background: colors.colorBackground,
      },
    },
    textMenuItem: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: 10,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: colors.textBody,
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
    buttonAccessibility: {
      display: 'inline-block',
      padding: 6,
      minWidth: 0,
      minHeight: 0,
      fontSize: 14,
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.accentColor,
    },
    boxAccessibility: {
      display: 'flex',
      alignItems: 'center',
    },
    boxSubMenu: {
      height: 41,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.accentColorBackground,
      padding: '0 15px',
    },
    boxSub: {
      width: '1441px',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    separate: {
      width: 0,
      border: `1px solid ${isActiveContrast ? colors.textAccentColor : '#FCFCFC'}`,
      minHeight: 33,
      height: '100%',
      margin: '0px 0.313rem',
    },
    linksBoxSubMenu: {
      margin: '0px 0.313rem',
      fontSize: theme.typography.pxToRem(18),
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: colors.textAccentColor,
      cursor: 'pointer',
      lineHeight: '1.318rem',
      display: 'flex',
      justifyContent: 'center',
      alignItens: 'center',
      gap: 2,
    },
    textLocale: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 15px',
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#3E3E3E',
      borderLeft: '1px solid #DBDFE2',
      borderRight: '1px solid #DBDFE2',
    },
    textFontContrast: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.textBody,
    },
    boxInfo: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.accentColor,
    },
    btnSocial: {
      color: colors.textAccentColor,
      padding: 0,
      margin: '0 10px',
    },
    boxIcon: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.accentColor,
      '&:hover': {
        backgroundColor: colors.accentColor,
        opacity: 0.8,
      },
    },
    iconSocial: {
      width: 25,
      height: 25,
    },
    img: {
      width: '100%',
      maxWidth: '365px',
      height: 'auto',
      maxHeight: '85px',
    },
    linkRouterContainer: {
      width: 180,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    buttonGovBr: {
      borderRadius: '100em',
      padding: '0px 16px',
      textTransform: 'initial',
      backgroundColor: '#1351B4',
      color: '#fff',
      fontSize: theme.typography.pxToRem(16.8),
      '&:hover': {
        backgroundColor: '#1351B4',
        color: '#fff',
      },
    },
    textGovBr: {
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: '1.318rem',
      marginLeft: '0.75rem',
      color: colors.textAccentColor,
    },
    textGovBrMobile: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: '1.318rem',
      marginLeft: '0.75rem',
      color: '#2D9CDB',
    },
    contentLogin: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    contentLogo: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonMenu: {
      padding: 0,
      marginRight: 48,
    },
    contactInformation: {
      width: '100%',
      display: 'flex',
      maxWidth: 1300,
      backgroundColor: colors.colorBackground,
      position: 'relative',
      margin: 'auto',
      border: 0,
    },
    containerInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '30px 0px',
    },
    iconButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: isActiveContrast ? colors.textAccentColor : '#939393',
    },
    logo: {
      maxWidth: 220,
      height: 149,
    },
    title: {
      fontSize: theme.typography.pxToRem(48),
      fontWeight: 700,
      textAlign: 'center',
      lineHeight: '56.35px',
      margin: '44px 0px',
      color: colors.textBody,
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(39),
      fontWeight: 600,
      textAlign: 'center',
      lineHeight: '49.1px',
      color: colors.textBody,
    },
    textInfo: {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 500,
      lineHeight: '49.1px',
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      margin: '0px 30px',
      color: colors.textBody,
      textDecoration: 'none',
    },
    containerOptions: {
      width: 250,
      height: '100vh',
      background: colors.colorBackground,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textMenu: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(21),
      fontWeight: 600,
      lineHeight: '25px',
      textAlign: 'center',
      color: isActiveContrast ? colors.textAccentColor : '#032876',
      textTransform: 'uppercase',
      margin: '27px 0px',
    },
    options: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },
    textItem: {
      color: isActiveContrast ? colors.textAccentColor : '#2D9CDB',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600,
      lineHeight: '21.13px',
      textDecoration: 'none',
    },
    boxLogo: {
      cursor: 'pointer',
    },
    boxLogged: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    containerIconClose: {
      display: 'flex',
      marginTop: 50,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery('(min-width:1012px)');
  const matchesMobile = useMediaQuery('(min-width:660px)');
  const token: string | null = localStorage.getItem('gov_access_token_sso');
  const tokenSiseci: string | null = localStorage.getItem('gov_access_token_siseci');

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLButtonElement | null>(null);
  const [userData, setUserData] = useState<UserData | null>();
  const [message, setMessage] = useState<string>('');
  const [avatarUser, setAvatarUser] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false);
  const [openOptions, setOpenOptions] = useState(false);

  const linkFace = (window as any)._env_.REACT_APP_LINK_FACEBOOK ?? '';
  const linkInsta = (window as any)._env_.REACT_APP_LINK_INSTAGRAM ?? '';
  const linkYoutube = (window as any)._env_.REACT_APP_LINK_YOUTUBE ?? '';
  const linkTwitter = (window as any)._env_.REACT_APP_LINK_TWITTER ?? '';

  const goToPage = (link: string) => {
    (window as any).open(link);
  };

  function handleSwitchColor() {
    setTypeTheme(typeTheme === 'normal' ? 'contraste' : 'normal');
  }

  useEffect(() => {
    const { state } = location;
    if ((state as any)) {
      setMessage((state as any).message);
    }
  }, [location]);

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      setLoading(true);

      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);

      setUserData(data);
      localStorage.setItem('gov_user_data', JSON.stringify(data));
      // setLoading(false);
    } catch (err) {
      clearStorage();
      if (err.response?.data) {
        setMessage(err.response.data.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
  }, [token, tokenSiseci]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type?: 'user' | 'access') => {
    if (type === 'user') {
      setAnchorElUser(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    logout();
    setUserData(undefined);
    setAnchorElUser(null);
  };

  useEffect(() => {
    const handleGetImageProfile = async () => {
      if (token) {
        const { data } = await getImageProfile(token);

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo;
          setAvatarUser(image);
        }
      }
    };

    handleGetImageProfile();
  }, [token]);

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  // const renderMenus = (): JSX.Element => (
  //   <>
  //     {!matches && (
  //       <>
  //         <IconButton onClick={handleClick} color="primary">
  //           <MdMenu style={{ color: colors.accentColor }} />
  //         </IconButton>
  //       </>
  //     )}
  //     <TermoDialog />
  //     <ModalAlertsDialog />
  //     <AlertAcceptCookies />
  //   </>
  // );

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  // const clickMenuIten = (to: string) => {
  //   history.push(to);
  //   handleClose();
  // };

  const nameSplitOne = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]}`;
    }
    return name;
  };

  const nameSplitTwo = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]} ${name.split(' ')[1][0]}.`;
    }
    return name;
  };

  const renderItems = (): JSX.Element => {
    const menus = [
      {
        titulo: 'Órgãos do governo',
        url: '/orgaos',
      },
      {
        titulo: 'Acesso à informação',
        external_url: (window as any)._env_.REACT_APP_LINK_ACESSO_INFORMACAO,
      },
      {
        titulo: 'Legislação',
        external_url: (window as any)._env_.REACT_APP_LINK_LEGISLACAO,
      },
      {
        titulo: 'Acessibilidade',
        url: '/acessibilidade',
      },
      {
        titulo: 'Consultar Autenticidade',
        url: '/validacao_documento',
      },
    ];

    return (
      <>
        {menus.map((menu) => (
          <MenuItem
            key={menu.titulo}
            style={{
              backgroundColor: colors.colorBackgroundSecundary,
            }}
          >
            <Link
              color="primary"
              href={menu.url}
              onClick={(e: React.SyntheticEvent): void => {
                preventDefault(e);
                if (menu.external_url) {
                  (window as any).open(menu.external_url);
                } else {
                  history.push(menu.url);
                }
                handleClose();
              }}
              className={classes.text}
            >
              {menu.titulo}
            </Link>
          </MenuItem>
        ))}
      </>
    );
  };

  const handleOpenModalInfo = useCallback(() => setOpenModalInfo(!openModalInfo), [openModalInfo]);

  const handleCloseDrawer = () => {
    setOpenOptions(false);
  };

  return (
    <>
      {matches ? (
        <Box className={classes.boxSubMenu}>
          <Box margin="auto" className={classes.boxSub}>
            {loading ? (
              <CircularProgress style={{ width: 25, height: 25, color: '#fff' }} />
            ) : (
              <>
                {userData ? (
                  <Button onClick={(e): void => handleClick(e, 'user')}>
                    <Typography className={classes.textGovBr}>
                      {nameSplitOne(userData?.nome?.toLowerCase())}
                    </Typography>
                  </Button>
                ) : (
                  <Box
                    onClick={loginRedirect}
                    className={classes.contentLogin}
                  >
                    <FiLogIn color={colors.textAccentColor} size={25} />
                    <Typography className={classes.textGovBr}>
                      entrar com o gov.br
                    </Typography>
                  </Box>
                )}
              </>
            )}

            <Box className={classes.separate} />
            <Box display="flex">
              <IconButton
                onClick={(): void => {
                  goToPage(linkTwitter);
                }}
                className={classes.btnSocial}
              >
                <FaSquareXTwitter className={classes.iconSocial} />
              </IconButton>
              <IconButton
                onClick={(): void => {
                  goToPage(linkInsta);
                }}
                className={classes.btnSocial}
              >
                <FaInstagramSquare className={classes.iconSocial} />
              </IconButton>
              <IconButton
                onClick={(): void => {
                  goToPage(linkFace);
                }}
                className={classes.btnSocial}
              >
                <FaFacebookSquare className={classes.iconSocial} />
              </IconButton>
              <IconButton
                onClick={(): void => {
                  goToPage(linkYoutube);
                }}
                className={classes.btnSocial}
              >
                <FaYoutubeSquare className={classes.iconSocial} />
              </IconButton>
              <IconButton
                onClick={(): void => {
                  goToPage(linkYoutube);
                }}
                className={classes.btnSocial}
              >
                <MdHeadsetMic className={classes.iconSocial} />
              </IconButton>
            </Box>
            <Box className={classes.separate} />
            <Typography onClick={(): void => history.push('/acessibilidade')} className={classes.linksBoxSubMenu}>
              <MdAccessibility size={20} />
              ACESSIBILIDADE
            </Typography>
            <Typography onClick={() => handleSwitchColor()} className={classes.linksBoxSubMenu}>
              <span style={{ fontSize: 20, color: colors.textAccentColor }} className="material-icons">
                contrast
              </span>
              CONTRASTE
            </Typography>
            <Box className={classes.separate} />
            <Box display="flex">
              <Typography
                onClick={(): void => history.push('/orgaos')}
                className={classes.linksBoxSubMenu}
              >
                GOVERNO
              </Typography>
              <Typography
                onClick={(): void => goToPage((window as any)._env_.REACT_APP_LINK_LEGISLACAO)}
                className={classes.linksBoxSubMenu}
              >
                TRANSPARÊNCIA
              </Typography>
              <Typography
                onClick={(): void => goToPage((window as any)._env_.REACT_APP_LINK_ACESSO_DIARIO_OFICIAL)}
                className={classes.linksBoxSubMenu}
              >
                DIÁRIO OFICIAL
              </Typography>
              <Typography className={classes.linksBoxSubMenu} onClick={handleOpenModalInfo}>
                FALE CONOSCO
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : ''}
      <Box id="back-to-top-anchor" style={{ top: 0 }} className={classes.main}>
        <Snackbar autoHideDuration={3000} onClose={(): void => setMessage('')} open={!!message}>
          <Alert onClose={(): void => setMessage('')} severity="error">
            {message}
          </Alert>
        </Snackbar>
        <Popover
          className={classes.boxInfo}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {renderItems()}
        </Popover>
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box>
            {userData && (
            <MenuItem
              style={{
                backgroundColor: colors.colorBackgroundSecundary,
                // minHeight: 73,
              }}
            >
              <Avatar src={avatarUser || null} alt={getInitialsFromName(userData.nome || '')} className={classes.avatar} />
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography
                  className={classes.textName}
                >
                  {matchesMobile
                    ? nameSplitTwo(userData?.nome?.toLowerCase())
                    : nameSplitOne(userData?.nome?.toLowerCase())}
                </Typography>
                <Typography className={classes.textCpf}>{cpfMask(userData.cpf)}</Typography>
              </Box>
            </MenuItem>
            )}
            <MenuItem className={classes.menuItem}>
              <a
                href="/workspace"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  textDecoration: 'none',
                  fontFamily: 'Roboto',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <BsFillGridFill style={{ color: colors.accentColor }} />
                <Typography className={classes.textMenuItem}>Meu Painel</Typography>
              </a>
            </MenuItem>
            <MenuItem className={classes.menuItem}>
              <a
                href="/workspace/meus_agendamentos"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  textDecoration: 'none',
                  fontFamily: 'Roboto',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <FaCalendarDay style={{ color: colors.accentColor }} />
                <Typography className={classes.textMenuItem}>Meus Agendamentos</Typography>
              </a>
            </MenuItem>
            {/* <Divider  /> */}
            <MenuItem onClick={logoutUser} className={classes.menuItem}>
              <MdExitToApp style={{ color: colors.buttonErrorColor }} />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
            {/* <Divider /> */}
          </Box>
        </Popover>
        <Box
          display="flex"
          maxWidth="1440px"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box className={classes.contentLogo}>
            {!openOptions && (
            <IconButton onClick={() => setOpenOptions(true)} className={classes.buttonMenu}>
              <FiMenu color={isActiveContrast ? colors.textAccentColor : '#195128'} size={61} />
            </IconButton>
            )}
            <Box className={classes.boxLogo} onClick={() => history.push('/')}>
              <img
                className={classes.img}
                alt="logo"
                src={
                  typeTheme === 'contraste'
                    ? (window as any)._env_.REACT_APP_LOGO_MAIN_WHITE
                    : (window as any)._env_.REACT_APP_LOGO_MAIN
                  }
              />
            </Box>
          </Box>
          <LinkRouter
            to="/"
          >
            <div className={classes.linkRouterContainer}>
              <img
                className={classes.img}
                alt="logo"
                src={
                  typeTheme === 'contraste'
                    ? (window as any)._env_.REACT_APP_LOGO_PRIMARY_WHITE
                    : (window as any)._env_.REACT_APP_LOGO_PRIMARY
                  }
              />
            </div>
          </LinkRouter>

        </Box>
      </Box>
      <Modal
        open={openModalInfo}
        onClose={handleOpenModalInfo}
      >
        <Box className={classes.contactInformation}>
          <IconButton onClick={handleOpenModalInfo} className={classes.iconButton}>
            <AiOutlineCloseCircle size={56} />
          </IconButton>

          <Box
            className={classes.containerInfo}
          >
            <img
              className={classes.logo}
              src={(window as any)._env_.REACT_APP_LOGO_MAIN}
              alt="logo"
              width="100%"
              height="100%"
            />
            <Typography className={classes.title}>
              Governo do Estado do Amapá
            </Typography>
            <Typography className={classes.subTitle}>
              Horário de Atendimento
            </Typography>
            <Typography className={classes.textInfo}>
              07:30 ÀS 12:00 - 14:30 ÀS 18:00
            </Typography>
            <Typography className={classes.textInfo}>
              <FaPhoneAlt />
              {' '}
              (96) 2101-8404
            </Typography>
            <a href="https://apdigital.portal.ap.gov.br/" className={classes.textInfo}>
              <BsFileWordFill />
              {' '}
              https://apdigital.portal.ap.gov.br/
            </a>
            <Typography className={classes.textInfo}>
              <MdOutlineEmail />
              {' '}
              gabinetegovernador@amapa.gov.br
            </Typography>
            <Typography className={classes.textInfo}>
              <MdHome />
              {' '}
              Palácio do Setentrião, Rua General Rondon, 259, Centro. CEP: 68908-908 - Macapá-AP.
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Drawer variant="persistent" anchor="left" open={openOptions} onClose={handleCloseDrawer}>
        <Box className={classes.containerOptions}>
          <Box className={classes.options}>
            <Box className={classes.containerIconClose}>
              <IconButton style={{ padding: 0 }} onClick={handleCloseDrawer}>
                <Close
                  color={isActiveContrast ? colors.textAccentColor : undefined}
                />
              </IconButton>
            </Box>

            <span className={classes.textMenu}>
              MENU
            </span>
            <Box className={classes.boxLogged}>
              {loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} color="primary" />
              ) : (
                <>
                  {userData ? (
                    <Button
                      color="primary"
                      onClick={(e): void => handleClick(e, 'user')}
                    >
                      <Avatar
                        src={avatarUser || null}
                        alt={getInitialsFromName(userData.nome || '')}
                        className={classes.avatar}
                      />
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography
                          className={classes.textName}
                          color="primary"
                        >
                          {matchesMobile
                            ? nameSplitTwo(userData?.nome?.toLowerCase())
                            : nameSplitOne(userData?.nome?.toLowerCase())}
                        </Typography>
                        <Typography color="primary" className={classes.textCpf}>{userData.cpf}</Typography>
                      </Box>
                      <MdExpandMore style={{ color: colors.accentColor }} />
                    </Button>
                  ) : (
                    <Box
                      onClick={loginRedirect}
                      className={classes.contentLogin}
                    >
                      <FiLogIn color={colors.accentColor} size={25} />
                      <Typography className={classes.textGovBrMobile}>
                        Entrar com o Gov.br
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Typography
              component="a"
              href="/servico/perfil/cidadao"
              className={classes.textItem}
            >
              Cidadão
            </Typography>
            <Typography
              component="a"
              href="/servico/perfil/empresa"
              className={classes.textItem}
            >
              Empresas
            </Typography>
            <Typography
              component="a"
              href="/servico/perfil/servidor"
              className={classes.textItem}
            >
              Servidor
            </Typography>
            <Typography
              component="a"
              href="#servicos"
              onClick={() => handleCloseDrawer()}
              className={classes.textItem}
            >
              Serviços mais acessados
            </Typography>
            <Typography
              component="a"
              href="#atendimento"
              onClick={() => handleCloseDrawer()}
              className={classes.textItem}
            >
              Atendimento
            </Typography>
            <Typography
              component="a"
              href="#indicadores"
              onClick={() => handleCloseDrawer()}
              className={classes.textItem}
            >
              Indicadores
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
