import React, { useContext, useState } from "react";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MdHome } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { SearchI } from "../../../SearchService";
import colorContext from "../../../../Context/colorContext";
import { CategoyService } from "../../..";
import { Categories } from "../../../Home/components";

interface Props {
  slug: string;
  service: SearchI;
}

export default function Breadcrumb({ slug, service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.accentColorBackground,
    },
    link: {
      display: "flex",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      color: colors.textAccentColor,
      width: 30,
      height: 30,
    },
    inputSearch: {
      height: 50,
      borderRadius: 0,
      width: "100%",
      border: "none",
      outline: "none",
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.colorBackground,
      "&::placeholder": {
        color: colors.textBody,
      },
    },
    buttonSearch: {
      background: colors.colorBackground,
      "&:hover": {
        background: colors.breadcrumbButtonColorHover,
      },
    },
    breadcrumbs: {
      flexGrow: 1,
      color: colors.textAccentColor,
    },
    iconSearch: {
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [textSearch, setTextSearch] = useState<string>("");
  const matches = useMediaQuery("(min-width:935px)");

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll("/", "");
      history.push(`/buscar/q=${formattedSearchText}`);
      setTextSearch("");
    }
  };

    const specificPublic = {
      'Cidadão': "cidadao",
      'Empresa': "empresa",
      'Servidor': "servidor",
    }[service.publico_especifico[0]]

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        padding="0px 15px"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        justifyContent={matches ? "space-between" : "flex-end"}
      >
        <Breadcrumbs separator="/" className={classes.breadcrumbs}>
          <Link
            href="/"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            <MdHome className={classes.icon} />
          </Link>

          <Link
          href={`/categoria/${service.categoria_slug}/${specificPublic}`}
          >
            <Typography
              style={{
                color: colors.textAccentColor,
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "300px",
                textTransform: "capitalize",
              }}
            >
              {service.categoria_nome.toLowerCase()}
            </Typography>
          </Link>
          <Typography
            style={{
              color: colors.textAccentColor,
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "300px",
              textTransform: "capitalize",
            }}
          >
            {slug.toLowerCase()}
          </Typography>
        </Breadcrumbs>
        <form
          onSubmit={submitSearch}
          style={{
            display: "flex",
            justifyContent: `${matches ? "flex-end" : "center"}`,
            flexGrow: 1,
            minWidth: 300,
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            style={{
              maxWidth: 300,
              width: "100%",
              borderRadius: "4px 0px 0px 4px",
            }}
            placeholder="Digite aqui o que procura..."
          />
          <IconButton
            type="submit"
            className={classes.buttonSearch}
            style={{
              borderRadius: "0px 4px 4px 0px",
            }}
          >
            <IoMdSearch className={classes.iconSearch} />
          </IconButton>
        </form>
      </Box>
    </Box>
  );
}
