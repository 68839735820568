import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

export const allCategory = (page: number): Promise<AxiosResponse> => apisiseci.get(`cms/temas/?page=${page}`);

export const getCategory = (slug: string): Promise<AxiosResponse> => apisiseci.get(`cms/temas/?slug=${slug}`);

export const filterCategory = (params: { search?: string; page?: number; slug?: string; }): Promise<AxiosResponse> => apisiseci.get('cms/temas/', {
  params,
});

export const listAllCategory = (): Promise<AxiosResponse> => apisiseci.get('cms/temas/');
