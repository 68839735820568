import React, { useCallback, useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdExpandMore } from 'react-icons/md';
import { SearchI } from '../../../SearchService';
import colorContext from '../../../../Context/colorContext';

interface Props {
  service: SearchI;
}

export default function ServiceDetails({
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textBody,
      fontWeight: 'bold',
      marginLeft: 6,
      fontFamily: 'Roboto',
    },
    text: {
      fontSize: 16,
      color: colors.accordionTextColor,
      fontWeight: 'normal',
      fontStyle: 'normal',
      marginLeft: 28,
      fontFamily: 'Roboto',
    },
    accordion: {
      backgroundColor: colors.colorBackgroundSecundary,
      marginBottom: 20,
      border: `1px solid ${colors.borderShadowsColor}`,
      width: '100%',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 62,
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
      marginLeft: 14,
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: colors.textAccentColor,
      borderRadius: 0,
      textTransform: 'capitalize',
    },
    buttonBack: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      textTransform: 'capitalize',
      color: '#000',
    },
    colorIcon: {
      color: colors.textBody,
    },
    colorStepper: {
      backgroundColor: colors.colorBackground,
      '& .MuiStepLabel-active': {
        color: `${colors.accentColorSecondary} !important`,
      },
      '& .MuiStepLabel-completed': {
        color: `${colors.accentColorSecondary} !important`,
      },
      '& .MuiStepLabel-label': {
        color: colors.stepLabelColor,
      },
      '& .MuiStepIcon-active': {
        color: colors.iconStepColor,
      },
      '& .MuiStepIcon-completed': {
        color: colors.accentColorSecondary,
      },
    },
  }));
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState < string | false >('panel1');
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    setExpanded(isExpanded ? panel : false);
  };

  const numberOrText = (data: string) => {
    if (!Number.isNaN(parseFloat(data))) {
      return parseFloat(data.replace(/\./g, '').replace(',', '.'))
        .toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
    }
    return data;
  };

  const handleNumberOrText = useCallback(numberOrText, []);

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        padding="0px 15px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        marginBottom="80px"
      >
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className={classes.accordion}
        >
          <AccordionSummary classes={{ root: classes.accordionRoot }} expandIcon={<MdExpandMore className={classes.colorIcon} />}>
            <Typography className={classes.title}>O que é este serviço?</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: service.descricao }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className={classes.accordion}
        >
          <AccordionSummary classes={{ root: classes.accordionRoot }} expandIcon={<MdExpandMore className={classes.colorIcon} />}>
            <Typography className={classes.title}>Exigências para realizar o serviço</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: service.requisitos }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className={classes.accordion}
        >
          <AccordionSummary classes={{ root: classes.accordionRoot }} expandIcon={<MdExpandMore className={classes.colorIcon} />}>
            <Typography className={classes.title}>Quem pode utilizar este serviço?</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: service.publico }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className={classes.accordion}
        >
          <AccordionSummary classes={{ root: classes.accordionRoot }} expandIcon={<MdExpandMore className={classes.colorIcon} />}>
            <Typography className={classes.title}>Quanto tempo leva?</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Typography
              className={classes.text}
            >
              {service.tempo_total && service.tipo_tempo && (
                <li>{`${service.tempo_total} ${service.tipo_tempo}.`}</li>
              )}
              {service.tempo && (
                <li>{`Atendimento presencial: ${service.tempo} min.`}</li>
              )}
              {service.tempo_online && (
                <li>{`Atendimento online: ${service.tempo_online} min.`}</li>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          className={classes.accordion}
        >
          <AccordionSummary
            classes={{ root: classes.accordionRoot }}
            expandIcon={<MdExpandMore className={classes.colorIcon} />}
          >
            <Typography className={classes.title}>Quais os custos?</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Typography
              className={classes.text}
            >
              {handleNumberOrText(service.custo)}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          className={classes.accordion}
        >
          <AccordionSummary classes={{ root: classes.accordionRoot }} expandIcon={<MdExpandMore className={classes.colorIcon} />}>
            <Typography className={classes.title}>Passo a passo</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Stepper activeStep={activeStep} className={classes.colorStepper} orientation="vertical">
              {service.jornada.sort((a, b) => a.ordem - b.ordem).map((jornada) => (
                <Step key={jornada.id}>
                  <StepLabel>
                    {jornada.titulo}
                  </StepLabel>
                  <StepContent>
                    <Typography
                      className={classes.text}
                      dangerouslySetInnerHTML={{ __html: jornada.conteudo }}
                    />
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          style={{ color: colors.accentColor }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.buttonBack}
                        >
                          Voltar
                        </Button>
                        {activeStep !== service.jornada.length - 1 && (
                          <Button
                            variant="contained"
                            style={{ backgroundColor: colors.accentColorBackground }}
                            onClick={handleNext}
                            className={classes.button}
                          >
                            Próximo
                          </Button>
                        )}
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </AccordionDetails>
        </Accordion>
        <Accordion
          hidden={!service.informacoes_extra}
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          className={classes.accordion}
        >
          <AccordionSummary classes={{ root: classes.accordionRoot }} expandIcon={<MdExpandMore className={classes.colorIcon} />}>
            <Typography className={classes.title}>Outras informações </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetail }}>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: service.informacoes_extra }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
