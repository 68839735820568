import React, { useContext } from 'react';
import {
  Box, Card, Typography, useMediaQuery, CardContent, CardActionArea,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';
import { Category } from '../../../Home';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listCategory: Category;
}

export default function CategoryList({
  listCategory,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    card: {
      width: '30%',
      minWidth: 340,
      minHeight: 200,
      maxHeight: 400,
      borderRadius: 0,
      color: colors.textAccentColor,
      backgroundColor: colors.cardAccentColor,
      border: `1px solid ${colors.accentColorBackground}`,
      padding: 28,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginLeft: 5,
      marginRight: 5,
      marginBottom: 10,
      cursor: 'pointer',
      '&:hover': {
        color: colors.accentColor,
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        border: `1px solid ${colors.accentColor}`,
      },
    },
    text: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(20),
      textTransform: 'capitalize',
    },
    tag: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.secondary.main,
      color: '#fff',
      padding: '2px 14px',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:755px)');
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        alignItems="center"
        width="100%"
        justifyContent={matches ? 'space-evenly' : 'center'}
        flexDirection="row"
        flexWrap="wrap"
      >
        {listCategory.results.map((category) => (
          <Card
            onClick={(): void => history.push(`/categoria/${category.slug}`)}
            key={category.id}
            className={classes.card}
          >
            <CardActionArea
              style={{
                height: '100%',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span style={{ fontSize: 34 }} className="material-icons">
                  {category.icone}
                </span>
                <Typography
                  align="center"
                  variant="h5"
                  style={{
                    fontSize: 18,

                    marginTop: 20,
                  }}
                >
                  {category.titulo}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
