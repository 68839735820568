import React, { useContext } from 'react';
import {
  Box, makeStyles, Theme, Typography,
} from '@material-ui/core';

import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { Service } from '../../Pages/Home';
import typeServices from '../../utils/typeServiceOfProfile';

interface Props {
  list: Service[];
  type: 'cidadao' | 'empresa' | 'servidor';
}

export default function MostAccessedServices({
  type,
  list,
}: Props):JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      maxWidth: '1141px',
      width: '100%',
    },
    main: {
      width: '100%',
      maxWidth: 406,
      margin: '0px 20px',
      maxHeight: 500,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    itemList: {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      gap: 20,
      margin: '15px 0px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(25.6),
      lineHeight: '2.1rem',
      cursor: 'pointer',
      color: colors.textBody,
    },
    icon: {
      color: typeTheme === 'contraste' ? colors.textAccentColor : typeServices[type].cardColor,
    },
    button: {
      width: '100%',
      maxWidth: 406,
      height: 55,
      borderRadius: 7,
      backgroundColor: typeTheme === 'contraste' ? colors.colorBackgroundSecundary : typeServices[type].cardColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    textButton: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 700,
      lineHeight: '2.198rem',
      color: colors.textAccentColor,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box>
        {list?.slice(0, 3).map((service) => (
          <Typography
            key={service.slug}
            className={classes.itemList}
            component="span"
            onClick={(): void => history.push(`/servico/${service.slug}`)}
            paragraph
          >
            <div className={classes.icon}>
              <HiOutlineChevronDoubleRight size={35} />
            </div>
            {' '}
            {service.titulo}
          </Typography>
        ))}
      </Box>
      <Box onClick={(): void => history.push(`/servico/perfil/${type}`)} className={classes.button}>
        <Typography className={classes.textButton}>
          {`Ver mais para ${typeServices[type].title}`}
        </Typography>
      </Box>
    </Box>
  );
}
