import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Box,
} from '@material-ui/core';
import {
  createGenerateClassName,
  makeStyles, StylesProvider, ThemeProvider,
} from '@material-ui/core/styles';
import {
  MdCheckCircle, MdClose, MdDeleteForever, MdMouse, MdEvent,
} from 'react-icons/md';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid, subDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SearchI } from '../../../SearchService';
// eslint-disable-next-line import/extensions
import { UserData } from '../../../../services/user';
import {
  anexarArquivo, atenderOnline, checarHorarios, incluirDependente,
} from '../../../../services/atendimentos';
import { Schedule } from '../ModalAttendancePresential';
import { cpfMask, removeCpfMask, validateCpf } from '../../../../utils/cpfFormat';
import dateFormatToBR from '../../../../utils/dateFormat';
import colorContext from '../../../../Context/colorContext';
import theme from '../../../../services/theme-service';
import themeCalendarProvider from '../../../../services/calendarTheme';

interface SavedDependenteProps {
  agendamento: number;
  cpf: string;
  cpf_solicitante: string;
  data_nascimento: string;
  grau_parentesco: string;
  id: number;
  nome: string;
}

interface SavedScheduleProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

interface Props {
  open: boolean;
  service: SearchI;
  handleClose: () => void;
}

interface ScheduleUser {
  anexo: boolean;
  created_at: string;
  data: string;
  guiche: number;
  hora: string;
  id: string;
  servico: number;
  setor: number;
  status: string;
  titulo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

export default function ModalAttendanceOnline({
  open,
  service,
  handleClose,
}: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const generateClassName = createGenerateClassName({ productionPrefix: 'online', disableGlobal: true });
  const materialTheme = themeCalendarProvider(colors, typeTheme);
  const useStyles = makeStyles(() => ({
    root: {
      '& label.Mui-focused': {
        color: colors.accentColor,
      },
      '& .MuiFormControl-root': {
        '& .MuiInputLabel-outlined': {
          color: colors.textBody,
        },
        '& .MuiIconButton-root': {
          color: colors.textBody,
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.accentColor,
        },
        '& label': {
          color: colors.accentColor,
        },
        '&:hover fieldset': {
          borderColor: colors.accentColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.accentColor,
        },
        '& .MuiSelect-iconOutlined': {
          color: colors.textBody,
          height: 24,
          width: 24,
        },
      },
    },
    rootDialog: {
      '& .MuiDialogContent-root': {
        overflowY: 'hidden',
      },
    },
    header: {
      background: colors.accentColor,
    },
    textHeader: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      width: '90%',
      color: colors.colorBackground,
    },
    formControl: {
      marginBottom: 20,
      margin: theme.spacing(1),
      width: '97%',
    },
    menuItem: {
      color: colors.accentColor,
      backgroundColor: colors.colorBackgroundSecundary,
      '&:hover': {
        backgroundColor: colors.colorBackground,
        color: colors.accentColor,
      },
      '&.Mui-selected': {
        backgroundColor: colors.menuItemColor,
        color: colors.accentColor,
        '&:hover': {
          backgroundColor: colors.menuItemColor,
          color: colors.accentColor,
        },
      },
    },
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: colors.colorBackground,
    },
    textSchedule: {
      color: colors.textBody,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20),
      marginTop: 30,
    },
    textSubtitleSchedule: {
      color: colors.textBody,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      marginTop: 20,
    },
    iconUpload: {
      color: '#9E9E9E',
    },
    rootUpload: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 140,
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    multilineColor: {
      color: colors.colorBackground,
    },
    inputCheckbox: {
      color: colors.textBody,
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    inputCheckboxText: {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontstyle: 'normal',
      fontWeight: 400,
      textAlign: 'left',
      color: colors.textBody,
      marginBottom: 10,
    },
    colorInputText: {
      color: colors.accentColor,
    },
    inputForm: {
      margin: '24px 0px',
    },
    text: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 16,
      color: colors.textBody,
    },
    infoText: {
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 400,
      color: colors.textBody,
      margin: '8px 0px',
    },
    textSubtitleScheduleBox: {
      width: 165,
      height: 30,
      borderRadius: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(225, 230, 239, 0.3)',
      color: colors.textBody,
    },
    box: {
      borderRadius: 4,
      border: `1px solid ${colors.borderShadowsColor}`,
      margin: '51px 32px',
      backgroundColor: colors.colorBackground,
    },
    titleBox: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      margin: '2px 0px 30px 0px',
    },
    boxConcludedMain: {
      backgroundColor: colors.colorBackgroundSecundary,
    },
    boxInfoConcluded: {
      margin: '20px 32px',
      borderRadius: 4,
      padding: '16px 24px',
      border: '1px solid #0BB07B',
      backgroundColor: 'rgba(11, 176, 123, 0.05)',
    },
    infoConcluded: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textInfoUnity: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginTop: 16,
      marginBottom: 8,
    },
    textInfoDateTime: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
      color: '#706E7A',
      marginBottom: 2,
    },
    buttonPrimary: {
      margin: '10px 0px',
      backgroundColor: colors.badgeColor,
      color: colors.textAccentColor,
      width: 186,
      height: 44,
      '&:hover': {
        backgroundColor: colors.favoriteBoxColor,
        color: colors.textAccentColor,
      },
    },
    textButtonPrimary: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    buttonOutlined: {
      width: 186,
      height: 44,
      padding: '14px 16px',
      marginLeft: '0px !important',
    },
    textButtonOutlined: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      fontFamily: 'Roboto',
      fontWeight: 700,
      textTransform: 'uppercase',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    boxMain: {
      backgroundColor: colors.colorBackgroundSecundary,
    },
    textLabel: {
      color: colors.textBody,
    },
    errorTextStyle: {
      color: colors.textBody,
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Roboto',
    },
    cardUploads: {
      width: '100%',
      border: `1px solid ${colors.borderShadowsColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      backgroundColor: colors.colorBackgroundSecundary,
      color: colors.textBody,
      display: 'flex',
      borderRadius: 4,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 10px',
      marginBottom: 2,
      marginTop: 5,
    },
    errorUploadCard: {
      width: '100%',
      border: `1px solid ${colors.buttonErrorColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      backgroundColor: colors.colorBackgroundSecundary,
      display: 'flex',
      borderRadius: 4,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 10px',
      marginBottom: 2,
      marginTop: 5,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [unitySelected, setUnitySelected] = useState < string >('');
  const [checkedDependente, setCheckedDependente] = useState(false);
  const [cpfDependente, setCpfDependente] = useState<string>('');
  const [errorCpfDependente, setErrorCpfDependente] = useState<undefined | string>(undefined);
  const [nameDependente, setNameDependente] = useState<string>('');
  const [errorNameDependente, setErrorNameDependente] = useState<undefined | string>(undefined);
  const [birthDateDependente, setBirthDateDependente] = useState<Date>(subDays(new Date(), 30));
  const [dependenteSelected, setDependenteSelected] = useState<string>('');
  const [scheduleServiceUser, setScheduleServiceUser] = useState<ScheduleUser | string | undefined>();
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState < FileObject[] >([]);
  const [openUploads, setOpenUploads] = useState < boolean >(false);
  const [scheduleService, setScheduleService] = useState < string | Schedule | undefined >();
  const [scheduleSelected, setScheduleSelected] = useState<string>('');
  const [loadingDepente, setLoadingDepedente] = useState(false);
  const [savedSchedule, setSavedSchedule] = useState<SavedScheduleProps>(undefined);
  const [savedDependente, setSavedDependente] = useState<SavedDependenteProps>(undefined);
  const [loadingFinally] = useState(false);
  const [hiddenInfoShedule, setHiddenInfoSchedule] = useState(true);
  const [showBoxData, setShowBoxData] = useState(false);
  const [isErrorInUpload, setIsErrorInUpload] = useState(false);
  const [hoursList, setHoursList] = useState<string[] | null>(null);
  const [loadingHours, setLoadingHours] = useState<boolean>(false);

  const handleCheckDependente = () => {
    setCheckedDependente(!checkedDependente);
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    const dataStorage: string | null = localStorage.getItem('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData) {
      try {
        const { data, status } = await atenderOnline(
          token,
          service.id,
          unitySelected,
          service.titulo,
          `Agendamento para ${service.titulo}`,
          service.setor_id,
          'Video',
          selectedDate,
          scheduleSelected,
          checkedDependente,
        );
        setScheduleServiceUser(data);

        if (status === 201) {
          setSavedSchedule(data);
        }

        setLoading(false);
      } catch (err) {
        if (err.response.data.message) {
          setScheduleServiceUser(err.response.data.message);
        } else {
          setScheduleServiceUser(err.response.data[0]);
        }
        setLoading(false);
      }
    }
  };

  const handleDependente = async () => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    const dataStorage: string | null = localStorage.getItem('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    const cpfDependenteWithoutMask = removeCpfMask(cpfDependente);

    if (nameDependente && nameDependente.length < 6) {
      setErrorNameDependente('Nome deve ter no mínimo 6 caracteres.');
      return;
    }

    if (cpfDependenteWithoutMask) {
      if (!validateCpf(cpfDependenteWithoutMask) || cpfDependenteWithoutMask.length < 11) {
        setErrorCpfDependente('CPF inválido.');
      }
    }

    if (savedSchedule.para_dependente) {
      try {
        setLoadingDepedente(true);
        const { data, status } = await incluirDependente(
          savedSchedule.id,
          cpfDependenteWithoutMask,
          nameDependente,
          birthDateDependente,
          userData.cpf,
          dependenteSelected,
          token,
        );

        if (status === 201) {
          setSavedDependente(data);
        }
        setLoadingDepedente(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDepedente(false);
      }
    }
  };

  const handleUploads = () => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');

    if (savedSchedule && files.length >= 1) {
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const fileUpload of files) {
          anexarArquivo(
            token,
            fileUpload.file.name,
            fileUpload.file.type,
            fileUpload.file,
            savedSchedule.id.toString(),
          );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }

    setShowBoxData(true);
    setHiddenInfoSchedule(false);
  };

  const handleUploadsAlert = (variant: string) => {
    if (variant === 'error') {
      setIsErrorInUpload(true);
    } else {
      setIsErrorInUpload(false);
    }
  };

  const handleFinally = () => {
    setShowBoxData(true);
    setHiddenInfoSchedule(true);
    history.push('/workspace/meus_agendamentos');
  };

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter((fileFiltering) => fileFiltering.data !== file.data);
    setFiles(fileFilter);
  };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    setHoursList([]);
    setLoadingHours(true);

    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, service.id);

        if (data?.horarios?.length > 0) {
          setHoursList(data.horarios);
        }

        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else if (err?.response?.data.length > 0) {
          setScheduleServiceUser(err?.response?.data[0]);
        } else {
          setScheduleService('Algo inesperado ocorreu.');
        }
      } finally {
        setLoadingHours(false);
      }
    } else {
      setScheduleService(undefined);
      setLoadingHours(false);
    }
  };

  const optionsDependentes = [
    {
      id: 0,
      option: 'Filho',
    },
    {
      id: 1,
      option: 'Filha',
    },
    {
      id: 2,
      option: 'Enteado',
    },
    {
      id: 3,
      option: 'Enteada',
    },
    {
      id: 4,
      option: 'Pai',
    },
    {
      id: 5,
      option: 'Mãe',
    },
    {
      id: 6,
      option: 'Avó',
    },
    {
      id: 7,
      option: 'Avô',
    },
    {
      id: 8,
      option: 'Tutor',
    },
    {
      id: 9,
      option: 'Tutora',
    },
  ];

  useEffect(() => {
    setUnitySelected('');
    setScheduleServiceUser(undefined);
    setScheduleService(undefined);
    setFiles([]);
    setOpenUploads(false);
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
        history.go(0);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ root: classes.rootDialog }}
    >
      <IconButton
        onClick={handleClose}
        className={classes.buttonClose}
      >
        <MdClose style={{ width: 24, height: 24 }} />
      </IconButton>
      <div hidden={showBoxData} className={classes.boxMain}>
        <DialogTitle
          className={classes.header}
        >
          <Typography className={classes.textHeader}>
            <MdMouse style={{
              marginRight: 15, width: 24, height: 24,
            }}
            />
            Atendimento online
          </Typography>
        </DialogTitle>
        {loading ? (
          <Box style={{ marginTop: 20, textAlign: 'center' }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            {!savedSchedule ? (
              <Box className={classes.box}>
                <DialogContent>
                  <Typography className={classes.titleBox}>Faça seu agendamento</Typography>
                  <div id="input-check">
                    <FormControlLabel
                      className={classes.inputCheckbox}
                      control={(
                        <Checkbox
                          checked={checkedDependente}
                          onChange={handleCheckDependente}
                          name="dependente"
                          style={{ color: colors.accentColor }}
                        />
                    )}
                      label="Dependente"
                    />
                    <Typography className={classes.inputCheckboxText}>Selecione caso este agendamento seja para seu dependente.</Typography>
                  </div>
                  <FormControl
                    classes={{ root: classes.root }}
                    error={typeof (scheduleServiceUser) === 'string'}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel className={classes.textLabel} id="demo-simple-select-outlined-label">Selecione a unidade</InputLabel>
                    <Select
                      error={typeof (scheduleServiceUser) === 'string'}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                  // value={age}
                      onChange={(event): void => {
                        setScheduleServiceUser(undefined);
                        setUnitySelected(event.target.value as string);
                        getSchedulers(selectedDate, event.target.value as string);
                      }}
                      label="Selecione a unidade"
                      classes={{
                        select: classes.menuItem,
                      }}
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      {service?.servicosUnidade?.map((unity) => (
                        <MenuItem
                          key={unity.id}
                          className={classes.menuItem}
                          value={unity.unidade.slug_unidade}
                        >
                          {unity.unidade.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <div id="input-data">
                    <FormControl
                      classes={{ root: classes.root }}
                      error={
                        typeof (scheduleService) === 'string'
                        || typeof (scheduleServiceUser) === 'string'
                        || (!loadingHours && selectedDate && hoursList && hoursList.length === 0)
                      }
                      disabled={!unitySelected}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <StylesProvider generateClassName={generateClassName}>
                        <ThemeProvider theme={materialTheme}>
                          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                              disableFuture={false}
                              error={
                                typeof (scheduleService) === 'string'
                                || typeof (scheduleServiceUser) === 'string'
                                || (!loadingHours && selectedDate && hoursList && hoursList.length === 0)
                              }
                              minDateMessage="Você não pode agendar em dias anteriores"
                              invalidDateMessage="A data informada é inválida"
                              minDate={new Date()}
                          // InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                              autoOk
                              disabled={!unitySelected}
                              variant="inline"
                              inputVariant="outlined"
                              label="Selecione o dia"
                              format="dd/MM/yyyy"
                              placeholder="00/00/0000"
                              // PopoverProps={{
                              //   PaperProps: {
                              //     style: {
                              //       backgroundColor: '#CED2DB',
                              //     },
                              //   },
                              // }}
                              value={selectedDate || null}
                              InputAdornmentProps={{ position: 'end', color: colors.textBody }}
                              onChange={(date) => {
                                if (date && isValid(date)) {
                                  handleDateChange(date);
                                  if (unitySelected) {
                                    getSchedulers(date, unitySelected);
                                  }
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </StylesProvider>
                      {typeof (scheduleService) === 'string' ? (
                        <FormHelperText>{scheduleService}</FormHelperText>
                      ) : selectedDate && (!loadingHours && hoursList && hoursList.length === 0) && (
                        <FormHelperText>Agendamento indisponível</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div id="input-horario">
                    <FormControl
                      classes={{ root: classes.root }}
                      disabled={
                        !unitySelected
                        || !selectedDate
                        || typeof (scheduleService) === 'string'
                        || !(hoursList.length > 0)
                      }
                      variant="outlined"
                      className={classes.formControl}
                      error={typeof (scheduleServiceUser) === 'string'}
                    >
                      <InputLabel className={classes.textLabel} id="demo-simple-select-filled-label">Escolha o horário</InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        classes={{
                          select: classes.menuItem,
                        }}
                  // value={age}
                        onChange={(event): void => {
                          setScheduleSelected(event.target.value as string);
                        }}
                        label="Escolha o horário"
                        MenuProps={{
                          MenuListProps: {
                            disablePadding: true,
                          },
                        }}
                      >
                        {typeof (scheduleService) !== 'string' && scheduleService?.horarios?.map((schedule) => (
                          <MenuItem key={schedule} className={classes.menuItem} value={schedule}>
                            {schedule}
                          </MenuItem>
                        ))}
                      </Select>
                      {typeof (scheduleServiceUser) === 'string' && (
                      <FormHelperText>{scheduleServiceUser}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  >
                    <Button
                      style={{
                        textTransform: 'uppercase',
                        color: colors.textAccentColor,
                        borderRadius: 4,
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: colors.cardAccentColor,
                      }}
                      disabled={
                            !unitySelected
                            || !selectedDate
                            || !scheduleSelected
                            || typeof (scheduleService) === 'string'
                            || typeof (scheduleService) === 'undefined'
                            || !(hoursList.length > 0)
                          }
                      // color="primary"
                      id="salvar-agendamento"
                      variant="contained"
                      onClick={schedulerService}
                    >
                      Salvar
                    </Button>
                  </Box>
                </DialogContent>
              </Box>
            ) : (
              <Box className={classes.boxInfoConcluded}>
                <Box className={classes.infoConcluded}>
                  <Typography className={classes.titleBox} style={{ margin: 0 }}>Agendamento Salvo com sucesso</Typography>
                  <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography className={classes.textInfoDateTime}>
                    {dateFormatToBR(savedSchedule?.data, false)}
                    {' '}
                    -
                    {' '}
                    {savedSchedule?.hora}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}

        {savedSchedule?.para_dependente && (
          <>
            {loadingDepente ? (
              <Box style={{ marginTop: 30, textAlign: 'center' }}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                {!savedDependente ? (
                  <Box className={classes.box}>
                    <DialogContent>
                      <Typography className={classes.titleBox}>dados do dependente</Typography>
                      <div className="input-name">
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={typeof (errorNameDependente) === 'string'}
                          className={classes.formControl}
                          classes={{ root: classes.root }}
                        >
                          <InputLabel className={classes.textLabel} htmlFor="outlined-name-dependente">
                            Nome Completo
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-name-dependente"
                            type="text"
                            label="Nome Completo"
                            autoFocus
                            className={classes.colorInputText}
                            value={nameDependente}
                            onChange={(event) => {
                              setNameDependente(event.target.value);
                              setErrorNameDependente(undefined);
                            }}
                            labelWidth={70}
                            inputProps={{ minLength: 6, maxLength: 100 }}
                          />
                          {typeof (errorNameDependente) === 'string' && (
                          <FormHelperText>{errorNameDependente}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div id="input-cpf">
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                          classes={{ root: classes.root }}
                          disabled={!nameDependente}
                          error={typeof (errorCpfDependente) === 'string'}
                        >
                          <InputLabel className={classes.textLabel} htmlFor="outlined-name-dependente">
                            CPF
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-name-dependente"
                            type="text"
                            label="cpf"
                            autoFocus
                            className={classes.colorInputText}
                            value={cpfDependente}
                            onChange={(event) => {
                              setCpfDependente(cpfMask(event.target.value));
                              setErrorCpfDependente(undefined);
                            }}
                            labelWidth={70}
                            inputProps={{ maxLength: 14, minLength: 14 }}
                          />
                          {typeof (errorCpfDependente) === 'string' && (
                          <FormHelperText>{errorCpfDependente}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div id="input-date">
                        <FormControl
                          classes={{ root: classes.root }}
                          error={typeof (scheduleService) === 'string' || typeof (scheduleServiceUser) === 'string'}
                          disabled={!cpfDependente}
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <StylesProvider generateClassName={generateClassName}>
                            <ThemeProvider theme={materialTheme}>
                              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                                  InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                                  autoOk
                                  variant="inline"
                                  inputVariant="outlined"
                                  label="Data de nascimento"
                                  minDateMessage="A data não pode ser superior ao mês atual"
                                  invalidDateMessage="A data informada é inválida"
                                  format="dd/MM/yyyy"
                                  maxDate={subDays(new Date(), 30)}
                                  disabled={!cpfDependente}
                                  value={birthDateDependente}
                                  InputAdornmentProps={{ position: 'end', color: '#000' }}
                                  onChange={(date) => {
                                    if (date && isValid(date)) {
                                      setBirthDateDependente(date);
                                    }
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </ThemeProvider>
                          </StylesProvider>
                          {typeof (scheduleService) === 'string' && (
                          <FormHelperText>{scheduleService}</FormHelperText>
                          )}
                        </FormControl>
                      </div>

                      <div id="input-parentesco">
                        <FormControl
                          classes={{ root: classes.root }}
                          variant="outlined"
                          className={classes.formControl}
                          disabled={!birthDateDependente || typeof (scheduleService) === 'string'}
                        >
                          <InputLabel className={classes.textLabel} id="demo-simple-select-outlined-label">
                            Grau de parentesco
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(event): void => {
                              setDependenteSelected(event.target.value as string);
                            }}
                            value={dependenteSelected || ''}
                            label="Grau de parentesco"
                            classes={{
                              select: classes.menuItem,
                            }}
                            MenuProps={{
                              MenuListProps: {
                                disablePadding: true,
                              },
                            }}
                          >
                            {optionsDependentes.map((unity) => (
                              <MenuItem
                                key={unity.id}
                                className={classes.menuItem}
                                value={unity.option}
                              >
                                {unity.option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <Box style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      >
                        <Button
                          onClick={handleDependente}
                          style={{
                            textTransform: 'uppercase',
                            color: colors.textAccentColor,
                            borderRadius: 4,
                            marginTop: 20,
                            marginBottom: 20,
                            backgroundColor: colors.cardAccentColor,
                          }}
                          disabled={
                          !nameDependente || !cpfDependente || !birthDateDependente || !dependenteSelected
                          }
                          // color="primary"
                          id="salvar-dependente"
                          variant="contained"
                        >
                          Salvar
                        </Button>
                      </Box>
                    </DialogContent>
                  </Box>
                ) : (
                  <Box className={classes.boxInfoConcluded}>
                    <Box className={classes.infoConcluded}>
                      <Typography className={classes.titleBox} style={{ margin: 0 }}>Informações salva com sucesso</Typography>
                      <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
                    </Box>
                    <Box>
                      <Typography className={classes.textInfoUnity}>{savedDependente.nome}</Typography>
                      <Typography className={classes.textInfoDateTime}>{savedDependente.cpf}</Typography>
                      <Typography className={classes.textInfoDateTime}>{dateFormatToBR(savedDependente.data_nascimento, false)}</Typography>
                      <Typography className={classes.textInfoDateTime}>{savedDependente.grau_parentesco}</Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}

        {savedSchedule && (
          <Box
            className={classes.box}
            style={{
              border: 'none',
              backgroundColor: colors.colorBackgroundSecundary,
            }}
          >
            <Button
              disabled={!unitySelected || typeof (scheduleServiceUser) === 'string'}
              onClick={(): void => setOpenUploads(true)}
              style={{
                borderRadius: 4,
                color: colors.accentColor,
                border: `1px solid ${colors.accentColor}`,
                display: openUploads ? 'none' : 'block',
                textTransform: 'uppercase',
              }}
            >
              Adicionar arquivo
            </Button>
            <div
              hidden={!openUploads}
              style={{
                color: '#9E9E9E',
                marginTop: 5,
                marginLeft: 10,
                width: '97%',
              }}
            >
              <DropzoneAreaBase
                onAdd={(filesUpload) => {
                  setFiles(filesUpload);
                }}
                fileObjects={files}
                acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                showPreviewsInDropzone={false}
                dropzoneText="Faça upload ou solte arquivos aqui"
                showFileNames={false}
                showFileNamesInPreview={false}
                dropzoneParagraphClass={classes.text}
                showAlerts={false}
                filesLimit={10}
                maxFileSize={31457280}
                classes={{
                  icon: classes.iconUpload,
                  root: classes.rootUpload,
                }}
                onAlert={(_, variant) => handleUploadsAlert(variant)}
              />
              <Typography className={classes.infoText}>
                Tamanho máximo:
                30MB
              </Typography>
              {files.map((file) => (
                <Card
                  className={classes.cardUploads}
                >
                  {file.file.name}
                  <IconButton onClick={(): void => removeFile(file)}>
                    <MdDeleteForever style={{ color: 'red', width: 24, height: 24 }} />
                  </IconButton>
                </Card>
              ))}
              {isErrorInUpload && (
                <Card
                  className={classes.errorUploadCard}
                >
                  <Typography className={classes.errorTextStyle}>
                    Arquivo inválido, ou excede o limite de tamanho.
                  </Typography>
                  <IconButton onClick={(): void => setIsErrorInUpload(false)}>
                    <MdDeleteForever
                      style={{
                        color: colors.buttonErrorColor,
                        width: 24,
                        height: 24,
                      }}
                    />
                  </IconButton>
                </Card>
              )}
            </div>
          </Box>
        )}

        <DialogActions
          style={{
            display: 'flex',
            margin: '0px 32px',
          }}
        >
          <Box style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          >
            {
              !unitySelected
              || !savedSchedule
              || (checkedDependente) ? (!checkedDependente || !nameDependente || !cpfDependente || !birthDateDependente || !dependenteSelected || !savedDependente) : (
                <Button
                  onClick={handleUploads}
                  style={{
                    textTransform: 'uppercase',
                    color: colors.textAccentColor,
                    borderRadius: 4,
                    marginBottom: 20,
                    backgroundColor: colors.badgeColor,
                  }}
              // color="primary"
                  id="salvar-dependente"
                  variant="contained"
                >
                  Finalizar
                </Button>
                )
            }

          </Box>
        </DialogActions>
      </div>
      <div
        hidden={hiddenInfoShedule}
        className={classes.boxConcludedMain}
      >
        <DialogContent style={{ textAlign: 'center' }}>
          <Box>
            <MdCheckCircle style={{
              color: '#3CD278', width: 80, height: 80, fontSize: 24,
            }}
            />
          </Box>
          <Typography className={classes.textSchedule}>
            Agendamento concluído, enviamos para você um comprovante,
            verifique no seu email.
          </Typography>
          <Typography className={classes.textSubtitleSchedule}>
            Não esqueça do seu horário marcado, você receberá uma notificação antes do seu atendimento iniciar.
          </Typography>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {savedSchedule && (
              <Typography
                className={classes.textSubtitleScheduleBox}
                style={{ fontWeight: 600, marginTop: 30 }}
              >
                {(typeof selectedDate?.getMonth === 'function') && format(selectedDate, 'dd/MM/yyyy')}
                {' '}
                às
                {' '}
                {scheduleSelected}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            margin: '10px 0px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {loadingFinally ? (
            <Box style={{ marginTop: 30, textAlign: 'center' }}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Button
              className={classes.buttonPrimary}
              onClick={handleFinally}
            >
              <Typography className={classes.textButtonPrimary}>Meus agendamentos</Typography>
            </Button>
          )}
          <Button
            onClick={() => {
              handleClose();
              history.push('/');
            }}
            className={classes.buttonOutlined}
          >
            <Typography className={classes.textButtonOutlined}>Voltar para o início</Typography>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
