import { AxiosResponse } from 'axios';
import { apiformflow, apisiseci } from './api';

export const getService = (slug: string): Promise<AxiosResponse> => apisiseci
  .get(`/cms/servicos/?slug=${slug}`);

export const searchService = (slug: string, page: number): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos_busca/?search=${slug.toUpperCase()}&ativo=true&page=${page}`);

export const searchServiceForOrgan = (
  slug: string,
  page: number,
): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos/?orgao_slug=${slug}&ativo=true&page=${page}`);

export const searchServiceForPublic = (
  publico_especifico: 'Empresa' | 'Servidor' | 'Cidadão',
): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos/?publico_especifico=${publico_especifico}&ativo=true`);

export const serviceForCategory = (slug: string, page: number): Promise<AxiosResponse> => apisiseci
  .get(`cms/servicos/?categoria_slug=${slug}&ativo=true&page=${page}`);

export const getServiceTypeUser = (userType: 'Cidadão' | 'Servidor' | 'Empresa' | 'Gestão Pública'): Promise<AxiosResponse> => apisiseci
  .get(`/cms/serv_destaques/?publico=${userType}`);

export const serviceSchedules = (): Promise<AxiosResponse> => apisiseci
  .get('/cms/servicos/?agendavel=true&ativo=true');

export const favoriteService = (servico: number, token: string): Promise<AxiosResponse> => apisiseci
  .post('/cms/servicos_favoritos/', {
    servico,
  },
  {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
export const getFavoriteService = (token: string): Promise<AxiosResponse> => apisiseci
  .get('/cms/servicos_favoritos/',
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

export const removeFavoriteService = (servico: number, token: string): Promise<AxiosResponse> => apisiseci
  .delete(`/cms/servicos_favoritos/${servico}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

export const ratingServiceAndComment = (service_id: number, avaliation: number, comments: string, token: string): Promise<AxiosResponse> => apisiseci
  .post('cms/avaliar_servico/', {
    servicos: service_id,
    avaliacao: avaliation,
    comentario: comments,
  },
  {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

export const validateDocument = (id_code: string, verify_hash: string): Promise<AxiosResponse> => apiformflow
  .get(`/flow/validar-documento-resposta/?identificador=${id_code}&verificador=${verify_hash}`);
