import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

interface Props {
  title: string;
  resume: string;
}

export default function Title({
  title,
  resume,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    mainBox: {
      width: '100%',
      minHeight: 270,
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.cardAccentColor,
      marginTop: 1,
    },
    main: {
      width: '100%',
      display: 'flex',
      maxWidth: '1052px',
      flexDirection: 'column',
      margin: '0px 10px',
    },
    text: {
      color: colors.textAccentColor,
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(32),
      marginBottom: 15,
    },
    resume: {
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.main}>
        <Typography className={classes.text}>{title}</Typography>
        <Typography className={classes.resume}>{resume}</Typography>
      </Box>
    </Box>
  );
}
