import React, { useCallback, useContext, useMemo } from 'react';
import {
  Box, makeStyles, Theme, Typography,
} from '@material-ui/core';
import {
  MdAccountCircle, MdGroups,
} from 'react-icons/md';
import typeServices from '../../../../utils/typeServiceOfProfile';
import { SearchI } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  type: 'cidadao' | 'empresa' | 'servidor';
  list: SearchI[];
}

export default function ListTypeProfile({
  type,
  list,
}: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      display: 'flex',
      width: '100%',
      maxWidth: 1440,
      flexDirection: 'column',
      margin: '20px 0px',
    },
    containerLegends: {
      display: 'flex',
      alignItems: 'center',
      gap: 14,
    },
    icon: {
      color: isActiveContrast ? colors.textAccentColor : typeServices[type].cardColor,
      fontSize: 34,
    },
    title: {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 500,
      fontFamily: 'Roboto',
      color: isActiveContrast ? colors.textAccentColor : typeServices[type].cardColor,
    },
    textOptions: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      color: colors.textBody,
      lineHeight: '1.8rem',
    },
    textInfo: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      color: colors.textBody,
      marginLeft: 30,
    },
    links: {
      textDecoration: 'none',
      color: 'inherit',
    },
  }));
  const classes = useStyles();

  const onCurrentIcon = useCallback(() => {
    if (type === 'cidadao') {
      return <MdGroups className={classes.icon} />;
    }
    if (type === 'empresa') {
      return (
        <span
          style={{
            fontSize: 34,
            width: 34,
            color: isActiveContrast ? colors.accentColor : typeServices[type].cardColor,
          }}
          className="material-icons"
        >
          handshake_outlined
        </span>
      );
    }
    return <MdAccountCircle className={classes.icon} />;
  }, [classes.icon, colors, isActiveContrast, type]);

  return (
    <Box className={classes.main}>
      <Box className={classes.containerLegends}>
        {onCurrentIcon()}
        <Typography className={classes.title}>
          {typeServices[type].title}
        </Typography>
      </Box>
      {list?.length > 0 ? (
        <ul>
          {list.map((service) => (
            <li
              key={service.slug}
              className={classes.textOptions}
            >
              <a className={classes.links} href={`/servico/${service.slug}`}>
                {service.titulo}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <Typography className={classes.textInfo}>
          Não foram encontrados serviços com o nome pesquisado.
        </Typography>
      )}
    </Box>
  );
}
