import React, { useContext } from 'react';
import {
  Box, Card, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { News } from '../../../News';
import colorContext from '../../../../Context/colorContext';

interface Props {
  newsRelation: News;
}

export default function ContentRelation({
  newsRelation,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      maxWidth: '1052px',
      backgroundColor: colors.colorBackground,
      marginBottom: 60,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
    },

    text: {
      color: colors.textBody,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(20),
      textAlign: 'center',
    },

    textRelation: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
    },

    card: {
      marginTop: 10,
      padding: 18,
      backgroundColor: colors.colorBackgroundSecundary,
      color: colors.accentColor,
      cursor: 'pointer',
      border: `1px solid ${colors.borderShadowsColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
    },
    boxTitle: {
      width: '100%',
      padding: '0 5px',
    },
    textTitle: {
      marginTop: 80,
      fontWeight: 700,
      fontFamily: 'Roboto',
      fontSize: 24,
      textTransform: 'uppercase',
      color: colors.textBody,
      textAlign: 'left',
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.main}>
      <Box width="100%">
        <Box className={classes.boxTitle}>
          <Typography
            className={classes.textTitle}
          >
            CONTEÚDO RELACIONADO
          </Typography>
          <Box
            height="4px"
            width="100%"
            marginTop="5px"
            marginBottom="56px"
            style={{
              backgroundColor: colors.textBody,
            }}
          />
        </Box>
        {newsRelation.results.slice(0, 3).map((newRelation) => (
          <Card onClick={(): void => history.push(`/noticias/${newRelation.slug}`)} className={classes.card}>
            <Typography className={classes.textRelation}>{newRelation.titulo}</Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
}
