import React, { useContext, useMemo } from 'react';
import {
  Box, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Service } from '../..';
import typeServices from '../../../../utils/typeServiceOfProfile';
import colorContext from '../../../../Context/colorContext';

interface Props {
  type: 'cidadao' | 'empresa' | 'servidor';
  service: Service;
}

export default function CardMostAccessedServices({
  type,
  service,
}: Props):JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) => ({
    card: {
      width: '100%',
      display: 'flex',
      minHeight: 113,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: isActiveContrast ? colors.colorBackgroundSecundary : '#E0E5EC',
      borderTop: `7px solid ${isActiveContrast ? colors.textAccentColor : typeServices[type].colorHighlights}`,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      cursor: 'pointer',
      margin: '25px 0px',
      padding: '0px 5px',
    },
    titleCard: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      textAlign: 'center',
      color: colors.textBody,
    },
  }));
  const classes = useStyles();
  return (
    <Box
      onClick={(): void => history.push(`/servico/${service.slug}`)}
      className={classes.card}
    >
      <Typography className={classes.titleCard}>
        {service.titulo}
      </Typography>
    </Box>
  );
}
