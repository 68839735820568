import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Box, CircularProgress, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { MdArrowForwardIos } from 'react-icons/md';
import { apisiseci } from '../../services/api';
import { Category, ES, EstatisticI } from '../Home';
import {
  CategoriesList,
  ServiceList, Title,
} from './components';
import { SearchI } from '../SearchService';
import { serviceForCategory } from '../../services/servico';
import colorContext from '../../Context/colorContext';
import { listAllCategory } from '../../services/categoria';
import { ChannelAttendante, Indicators } from '../Home/components';
import typeServiceOfProfile from '../../utils/typeServiceOfProfile';

interface Params {
  slug: string;
  public: 'cidadao' | 'empresa' | 'servidor';
}

export interface SearchII extends ES {
  results: SearchI[];
}

export interface CategoryII {
  id: number;
  slug: string;
  titulo: string;
  icone: string;
}

export default function CategoryService(): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      backgroundColor: colors.colorBackground,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
    },
    boxActions: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '14px 14px 0px 14px',
      borderRadius: 4,
      border: `1px solid ${colors.accentColor}`,
      maxWidth: 400,
      marginBottom: 44,
    },
    textTitleSelected: {
      cursor: 'pointer',
      padding: '15px 40px',
      color: colors.colorBackgroundSecundary,
      backgroundColor: colors.accentColor,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.accentColor,
      textTransform: 'uppercase',
      padding: '15px 40px',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
    },
    boxActivy: {
      width: '100%',
      textAlign: 'center',
    },
    boxFavorite: {
      width: '100%',
      textAlign: 'center',
    },
    button: {
      padding: 0,
      color: colors.textBody,
    },
    breadcrumb: {
      margin: '30px 0px',
      display: 'flex',
      width: '100%',
      maxWidth: 1380,
      alignItems: 'center',
    },
    arrowIcon: {
      fontSize: 32,
      color: isActiveContrast ? colors.textAccentColor : '#BDBDBD',
    },
    titleBreadcrumbMain: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      lineHeight: '38px',
      color: isActiveContrast ? colors.textAccentColor : '#BDBDBD',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    titleBreadcrumbSecondary: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      lineHeight: '38px',
      color: isActiveContrast ? colors.textAccentColor : '#BDBDBD',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    contentLegend: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    barSeparate: {
      width: '100%',
      height: 0,
      border: '0.5px solid #555555',
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    textLegend: {
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      minWidth: 'fit-content',
      margin: '0px 9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    emptyServiceText: {
      fontSize: theme.typography.pxToRem(28),
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 500,
      },
    },
    listCategories: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: 26,
      maxWidth: '1380px',
      padding: '10px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'initial',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      },
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const history = useHistory();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [category, setCategory] = useState<CategoryII>();
  const [listCategory, setListCategory] = useState<Category>();
  const [estatistic, setEstatistic] = useState<EstatisticI>();
  const [loadingEstatistic, setLoadingEstatistic] = useState<boolean>(false);
  const [loadingServicesCategory, setLoadingServicesCategory] = useState<boolean>(false);
  const [loadingGetCategory, setLoadingGetCategory] = useState<boolean>(false);
  const [loadingGetAllCategory, setLoadingGetAllCategory] = useState<boolean>(false);

  const getEstatistic = async () => {
    setLoadingEstatistic(true);
    try {
      const { data } = await apisiseci.get('estatisticas/');
      setEstatistic(data);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoadingEstatistic(false);
    }
  };

  const getAllServicesForCategory = async (page: number) => {
    setListSearch(undefined);
    setLoadingServicesCategory(true);
    try {
      const { data } = await serviceForCategory(params.slug, page);
      setListSearch(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingServicesCategory(false);
    }
  };

  const getCategories = async () => {
    setLoadingGetCategory(true);
    try {
      const { data } = await apisiseci.get(`/cms/temas/?slug=${params.slug}`);
      setCategory(data.results[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetCategory(false);
    }
  };

  const getAllCategories = async () => {
    setLoadingGetAllCategory(true);
    try {
      const { data } = await listAllCategory();
      setListCategory(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetAllCategory(false);
    }
  };

  const isLoading = useMemo(() => (
    (loadingEstatistic || loadingGetAllCategory || loadingGetCategory || loadingServicesCategory)
  ), [loadingEstatistic, loadingGetAllCategory, loadingGetCategory, loadingServicesCategory]);

  useEffect(() => {
    getAllCategories();
    getCategories();
    getEstatistic();
    getAllServicesForCategory(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  return (
    <Box className={classes.main}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {category && (
            <>
              <Title public_specific={params.public} category={category} />
              <Box className={classes.breadcrumb}>
                <Box
                  display="flex"
                  gridGap={10}
                >
                  <IconButton
                    onClick={() => history.push('/')}
                    className={classes.button}
                  >
                    <FiHome size={32} />
                  </IconButton>
                  <MdArrowForwardIos className={classes.arrowIcon} size={32} />
                </Box>
                <Typography
                  onClick={() => history.push(`/servico/perfil/${typeServiceOfProfile[params.public].nameLowerCase}`)}
                  component="span"
                  className={classes.titleBreadcrumbMain}
                >
                  {`Serviços para ${typeServiceOfProfile[params.public].title}`}
                </Typography>
                <MdArrowForwardIos className={classes.arrowIcon} size={32} />
                <Typography className={classes.titleBreadcrumbSecondary}>
                  {category.titulo}
                </Typography>
              </Box>
              <Box className={classes.contentLegend}>
                <div className={classes.barSeparate} />
                <Typography className={classes.textLegend}>
                  {`Serviços de ${category.titulo.toLocaleLowerCase()} para ${typeServiceOfProfile[params.public].title}`}
                </Typography>
                <div className={classes.barSeparate} />
              </Box>
            </>
          )}
          {(listSearch?.results?.length === 0) && <Typography className={classes.emptyServiceText}>Sem resultados.</Typography> }
          {(listSearch?.results) && (
            <ServiceList listSearch={listSearch} />
          )}
          <Box className={classes.contentLegend}>
            <div className={classes.barSeparate} />
            <Typography className={classes.textLegend}>
              Selecionar outras categorias
            </Typography>
            <div className={classes.barSeparate} />
          </Box>
          {listCategory?.results?.length && (
          <Box
            className={classes.listCategories}
          >
            {listCategory.results.map((category_list) => (
              <CategoriesList
                key={category_list.slug}
                categorySelected={params.slug}
                profile={params.public}
                category={category_list}
              />
            ))}
          </Box>
          )}
          <ChannelAttendante />
          {estatistic && (
          <Indicators
            indicators={estatistic}
          />
          )}
        </>
      )}
    </Box>
  );
}
