/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdMouse, MdDevices, MdPeople } from 'react-icons/md';
import { SearchI } from '../../../SearchService';
import { ModalNotAuth } from '../../../../Components';
import colorContext from '../../../../Context/colorContext';

interface Props {
  service: SearchI;
  openModalSchedulerPresential: () => void;
  openModalSchedulerOnline: () => void;
}

export default function ConsumeService({
  service,
  openModalSchedulerPresential,
  openModalSchedulerOnline,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      borderRadius: 4,
      textTransform: 'capitalize',
      marginLeft: 5,
      marginRight: 5,
      marginTop: 10,
      height: 120,
      maxWidth: 436,
      minWidth: 336,
      width: '100%',
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.serviceCardBackgroundColor,
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      '&:hover': {
        boxShadow: '0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
        backgroundColor: colors.colorBackground,
      },
    },
    labelButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      textAlign: 'left',
    },
    boxTitle: {
      width: '100%',
      padding: '0 5px',
    },
    textTitle: {
      marginTop: 120,
      fontWeight: 700,
      fontFamily: 'Roboto',
      fontSize: 24,
      textTransform: 'uppercase',
      color: colors.textBody,
      textAlign: 'left',
    },
  }));
  const classes = useStyles();
  const [isAuth, setIsAuth] = useState<boolean>(true);

  const openSchedulerPresential = (): void => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      setIsAuth(true);
      openModalSchedulerPresential();
    } else {
      setIsAuth(false);
    }
  };

  const openSchedulerOnline = (): void => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      setIsAuth(true);
      openModalSchedulerOnline();
    } else {
      setIsAuth(false);
    }
  };

  // function popupWindow(url: string, windowName: string, win: any, w: number, h: number): void {
  //   const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
  //   const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
  //   return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  // }

  const openAccessExternal = (link: string): void => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      setIsAuth(true);
      window.open(link, '_blank');
    } else {
      setIsAuth(false);
    }
  };
  return (
    <Box className={classes.main}>
      <ModalNotAuth open={!isAuth} onClose={(): void => setIsAuth(true)} />
      <Box
        display="flex"
        maxWidth="1440px"
        padding="0px 15px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        marginBottom="80px"
      >
        <Box className={classes.boxTitle}>
          <Typography
            className={classes.textTitle}
          >
            Como usar este serviço
          </Typography>
          <Box
            height="4px"
            width="100%"
            marginTop="5px"
            marginBottom="56px"
            style={{
              backgroundColor: colors.textBody,
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Button
            disabled={!service.acesso_externo}
            variant="contained"
            className={classes.button}
            classes={{
              label: classes.labelButton,
            }}
            onClick={() => openAccessExternal(service.url_externo)}
          >
            <MdMouse style={{
              marginBottom: 10,
              width: 40,
              height: 40,
              marginRight: 24,
            }}
            />
            Acessar serviço
          </Button>
          <Button
            disabled={!service.online}
            variant="contained"
            className={classes.button}
            onClick={openSchedulerOnline}
            classes={{
              label: classes.labelButton,
            }}
          >
            <MdDevices style={{
              marginBottom: 10,
              width: 40,
              height: 40,
              marginRight: 24,
            }}
            />
            Solicitar atendimento online
          </Button>
          <Button
            disabled={!service.agendavel}
            variant="contained"
            className={classes.button}
            classes={{
              label: classes.labelButton,
            }}
            onClick={openSchedulerPresential}
          >
            <MdPeople style={{
              marginBottom: 10,
              width: 40,
              height: 40,
              marginRight: 24,
            }}
            />
            Agendar atendimento presencial
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
