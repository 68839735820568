import React, { useContext } from 'react';
import {
  Box, Typography, Button,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { SearchII } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listSearch: SearchII;
}

export default function ServiceList({
  listSearch,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    card: {
      width: '100%',
      minHeight: 120,
      color: '#373F41',
      padding: 28,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 5,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    text: {
      fontWeight: 'normal',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
    },
    tag: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.secondary.main,
      color: '#fff',
      padding: '2px 14px',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    boxService: {
      margin: '8px 0px',
      width: '100%',
      minHeight: 56,
      height: 'auto',
      backgroundColor: colors.colorBackgroundSecundary,
      border: `1px solid ${colors.borderShadowsColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      padding: 18,
      display: 'flex',
      alignItems: 'center',
    },
    serviceText: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'start',
      textTransform: 'capitalize',
      textDecoration: 'none',
      color: colors.textBody,
      '&:hover': {
        color: colors.accentColor,
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
      >
        {listSearch?.results.map((search) => (
          <Box
            className={classes.boxService}
            key={`list-${search.id}`}
          >
            <Button
              onClick={(): void => history.push(`/servico/${search.slug}`)}
            >
              <Typography className={classes.serviceText}>
                {search.titulo.toLowerCase()}
              </Typography>
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
