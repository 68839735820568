import React, {
  Fragment, useEffect, useRef,
} from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { format } from 'date-fns';
import { AttendanceOnlineI } from '../..';
// eslint-disable-next-line import/extensions
import { UserData } from '../../../../services/user';

interface Props {
  chatService: AttendanceOnlineI;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxChatSended: {
    borderRadius: '8px 8px 0px 8px',
    padding: '15px 14px',
    background: theme.palette.primary.main,
    maxWidth: 332,
    color: theme.palette.common.white,
    overflowWrap: 'anywhere',
  },
  boxChatTarget: {
    borderRadius: '8px 8px 8px 0px',
    padding: '15px 14px',
    background: '#DADCE0',
    maxWidth: 332,
    color: '#373F41',
    overflowWrap: 'anywhere',
  },
  boxDownload: {
    borderRadius: '8px 8px 0px 8px',
    background: '#fff',
    maxWidth: 332,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: '#373F41',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    textDecoration: 'underline',
    marginBottom: 6,
    textTransform: 'capitalize',
  },
  schedule: {
    color: '#737B7D',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginTop: 2,
  },
  iconButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function Chat({
  chatService,
}: Props): JSX.Element {
  const classes = useStyles();
  // const matches = useMediaQuery('(min-width:935px)');
  const refElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refElement.current) {
      refElement.current.scrollTo({ behavior: 'smooth', top: refElement.current.scrollHeight });
    }
  }, [chatService]);

  const nameSplitTwo = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0].toLowerCase()} ${name.split(' ')[1][0]}.`;
    }
    return name;
  };
  const nameSplitTwoMinimazed = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    }
    return name;
  };
  const dataStorage: string | null = localStorage.getItem('gov_user_data');
  const userData: UserData | null = dataStorage
    ? JSON.parse(dataStorage)
    : null;
  return (
    <Box className={classes.main}>
      <Box
        {...{ ref: refElement } as any}
        display="flex"
        maxWidth="1052px"
        width="100%"
        marginBottom="20px"
        flexDirection="column"
        height="513px"
        style={{
          border: '1px solid #DADCE0',
          overflowY: 'auto',
        }}
        padding="20px"
      >
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography className={classes.name} style={{ marginRight: 58 }}>{nameSplitTwo(chatService.user.first_name)}</Typography>
          <Box display="flex" alignItems="flex-end">
            <Box className={classes.boxChatSended} style={{ fontWeight: 'bold' }}>
              {chatService.conteudo}
            </Box>
            <Avatar style={{ width: 48, height: 48, marginLeft: 10 }}>
              {nameSplitTwoMinimazed(chatService.user.first_name)}
            </Avatar>
          </Box>
          <Typography className={classes.schedule} style={{ marginRight: 58 }}>
            {format(new Date(chatService.created_at), 'dd/MM/yyyy - HH:mm')}
          </Typography>
        </Box>
        {chatService.anexos && chatService.anexos.length > 0 && chatService.anexos.map((anexo) => (
          <Box key={anexo.id} marginTop="30px" display="flex" flexDirection="column" alignItems="flex-end">
            <Typography className={classes.name} style={{ marginRight: 58 }}>{nameSplitTwo(chatService.user.first_name)}</Typography>
            <Box display="flex" alignItems="flex-end">
              <Box
                style={{ cursor: 'pointer' }}
                onClick={(): void => {
                  (window as any).open(anexo.arquivo);
                }}
                className={classes.boxDownload}
              >
                <IconButton
                  className={classes.iconButton}
                >
                  <GetApp />
                </IconButton>
                <span style={{ margin: '0px 10px' }}>
                  {anexo.titulo}
                </span>
              </Box>
              <Avatar style={{ width: 48, height: 48, marginLeft: 10 }}>
                {nameSplitTwoMinimazed(chatService.user.first_name)}
              </Avatar>
            </Box>
            <Typography className={classes.schedule} style={{ marginRight: 58 }}>
              {format(new Date(chatService.created_at), 'dd/MM/yyyy - HH:mm')}
            </Typography>
          </Box>
        ))}
        {chatService.respostas.map((resposta) => (
          <Fragment key={resposta.id}>
            <Box
              marginTop="30px"
              display="flex"
              flexDirection="column"
              alignItems={userData?.cpf === resposta.user.username ? 'flex-end' : 'flex-start'}
            >
              <Typography
                className={classes.name}
                style={userData?.cpf === resposta.user.username
                  ? { marginRight: 58 }
                  : { marginLeft: 58 }}
              >
                {nameSplitTwo(resposta.user.first_name)}
              </Typography>
              <Box
                display="flex"
                flexDirection={userData?.cpf === resposta.user.username ? 'row' : 'row-reverse'}
                alignItems="flex-end"
              >
                <Box
                  className={userData?.cpf === resposta.user.username ? classes.boxChatSended : classes.boxChatTarget}
                >
                  {resposta.conteudo}
                </Box>
                <Avatar style={userData?.cpf === resposta.user.username ? {
                  width: 48,
                  height: 48,
                  marginLeft: 10,
                } : {
                  width: 48,
                  height: 48,
                  marginRight: 10,
                }}
                >
                  {nameSplitTwoMinimazed(resposta.user.first_name)}
                </Avatar>
              </Box>
              <Typography className={classes.schedule} style={userData?.cpf === resposta.user.username ? { marginRight: 58 } : { marginLeft: 58 }}>
                {format(new Date(resposta.created_at), 'dd/MM/yyyy - HH:mm')}
              </Typography>
            </Box>
            {resposta.anexos && resposta.anexos.length > 0 && resposta.anexos.map((anexo) => (
              <Box
                key={anexo.id}
                marginTop="30px"
                display="flex"
                alignItems={userData?.cpf === resposta.user.username ? 'flex-end' : 'flex-start'}
                flexDirection="column"
              >
                <Typography
                  className={classes.name}
                  style={userData?.cpf === resposta.user.username
                    ? { marginRight: 58 }
                    : { marginLeft: 68 }}
                >
                  {nameSplitTwo(resposta.user.first_name)}

                </Typography>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  flexDirection={userData?.cpf === resposta.user.username ? 'row' : 'row-reverse'}
                >
                  <Box
                    style={{
                      cursor: 'pointer',
                      marginLeft: userData?.cpf === resposta.user.username ? 0 : 10,
                    }}
                    onClick={(): void => {
                      (window as any).open(anexo.arquivo);
                    }}
                    className={classes.boxDownload}
                    flexDirection={userData?.cpf === resposta.user.username ? 'row' : 'row-reverse'}
                  >
                    <IconButton
                      className={classes.iconButton}
                    >
                      <GetApp />
                    </IconButton>
                    <span style={{ margin: '0px 10px' }}>
                      {anexo.titulo}
                    </span>
                  </Box>
                  <Avatar style={{ width: 48, height: 48, marginLeft: 10 }}>
                    {nameSplitTwoMinimazed(resposta.user.first_name)}
                  </Avatar>
                </Box>
                <Typography className={classes.schedule} style={{ marginRight: 58 }}>{format(new Date(anexo.created_at), 'dd/MM/yyyy - HH:mm')}</Typography>
              </Box>
            ))}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}
