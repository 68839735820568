import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { apisiseci } from './api';

export const checarHorarios = (
  date: Date,
  unity: string,
  serviceID: number,
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci
    .get(`agendamento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`);
};

export const agendar = (
  token: string,
  cpf: string,
  servico: number,
  unidade: string,
  setor: string,
  tipo: string,
  date: Date,
  hora: string,
  para_dependente?: boolean,
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.post('agendamento/agendar/', {
    cpf,
    servico,
    unidade,
    setor,
    tipo,
    data,
    hora,
    para_dependente,
  }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const incluirDependente = (
  id_agendamento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string,
): Promise<AxiosResponse> => {
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post('agendamento/dependente/', {
    agendamento: id_agendamento,
    cpf,
    nome,
    data_nascimento: data,
    cpf_solicitante,
    grau_parentesco,
  }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export interface IParamsHistory {
  status: string;
}

export const historico = (token: string, page: number, params?: IParamsHistory): Promise<AxiosResponse> => apisiseci
  .get(`agendamento/historico/?page=${page}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
    params,
  });

export const getHistorico = (token: string, id: number): Promise<AxiosResponse> => apisiseci
  .get(`agendamento/historico/${id}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

export const cancelar = (token: string, id: number): Promise<AxiosResponse> => apisiseci
  .put(`agendamento/cancelar/${id}/`, {
    id, status: 'Cancelado',
  }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

export const feriados = (): Promise<AxiosResponse> => apisiseci
  .get('cms/feriados');
