import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

export const postAddDevice = (device_token: string, token: string): Promise<AxiosResponse> => apisiseci
  .post('/profile/device/registrar', {
    device_token,
  }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

export const postRemoveDevice = (device_token: string, token: string): Promise<AxiosResponse> => apisiseci
  .post('/profile/device/remover', {
    device_token,
  }, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
