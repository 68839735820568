/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdCategory } from 'react-icons/md';
import colorContext from '../../../../Context/colorContext';

export default function Title(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 270,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
      marginTop: 1,
    },
    container: {
      color: colors.textAccentColor,
    },
    iconCategory: {
      width: 60,
      height: 60,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        alignItems="center"
        width="100%"
        justifyContent="center"
        flexDirection="column"
        className={classes.container}
      >
        <MdCategory className={classes.iconCategory} />
        <Typography
          style={{
            textAlign: 'center',
          }}
          className={classes.container}
          variant="h4"
        >
          Lista de categorias
        </Typography>
      </Box>
    </Box>
  );
}
