import React, { useContext, useState } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

interface Props {
  image: string;
  video: string;
  detailImage: string;
  detailVideo: string;
}

export default function ImageOrVideo({
  image,
  video,
  detailVideo,
  detailImage,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      maxWidth: '1052px',
      backgroundColor: colors.colorBackground,
      marginBottom: 60,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
    },
    text: {
      color: colors.textBody,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
    },
    detail: {
      fontSize: theme.typography.pxToRem(14),
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 500,
      textAlign: 'center',
      marginTop: 16,
    },
    active: {
      backgroundColor: colors.cardAccentColor,
      color: colors.textAccentColor,
    },
    noActive: {
      color: colors.accentColor,
    },
    boxActions: {
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 4,
      border: `1px solid ${colors.accentColor}`,
      marginBottom: 44,
    },
    boxActivy: {
      width: '100%',
      textAlign: 'center',
    },
    boxFavorite: {
      width: '100%',
      textAlign: 'center',
    },
    textTitleSelected: {
      cursor: 'pointer',
      padding: '15px 40px',
      color: colors.textAccentColor,
      backgroundColor: colors.cardAccentColor,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
    },
    textTitle: {
      cursor: 'pointer',
      color: colors.accentColor,
      textTransform: 'uppercase',
      padding: '15px 40px',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
    },
    img: {
      width: '100%',
      borderRadius: 5,
    },
  }));
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState<'imagem' | 'video'>('imagem');

  return (
    <Box className={classes.main}>
      <Box className={classes.boxActions} maxWidth={video ? '400px' : '200px'}>
        <Box
          className={classes.boxActivy}
          onClick={(): void => setTabSelected('imagem')}
        >
          <Typography
            className={
            tabSelected === 'imagem'
              ? classes.textTitleSelected
              : classes.textTitle
            }
          >
            Imagem
          </Typography>
        </Box>
        {video ? (
          <Box
            className={classes.boxFavorite}
            onClick={(): void => setTabSelected('video')}
          >
            <Typography
              className={
                    tabSelected === 'video'
                      ? classes.textTitleSelected
                      : classes.textTitle
                }
            >
              Vídeo
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box width="100%">
        {tabSelected === 'imagem' && (
          <img src={image} alt="" className={classes.img} />
        )}
        {tabSelected === 'video' && (
          <iframe
            src={`https://www.youtube.com/embed/${video}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            width="100%"
            height="400px"
            allowFullScreen
            title="video"
          />
        )}
        <Typography className={classes.detail}>
          {tabSelected === 'imagem' ? detailImage : detailVideo}
        </Typography>
      </Box>
    </Box>
  );
}
