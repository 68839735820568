import React, { useContext, useEffect, useState } from 'react';
import {
  Box, CircularProgress, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Category } from '../Home';
import {
  Breadcrumb,
  PaginationService,
  CategoryListComponent,
  Title,
} from './components';
import { filterCategory } from '../../services/categoria';
import colorContext from '../../Context/colorContext';

export interface Gestor {

  arquivo: string;
  ativo: boolean;
  biografia: string;
  nome: string;

}
export interface OrgansI {
  contatoOrgao: {
    email: string;
    facebook: string;
    instagram: string;
    telefone: string;
    twitter: string;
    whatsapp: string;
    youtube: string;
  };
  created_at: string;
  descricao: string;
  endereco_orgao: {
    bairro: string;
    cep: string;
    cidade: {
      slug: string;
      cidade: string;
      estado: string;
    };
    complemento: string;
    dias_semana: string;
    endereco: string;
    hora_fim: string;
    hora_inicio: string;
    source: string;
  };
  gestor: Gestor[];
  id: number;
  nome: string;
  sigla: string;
  slug: string;
  updated_at: string;
  link: string;
  missao: string;
  valores: string;
  visao: string;
}

export default function CategoryList(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '99%',
      minHeight: 399,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
    },
    boxInput: {
      width: '99%',
      display: 'flex',
    },
    boxInputSearch: {
      display: 'flex',
      justifyContent: 'center',
      margin: '24px 0px',
    },
    inputSearch: {
      width: '100%',
      height: 64,
      outline: 'none',
      paddingLeft: 16,
      fontFamily: 'Roboto',
      fontSize: 14,
      borderRadius: 4,
      border: 'none',
      margin: '0px 10px',
      backgroundColor: colors.accentColorBackground,
      color: colors.textAccentColor,
      '&::-webkit-input-placeholder': {
        color: colors.textAccentColor,
      },
    },
    iconButton: {
      borderRadius: -1,
      color: '#2E3E3E',
      padding: 19,
      minWidth: 49,
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  const [listCategory, setListCategory] = useState<Category>();
  const [loading, setLoading] = useState<boolean>(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);

  const getCategory = async ({ page, search }: { page?:number; search?: string; }) => {
    (window as any).scrollTo(0, 0);
    setListCategory(undefined);
    setLoading(true);
    if (search) {
      setIsSearch(true);
    }
    try {
      const { data } = await filterCategory({ page, search });
      setListCategory(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleClear = () => {
    getCategory({ page: 1 });
    setTextSearch('');
    setIsSearch(false);
  };

  useEffect(() => {
    getCategory({ page: 1 });
  }, []);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Categorias" />
      <Title />
      <Box className={classes.boxInputSearch}>
        <Box
          style={{
            maxWidth: 1052,
            width: '100%',
            margin: '0px 25px 0px 10px',
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (textSearch) {
                getCategory({ search: textSearch.toLowerCase() });
              }
            }}
            style={{
              maxWidth: 520,
              borderRadius: 4,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px 10px',
              backgroundColor: colors.accentColorBackground,
            }}
          >
            <div className={classes.boxInput}>
              {!isSearch ? (
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                >
                  <FaSearch style={{ color: colors.textAccentColor }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleClear}
                  type="button"
                  className={classes.iconButton}
                >
                  <FaTimes style={{ color: colors.textAccentColor }} />
                </IconButton>
              )}

              <input
                value={textSearch}
                onChange={(e): void => setTextSearch(e.target.value)}
                className={classes.inputSearch}
                placeholder="Buscar categoria.."
              />
            </div>
          </form>
        </Box>
      </Box>

      {loading && (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {listCategory?.results.length && !loading ? (
        <>
          <CategoryListComponent listCategory={listCategory} />
          <PaginationService getCategory={getCategory} listCategory={listCategory} />
        </>
      ) : null}
      {!listCategory && !loading && (
        <Box
          marginBottom="60px"
          minHeight="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            style={{ textAlign: 'center' }}
            variant="h4"
          >
            Nenhuma categoria encontrada
          </Typography>
        </Box>
      )}
    </Box>
  );
}
