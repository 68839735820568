/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Breadcrumb,
  Filter,
  Pagination,
  Title,
  ListNews,
} from './components';
import { getAllNews, getNewForCategory } from '../../services/noticias';
import { Category, ES } from '../Home';
import { apisiseci } from '../../services/api';
import adapterNews from '../../services/adapters/noticias_liferay';
import colorContext from '../../Context/colorContext';

export interface NewsI {
  id: number;
  slug: string;
  titulo: string;
  resumo: string;
  conteudo: string;
  publicado: boolean;
  imagem_destaque_url: string;
  autor_imagem: string;
  descricao_imagem: string;
  youtube_videoId: string;
  descricao_video: string;
  categoria_slug: string;
  categoria_icone: string;
  categoria_titulo: string;
  subtopico_slug: string;
  subtopico_icone: string;
  subtopico_titulo: string;
  orgao_slug: string;
  orgao_nome: string;
  orgao_sigla: string;
  created_at: string;
  updated_at: string;
  tags_noticia: string;
}

export interface News extends ES {
  results: NewsI[];
}

export default function ServiceInfo(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  const [news, setNews] = useState<News>();
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category>();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<string>('');
  const [loadingCategories, setLoadingCategories] = useState(false);

  const getNews = async () => {
    setNews(undefined);
    setLoading(true);

    try {
      const { data } = await getAllNews(page);
      const newsData = adapterNews(data);
      setNews(newsData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getCategories = async () => {
    setLoadingCategories(true);
    const { data } = await apisiseci.get('/cms/temas/');
    const {
      total_pages, next,
    } = data;
    const lista = [];
    const loopCategorias = (pageApi: number):
    Promise<void> => new Promise((_resolve, _reject) => {
      if (pageApi > 0) {
        apisiseci.get(`/cms/temas/?page=${pageApi}`)
          .then((response) => {
            setLoadingCategories(false);
            lista.push(...response?.data?.results);
            setCategories({ ...data, results: lista });
          }).catch((err) => {
            console.log({ err });
          }).finally(() => {
            setLoadingCategories(false);
          });
      }
    });
    const promises = [];
    if (total_pages > 1 && next !== null) {
      for (let index = 0; index <= total_pages; index += 1) {
        promises.push(loopCategorias(index));
      }
    } else {
      setCategories({ ...data });
      setLoadingCategories(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Promise.all(promises).then((newData) => newData);
  };

  useEffect(() => {
    const getNewsFiltered = async (params?: string) => {
      if (params === 'Todos') {
        getNews();
        setFilter('');
        return;
      }
      setNews(undefined);
      setLoading(true);
      try {
        const { data } = await getNewForCategory(params, 1);
        const newsData = adapterNews(data);
        setNews(newsData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    if (filter) {
      getNewsFiltered(filter);
    }
  }, [filter, page]);

  useEffect(() => {
    getNews();
  }, [page]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Notícias" />
      <Title />
      {categories?.results?.length > 0
        && (window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL === 'false'
        && !loadingCategories && (
        <Filter setFilter={setFilter} categories={categories} />
      )}
      {news && news.results.length > 0 && !loading && !loadingCategories && (
      <>
        <ListNews listNews={news} />
        <Pagination setPage={setPage} listItem={news} />
      </>
      )}
      {(loading || loadingCategories) && (
        <Box
          marginBottom="60px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {(news?.results?.length === 0 && !loading && !loadingCategories) && (
      <Box
        marginBottom="60px"
        minHeight="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          style={{
            maxWidth: '280px',
            height: 'auto',
          }}
          src="/assets/images/not_found.svg"
          alt="Não encontrado"
        />
        <Typography
          style={{
            textAlign: 'center',
            marginTop: 50,
            color: colors.textBody,
          }}
          variant="h4"
        >
          Nenhuma notícia encontrada.
        </Typography>
      </Box>
      )}
    </Box>
  );
}
