/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { CategoryII } from '../..';
import colorContext from '../../../../Context/colorContext';
import typeServices from '../../../../utils/typeServiceOfProfile';
import RenderIcons from '../../../../utils/renderIcons';

interface Props {
  category: CategoryII;
  public_specific: 'cidadao' | 'empresa' | 'servidor';
}

export default function Title({
  category,
  public_specific,
}: Props): JSX.Element {
  const history = useHistory();
  const { colors, typeTheme } = useContext(colorContext);
  const isActiveContrast = useMemo(() => typeTheme === 'contraste', [typeTheme]);
  const [textSearch, setTextSearch] = useState<string>('');
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      backgroundColor: colors.colorBackground,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
    },
    text: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textAccentColor,
      fontWeight: 400,
      marginTop: 32,
    },
    containerSearch: {
      width: '100%',
      maxWidth: 1380,
      minHeight: 357,
      background: isActiveContrast ? colors.colorBackgroundSecundary : '#F2F2F2',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    containerInfo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 26,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    textTitle: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(128),
      maxWidth: '700px',
      fontWeight: 500,
      color: isActiveContrast ? colors.textAccentColor : typeServices[public_specific].color,
      marginLeft: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(32),
        width: 'auto'
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      textAlign: 'center',
      color: colors.textBody,
    },
    contentSearchInput: {
      maxWidth: 947,
      borderRadius: 20,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.colorBackgroundSecundary,
      border: `2px solid ${isActiveContrast ? colors.textAccentColor : '#032876'}`,
      margin: '20px 0px',
    },
    boxInput: {
      width: '100%',
      display: 'flex',
    },
    inputSearch: {
      width: '100%',
      height: 75,
      outline: 'none',
      paddingLeft: 20,
      paddingTop: 10,
      fontFamily: 'Roboto',
      fontSize: 14,
      borderRadius: 20,
      border: 'none',
      color: colors.textBody,
      backgroundColor: colors.colorBackgroundSecundary,
      '&::placeholder': {
        color: isActiveContrast ? colors.textAccentColor : '#C0B9B9',
        fontSize: theme.typography.pxToRem(30),
        fontFamily: 'Roboto',
        fontWeight: 600,
        lineHeight: '2.188rem',
      },
    },
    iconButton: {
      borderRadius: 0,
      color: colors.textBody,
      padding: 20,
      minWidth: 50,
      textAlign: 'center',
      marginRight: 13,
    },
  }));
  const classes = useStyles();

  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '');
      history.push(`/buscar/q=${formattedSearchText}`);
      setTextSearch('');
    }
  };
  return (
    <Box className={classes.main}>

      <Box className={classes.containerSearch}>
        <Box className={classes.containerInfo}>
          <span
            style={{
              fontSize: 150,
              color: isActiveContrast ? colors.textAccentColor : typeServices[public_specific].color,
            }}
            className="material-icons"
          >
            {category.icone}
          </span>
          <Typography className={classes.textTitle}>
            {category.titulo}
          </Typography>
        </Box>
        <Typography className={classes.title}>
          O que você procura?
        </Typography>
        <form
          onSubmit={submitSearch}
          className={classes.contentSearchInput}
        >
          <div className={classes.boxInput}>
            <input
              value={textSearch}
              onChange={(e): void => setTextSearch(e.target.value)}
              className={classes.inputSearch}
              placeholder="O que você procura?"
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
            >
              <FiSearch size={30} />
            </IconButton>
          </div>
        </form>
      </Box>
    </Box>
  );
}
