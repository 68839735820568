import React, { useContext, useState } from 'react';
import {
  Box, Card, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdPlayArrow } from 'react-icons/md';
import colorContext from '../../../../Context/colorContext';
import { VideoDialog } from '..';

interface Props {
  videos: [
    {
      titulo: string;
      resumo: string;
      video_id: string;
    },
  ]
}

export default function VideoList({ videos }: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [videoSelected, setVideoSelected] = useState<string>('');
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      marginTop: 50,
    },
    subBox: {
      width: '100%',
      maxWidth: '1052px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    card: {
      width: '100%',
      minHeight: 160,
      height: 'auto',
      marginBottom: 24,
      borderRadius: 4,
      color: theme.palette.primary.main,
      backgroundColor: colors.colorBackgroundSecundary,
      border: `1px solid ${colors.borderShadowsColor}`,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      display: 'flex',
      cursor: 'pointer',
    },
    textTitle: {
      marginTop: 24,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBody,
      textTransform: 'capitalize',
      '&:hover': {
        color: colors.accentColor,
      },
    },
    textSubTitle: {
      margin: '8px 0px',
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
      color: colors.subTitleColor,
      lineHeight: '24px',
      maxWidth: 776,
    },
    boxIcon: {
      position: 'relative',
      minWidth: 112,
      width: 112,
      margin: 24,
      height: 112,
      borderRadius: 5,
      backgroundColor: 'rgba(15, 111, 183, 0.05)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      color: colors.accentColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
      fontSize: 31,
    },
    boxInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    iconBoxMain: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxIconPlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.24)',
      border: '0.5px solid rgba(255, 255, 255, 0.8)',
      width: 24,
      height: 24,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      fontSize: 18,
      color: colors.textAccentColor,
      zIndex: 5,
    },
    img: {
      borderRadius: 4,
    },
  }));
  const classes = useStyles();

  const handleOpenVideoModal = (video_id: string) => {
    setVideoSelected(video_id);
    setOpen(true);
  };

  return (
    <Box className={classes.main}>
      <Box
        className={classes.subBox}
      >
        {videos.map((video) => (
          <Card
            onClick={() => handleOpenVideoModal(video.video_id)}
            key={video.video_id}
            className={classes.card}
          >
            <Box className={classes.boxIcon}>
              <img
                src={`https://img.youtube.com/vi/${video.video_id}/0.jpg`}
                width="100%"
                height="100%"
                alt="thumbnail"
                className={classes.img}
              />
              <div className={classes.iconBoxMain}>
                <Box className={classes.boxIconPlay}>
                  <MdPlayArrow size={20} className={classes.icon} />
                </Box>
              </div>
            </Box>
            <Box className={classes.boxInfo}>
              <Typography
                className={classes.textTitle}
              >
                {video.titulo}
              </Typography>
              <Typography
                className={classes.textSubTitle}
              >
                {video.resumo.toLowerCase()}
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
      {videos?.length > 0 && (
        <VideoDialog
          video_id={videoSelected}
          setOpen={setOpen}
          open={open}
        />
      )}
    </Box>
  );
}
