import React, { useContext, useState } from 'react';
import {
  Box,
  Snackbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Alert, Rating } from '@material-ui/lab';
import {
  MdStar, MdStarBorder, MdAnnouncement, MdClose,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { SearchI } from '../../../SearchService';
import { ModalNotAuth } from '../../../../Components';
import { ratingServiceAndComment } from '../../../../services/servico';
import colorContext from '../../../../Context/colorContext';

interface Props {
  service: SearchI;
}

export default function RatingComponent({
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxRating: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
      marginBottom: '80px',
      maxHeight: '182px',
      marginTop: 108,
      minHeight: '182px',
      backgroundColor: colors.favoriteBoxColor,
    },
    buttonRating: {
      border: `1px solid ${colors.accentColor}`,
      borderRadius: 4,
      padding: '4px 24px',
      margin: '10px 10px 40px 10px',
      color: colors.accentColor,
    },
    boxButtonsRating: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: colors.textAccentColor,
    },
    header: {
      background: colors.colorBackgroundSecundary,
    },
    textHeader: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      width: '90%',
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [isUser, setIsUser] = useState<boolean>(true);
  const [open, setOpen] = useState <boolean>(false);
  const [ratingService, setRatingService] = useState<number>(0);
  const [message, setMessage] = useState<{
    message: string;
    type: 'error' | 'success' | 'info' | 'warning' | undefined;
  }>({
    message: '',
    type: 'success',
  });
  const [comments, setComments] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleRating = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(true);
    setRatingService(parseFloat(e.target.value));
  };

  const handleComment = async () => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      setIsUser(true);
      try {
        const response = await ratingServiceAndComment(
          service.id,
          ratingService,
          comments,
          token,
        );

        if (response.data) {
          setMessage({
            message: 'Obrigado por avaliar está informação',
            type: 'success',
          });
          setOpen(false);
        }
      } catch (err) {
        if (err.response.data.message) {
          setMessage({
            message: err.response.data.message,
            type: 'error',
          });
          setOpen(false);
        } else {
          setMessage({
            message: 'Algo inesperado ocorreu',
            type: 'error',
          });
          setOpen(false);
        }
      }
    } else {
      setIsUser(false);
    }
  };

  return (
    <Box className={classes.main}>
      <ModalNotAuth open={!isUser} onClose={(): void => setIsUser(true)} />
      <Snackbar
        autoHideDuration={3000}
        onClose={(): void => setMessage({ message: '', type: message.type })}
        open={!!message.message}
      >
        <Alert
          onClose={(): void => setMessage({ message: '', type: message.type })}
          severity={message.type}
        >
          {message.message}
        </Alert>
      </Snackbar>
      <Box
        className={classes.boxRating}
      >
        <Box marginTop="40px">
          <Typography
            style={{
              color: colors.textBody,
              textTransform: 'uppercase',
              fontFamily: 'Roboto',
              fontSize: 18,
              margin: '0px 10px ',
              textAlign: 'center',
            }}
          >
            Avalie está informação em uma escala de 1 a 5 estrelas
          </Typography>
        </Box>
        <Dialog
          fullWidth
          open={open}
          onClose={() => {
            handleClose();
            history.go(0);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            onClick={handleClose}
            className={classes.buttonClose}
          >
            <MdClose />
          </IconButton>
          <div style={{ backgroundColor: colors.colorBackgroundSecundary }}>
            <DialogTitle
              className={classes.header}
            >
              <Typography className={classes.textHeader}>
                <MdAnnouncement style={{ marginRight: 15, fontSize: 24 }} />
                Deixe seu comentário
              </Typography>
            </DialogTitle>
            <DialogContent>
              <textarea
                aria-label="minimum height"
                style={{
                  width: 550,
                  height: 200,
                  backgroundColor: colors.colorBackgroundSecundary,
                  color: colors.textBody,
                }}
                placeholder="Escreva aqui..."
                maxLength={200}
                onChange={(e) => setComments(e.target.value)}
              />
            </DialogContent>
            <DialogActions
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginBottom: 15,
              }}
            >
              <Button
                style={{
                  textTransform: 'uppercase',
                  color: colors.textBody,
                  borderRadius: 4,
                  minWidth: 90,
                  height: 44,
                  marginTop: 20,
                  background: colors.colorBackground,
                }}
                variant="contained"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  textTransform: 'uppercase',
                  color: colors.accentColor,
                  borderRadius: 4,
                  minWidth: 90,
                  height: 44,
                  marginTop: 20,
                  background: colors.colorBackground,
                }}
                variant="contained"
                onClick={handleComment}
              >
                Enviar
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Box>
          <Rating
            size="large"
            value={service.total_avaliacao}
            onChange={handleRating}
            name="simple-controlled"
            icon={(
              <Box padding="10px">
                <MdStar style={{ color: colors.accentColor, width: 35, height: 35 }} />
              </Box>
            )}
            emptyIcon={(
              <Box padding="10px">
                <MdStarBorder style={{ color: colors.accentColor, width: 35, height: 35 }} />
              </Box>
            )}
          />
        </Box>
        <Typography style={{ color: colors.textBody, marginTop: 5 }}>{`(${service.total_avaliacao} avaliações)`}</Typography>
      </Box>
    </Box>
  );
}
