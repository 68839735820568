/* eslint-disable react/no-unescaped-entities */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Box, Typography, Button, CircularProgress, IconButton, useMediaQuery, Snackbar,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  FaFacebookF,
  FaLink,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import {
  MdBookmarkBorder,
  MdBookmark,
  MdAccountBalance,
  MdPrint,
  MdEventAvailable,
  MdMouse,
  MdFace,
  MdWork,
  MdBusiness,
} from 'react-icons/md';
import {
  FacebookShareButton, TwitterShareButton, WhatsappShareButton,
} from 'react-share';
import { favoriteService, getFavoriteService, removeFavoriteService } from '../../../../services/servico';
import { SearchI } from '../../../SearchService';
import { ModalNotAuth } from '../../../../Components';
import colorContext from '../../../../Context/colorContext';

interface Props {
  slug: string;
  service: SearchI;
}

export default function Title({
  slug,
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 300,
      height: 'auto',
      backgroundColor: colors.cardAccentColor,
      marginBottom: 80,
      marginTop: 1,
    },
    container: {
      color: colors.accentColor,
      maxWidth: '1440px',
      padding: '0px 15px',
      margin: '1px auto',
    },
    tag: {
      background: colors.colorBackground,
      color: colors.accentColor,
      padding: '5px 16px',
      marginBottom: 10,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      height: 30,
      borderRadius: 25,
    },
    favoriteBox: {
      display: 'flex',
      alignItems: 'center',
      color: colors.textAccentColor,
    },
    boxInfoService: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '0 10px',
    },
    titleService: {
      fontSize: 24,
      fontFamily: 'Roboto',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colors.textAccentColor,
    },
    subTitleService: {
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: colors.textAccentColor,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
    },
    boxSocialsIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#fff',
      margin: '50px 5px 20px 5px',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    btnSocial: {
      color: colors.textAccentColor,
      margin: '0 -1px',
    },
    subBoxInfoService: {
      width: '100%',
      maxWidth: 600,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '6px',
      marginTop: '20px',
    },
    time: {
      fontWeight: 'normal',
      fontSize: '0.875rem',
      color: colors.textAccentColor,
    },
    colorIcon: {
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const matchesMobile = useMediaQuery('(max-width:575px)');
  const [serviceFavorite, setServiceFavorite] = useState<boolean>(false);
  const [myServiceFavorite, setMyServiceFavorite] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [copy, setCopy] = useState<boolean>(false);

  const url: string = (window as any).location;

  const handleSearchFavoritedService = useCallback(async (data) => {
    try {
      return data.results.find((services_favorited) => services_favorited.servico.slug === service.slug) as Promise<any>;
    } catch (error) {
      return {};
    }
  }, [service.slug]);

  const handleFavoriteService = async () => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      setIsAuth(true);
      setLoading(true);
      if (!serviceFavorite) {
        const { status, data } = await favoriteService(service.id, token);
        if (status === 201) {
          setServiceFavorite(true);
          setMyServiceFavorite({ id: data.id });
        }
      } else {
        const response = await removeFavoriteService(myServiceFavorite.id, token);
        if (response.status === 204) {
          setServiceFavorite(!serviceFavorite);
        }
      }
      setLoading(false);
    } else {
      setIsAuth(false);
    }
  };

  useEffect(() => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      const handleGetFavoriteService = async () => {
        const { data } = await getFavoriteService(token);
        const searchServiceFavorited = await handleSearchFavoritedService(data);
        if (searchServiceFavorited) {
          setMyServiceFavorite(searchServiceFavorited);
          setServiceFavorite(true);
        } else {
          setServiceFavorite(false);
        }
      };
      handleGetFavoriteService();
    }
  }, [handleSearchFavoritedService]);

  return (
    <Box className={classes.main}>
      <ModalNotAuth open={!isAuth} onClose={(): void => setIsAuth(true)} />
      <Box
        display="flex"
        maxWidth="1440px"
        padding="0px 15px"
        alignItems="flex-end"
        width="100%"
        justifyContent="center"
        flexDirection="column"
        style={{
          margin: 'auto',
          marginTop: 34,
        }}
      >
        <Button className={classes.favoriteBox} disabled={loading} onClick={() => handleFavoriteService()}>
          {loading ? (
            <CircularProgress size={20} style={{ marginLeft: 5, color: colors.textAccentColor }} />
          ) : (
            <>
              {serviceFavorite ? (
                <>
                  <Typography>
                    Remover dos Favoritos
                  </Typography>
                  {' '}
                  <MdBookmark style={{ width: 24, height: 24 }} />
                </>
              ) : (
                <>
                  <Typography>
                    Adicionar aos Favoritos
                  </Typography>
                  {' '}
                  <MdBookmarkBorder style={{ width: 24, height: 24 }} />
                </>
              )}
            </>
          )}
        </Button>
      </Box>
      <Box
        display="flex"
        maxWidth="100%"
        alignItems="center"
        width="100%"
        justifyContent="center"
        flexDirection="column"
        className={classes.container}
      >
        <Box className={classes.boxInfoService}>
          <Typography
            className={classes.titleService}
          >
            {`${slug.toLowerCase()} - ${service.orgao_sigla}`}
          </Typography>
          <Box className={classes.subBoxInfoService}>
            <span
              className={classes.subTitleService}
              style={{ marginBottom: matchesMobile ? '10px' : '0px' }}
            >
              <MdAccountBalance style={{ marginRight: 5, color: colors.textAccentColor, fontSize: 24 }} />
              {' '}
              {service.orgao_nome.toLowerCase()}
            </span>
            <Typography
              className={classes.subTitleService}
            >
              <span style={{ fontSize: 24, color: colors.textAccentColor, marginRight: 5, maxWidth: 24 }} className="material-icons">
                {service.categoria_icone}
              </span>
              {service.categoria_nome.toLowerCase()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width="100%" maxWidth="1440px" padding="0px 15px" margin="1px auto">
        <Box className={classes.boxSocialsIcons}>
          <Box marginTop="30px" flexWrap="wrap" display="flex">
            {service.online && (
            <Box className={classes.tag}>
              <MdMouse className={classes.colorIcon} style={{ width: 24, height: 24 }} />
              <Typography style={{ fontSize: 13 }}>Digital</Typography>
            </Box>
            )}
            {service.agendavel && (
            <Box className={classes.tag}>
              <MdEventAvailable className={classes.colorIcon} style={{ width: 24, height: 24 }} />
              <Typography style={{ fontSize: 13 }}>Agendável</Typography>
            </Box>
            )}
            {service.publico_especifico.length && service.publico_especifico.map((publico) => (
              <Box className={classes.tag}>
                {publico === 'Cidadão' && (
                <MdFace className={classes.colorIcon} style={{ width: 24, height: 24 }} />
                )}
                {publico === 'Servidor' && (
                <MdWork className={classes.colorIcon} style={{ width: 24, height: 24 }} />
                )}
                {publico === 'Empresa' && (
                <MdBusiness className={classes.colorIcon} style={{ width: 24, height: 24 }} />
                )}
                <Typography style={{ marginLeft: 4, fontSize: 13 }}>{publico}</Typography>
              </Box>
            ))}
          </Box>
          <Box display="flex" alignItems="center" marginTop="20px">
            <Typography className={classes.time}>
              Compartilhe:
            </Typography>
            <IconButton>
              <a href={`/servico/${service.slug}/imprimir`} style={{ display: 'inherit' }} className={classes.btnSocial} target="_blank" rel="noopener noreferrer">
                <MdPrint style={{ width: 20, height: 20 }} />
              </a>
            </IconButton>
            <FacebookShareButton url={url}>
              <IconButton style={{ color: colors.textAccentColor }}>
                <FaFacebookF style={{ width: 16, height: 16 }} />
              </IconButton>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <IconButton style={{ color: colors.textAccentColor }}>
                <FaTwitter style={{ width: 16, height: 16 }} />
              </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <IconButton style={{ color: colors.textAccentColor }}>
                <FaWhatsapp style={{ width: 16, height: 16 }} />
              </IconButton>
            </WhatsappShareButton>
            <IconButton
              onClick={() => { navigator.clipboard.writeText(url); setCopy(true); }}
            >
              <FaLink style={{ color: colors.textAccentColor, width: 16, height: 16 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={copy}
        autoHideDuration={2000}
        onClose={(): void => setCopy(false)}
        message="Copiado para área de transferência"
      />
    </Box>
  );
}
