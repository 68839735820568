import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { MdLibraryBooks } from 'react-icons/md';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

export default function PaginationService(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 270,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.cardAccentColor,
      marginTop: 1,
      marginBottom: 70,
    },
    container: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
    },
    textTitle: {
      fontFamily: 'Roboto',
      fontSize: 32,
      fontWeight: 700,
      textAlign: 'center',
      color: colors.textAccentColor,
    },
    text: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textAccentColor,
      fontWeight: 400,
      marginTop: 32,
    },
    textResult: {
      marginTop: 22,
      marginBottom: 40,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(24),
      color: colors.textAccentColor,
      fontFamily: 'Roboto',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="8px"
      >
        <Box marginTop="30px">
          <MdLibraryBooks style={{ fontSize: 56, color: colors.textAccentColor }} />
        </Box>
        <Typography className={classes.textTitle}>Últimas notícias</Typography>
      </Box>
    </Box>
  );
}
