/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { EstatisticI } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  indicators: EstatisticI;
}

export default function Indicators({
  indicators,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      // padding: '80px 10px',
      minHeight: 208,
      display: 'flex',
      justifyContent: 'center',
      background: `linear-gradient(180deg, ${colors.cardAccentColor} 0%, ${colors.cardAccentColor} 100%)`,
      alignItems: 'center',
    },
    textTitle: {
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: colors.textAccentColor,
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(32),
    },
    boxIndicator: {
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    countIndicator: {
      fontSize: theme.typography.pxToRem(40),
      fontWeight: 900,
      color: colors.textAccentColor,
    },
    titleIndicator: {
      color: colors.textAccentColor,
      fontSize: theme.typography.pxToRem(24),
    },
  }));
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:935px)');
  return (
    <Box id="indicadores" className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        width="100%"
        flexDirection="column"
        padding="0px 15px"
      >
        <Typography
          className={classes.textTitle}
          variant="h5"
        >
          Indicadores
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent={matches ? 'space-between' : 'center'}
          flexDirection={matches ? 'row' : 'column'}
        >
          <Box className={classes.boxIndicator}>
            <Typography
              className={classes.countIndicator}
            >
              {indicators.servicos_total}
            </Typography>
            <Typography
              className={classes.titleIndicator}
            >
              Serviços no Portal
            </Typography>
          </Box>
          <Box className={classes.boxIndicator}>
            <Typography className={classes.countIndicator}>
              {indicators.servicos_online}
            </Typography>
            <Typography
              className={classes.titleIndicator}
            >
              Serviços Digitais
            </Typography>
          </Box>
          <Box className={classes.boxIndicator}>
            <Typography className={classes.countIndicator}>
              {indicators.servicos_agendamento}
            </Typography>
            <Typography
              className={classes.titleIndicator}
            >
              Serviços Agendáveis
            </Typography>
          </Box>
          <Box className={classes.boxIndicator}>
            <Typography className={classes.countIndicator}>
              {indicators.agendamentos_realizados}
            </Typography>
            <Typography
              className={classes.titleIndicator}
            >
              Agendamentos Concluídos
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
