import React, { useContext, useState } from 'react';
import {
  Box, IconButton, Snackbar, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdLibraryBooks } from 'react-icons/md';
import {
  FaFacebookF, FaLink, FaTwitter, FaWhatsapp,
} from 'react-icons/fa';

import {
  FacebookShareButton, TwitterShareButton, WhatsappShareButton,
} from 'react-share';
import { format } from 'date-fns';
import colorContext from '../../../../Context/colorContext';

interface Props {
  author: string;
  resume: string;
  slug: string;
  created: string;
  updated: string;
}

export default function SocialAndAuthor({
  author,
  resume,
  created,
  updated,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxMain: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.cardAccentColor,
      marginBottom: 40,
    },
    main: {
      width: '100%',
      display: 'flex',
      maxWidth: '1052px',
      flexDirection: 'column',
      margin: '20px 10px',
    },
    text: {
      color: colors.textAccentColor,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(16),
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Roboto',
    },
    time: {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
      color: colors.textAccentColor,
      display: 'flex',
      alignItems: 'center',
    },
    iconColor: {
      color: colors.textAccentColor,
    },
  }));
  const classes = useStyles();
  const [copy, setCopy] = useState<boolean>(false);
  const url: string = (window as any).location;
  const matches = useMediaQuery('(min-width:660px)');
  return (
    <Box className={classes.boxMain}>
      <Box className={classes.main}>
        <Typography className={classes.text}>
          <Typography style={{ marginRight: 6 }}>
            <MdLibraryBooks style={{ width: 24, height: 24 }} />
          </Typography>
          Por
          {' '}
          {author}
        </Typography>
        <Box
          flexDirection={matches ? 'row' : 'column'}
          alignItems={matches ? 'center' : 'flex-start'}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <Typography className={classes.time}>
            <span style={{ color: colors.textAccentColor, marginRight: 8 }} className="material-icons">
              edit_calendar
            </span>
            Públicado
            {' '}
            {format(new Date(created), 'dd/MM/yyyy - HH:mm')}
            {' '}
            - Atualizado
            {' '}
            {format(new Date(updated), 'dd/MM/yyyy - HH:mm')}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography className={classes.time}>
              Compartilhe:
            </Typography>
            <FacebookShareButton url={url} quote={resume}>
              <IconButton className={classes.iconColor}>
                <FaFacebookF style={{ width: 16, height: 16 }} />
              </IconButton>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <IconButton className={classes.iconColor}>
                <FaTwitter style={{ width: 16, height: 16 }} />
              </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <IconButton className={classes.iconColor}>
                <FaWhatsapp style={{ width: 16, height: 16 }} />
              </IconButton>
            </WhatsappShareButton>
            <IconButton
              onClick={() => { navigator.clipboard.writeText(url); setCopy(true); }}
              className={classes.iconColor}
            >
              <FaLink style={{ width: 16, height: 16 }} />
            </IconButton>
          </Box>
        </Box>
        <Snackbar
          open={copy}
          autoHideDuration={2000}
          onClose={(): void => setCopy(false)}
          message="Copiado para área de transferência"
        />
      </Box>
    </Box>
  );
}
