import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { MdClose, MdOutlineOndemandVideo } from 'react-icons/md';

interface Props {
  video_id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  textHeaderModal: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    width: '90%',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 13,
  },
  header: {
    background: theme.palette.primary.main,
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#fff',
  },
  boxText: {
    color: '#373F41',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.25,
  },
}));

export default function TermoDialog({ video_id, setOpen, open }: Props): JSX.Element {
  const classes = useStyles();
  const [showIframe, setShowIframe] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setShowIframe(true);
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        className={classes.buttonClose}
      >
        <MdClose />
      </IconButton>
      <DialogTitle
        className={classes.header}
      >
        <div className={classes.headerBox}>
          <MdOutlineOndemandVideo style={{ fontSize: 24 }} />
          <Typography className={classes.textHeaderModal}>
            Vídeo
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {showIframe && (
        <iframe
          style={{ width: '100%' }}
          height="350"
          src={`https://www.youtube.com/embed/${video_id}`}
          title="YouTube video player"
          frameBorder="0"
          allow="fullscreen;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        )}
      </DialogContent>
    </Dialog>
  );
}
