import React, { useContext } from 'react';
import {
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { News } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listItem: News;
  setPage: (page: number) => void;
}

export default function PaginationService({
  listItem,
  setPage,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      marginTop: 62,
      marginBottom: 100,
      '& .Mui-selected': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackgroundSecundary,
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: colors.accentColor,
          color: colors.colorBackgroundSecundary,
        },
      },
    },
    paginationItem: {
      color: colors.textBody,
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackgroundSecundary,
      },
    },
    paginationSelected: {
      color: '#fff',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{ root: classes.paginationItem, selected: classes.paginationSelected }}
          />
        )}
        count={listItem.total_pages}
        page={listItem.current}
        onChange={(e, page) => {
          setPage(page);
        }}
      />
    </Box>
  );
}
