import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Category } from '../../../Home';

const useStyles = makeStyles((theme: Theme) => createStyles({
  boxFilter: {
    marginBottom: '30px',
    width: '100vw',
    maxWidth: '1052px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  itemMenu: {
    borderRadius: '0',
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    maxWidth: 280,
  },
  textMenu: {
    width: '100%',
    padding: '10px',
    justifyContent: 'center',
    color: '#737B7D',
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  // eslint-disable-next-line react/require-default-props
  setFilter?: any;
  categories: Category;
}

export default function FilterAttendence({ setFilter, categories }: Props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string>('');

  useEffect(() => {
    setFilter(selected);
  }, [selected, setFilter]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item>
        <Box className={classes.boxFilter}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            classes={{ root: classes.root }}
          >
            <InputLabel id="demo-simple-select-outlined-label">Filtrar notícia</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={(event): void => {
                setSelected(event.target.value as string);
              }}
              value={selected}
              label="Filtrar notícia"
              classes={{
                select: classes.menuItem,
              }}
            >
              <MenuItem
                className={classes.textMenu}
                value="Todos"
              >
                Todos
              </MenuItem>
              {categories.results.map((category) => (
                <MenuItem
                  key={category.id}
                  className={classes.textMenu}
                  value={category.slug}
                >
                  {category.titulo}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}
