import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import { registrarDispositivo } from './avisos';

const firebaseConfig = {
  apiKey: (window as any)._env_.REACT_APP_FIREBASE_KEY ?? '',
  authDomain: (window as any)._env_.REACT_APP_AUTH_DOMAIN ?? '',
  projectId: (window as any)._env_.REACT_APP_PROJECT_ID ?? '',
  storageBucket: (window as any)._env_.REACT_APP_STORAGE_BUCKET ?? '',
  messagingSenderId: (window as any)._env_.REACT_APP_MESSAGING_SENDER_ID ?? '',
  appId: (window as any)._env_.REACT_APP_APP_ID ?? '',
  measurementId: (window as any)._env_.REACT_APP_MEASUREMENT_ID ?? '',
};

export const initializeApp = () => {
  firebase.initializeApp(firebaseConfig);
};

export const initializeAnalytics = () => {
  firebase.analytics();
};

export const initializeMessaging = () => {
  const messaging = firebase.messaging();
  return messaging;
};

export const registrationServiceWorker = async () => {
  const registration = navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js?apiKey=${(window as any)._env_.REACT_APP_FIREBASE_KEY}&authDomain=${(window as any)._env_.REACT_APP_AUTH_DOMAIN}&projectId=${(window as any)._env_.REACT_APP_PROJECT_ID}&storageBucket=${(window as any)._env_.REACT_APP_STORAGE_BUCKET}&messagingSenderId=${(window as any)._env_.REACT_APP_MESSAGING_SENDER_ID}&appId=${(window as any)._env_.REACT_APP_APP_ID}&measurementId=${(window as any)._env_.REACT_APP_MEASUREMENT_ID}`);
  return registration;
};

export const registrationTokenDevice = async (currentToken) => {
  try {
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    if (token) {
      await registrarDispositivo(token, currentToken);
    }
  } catch (error) {
    console.log('error to register device', error);
  }
};

export const getToken = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      initializeApp();
      const messaging = initializeMessaging();
      const registration = await registrationServiceWorker();

      if (messaging && registration) {
        const currentToken = await messaging.getToken({
          serviceWorkerRegistration: registration,
          vapidKey:
            (window as any)._env_.REACT_APP_CLOUD_MESSAGING_VALIP_KEY ?? '',
        });

        if (currentToken) {
          await registrationTokenDevice(currentToken);
        }
        console.log('current token for client: ', currentToken);
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};

export const onMessageListener = () => new Promise((resolve) => {
  const messaging = initializeMessaging();
  messaging.onMessage((payload) => {
    // console.log('Message received. front', payload);
    resolve(payload);
  });
});
