/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { MdInfo } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';
import { FaPhoneSquare } from 'react-icons/fa';
import { IoChatbubbles } from 'react-icons/io5';
import { AiFillBank } from 'react-icons/ai';
import colorContext from '../../../../Context/colorContext';
import theme from '../../../../services/theme-service';

export default function ChannelAttendante(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      padding: '80px 5px',
      flexDirection: 'column',
    },
    card: {
      borderRadius: 4,
      width: '100%',
      maxWidth: 300,
      backgroundColor: colors.colorBackgroundSecundary,
      border: '1px solid #eeee',
      display: 'flex',
      justifyContent: 'center',
      '&:hover': {
        boxShadow: '0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
        cursor: 'pointer',
      },
    },
    linkCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0px',
      textDecoration: 'none',
    },
    cardMobile: {
      marginBottom: 10,
      marginLeft: 5,
      marginRight: 5,
      width: '47%',
      height: 204,
      color: colors.accentColor,
      fontSize: theme.typography.pxToRem(18),
      backgroundColor: colors.textBody,
      boxShadow: '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.05)',
      borderRadius: 4,
      '&:hover': {
        color: colors.textBody,
        background: `linear-gradient(180deg, ${colors.accentColor} 0%, ${colors.accentColor} 100%)`,
        transition: 'all 0.2s ease',
      },
    },
    textTitle: {
      position: 'relative',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      padding: '10px 0px 0px 3px',
      marginBottom: 5,
      fontWeight: 'bold',
      color: colors.textBody,
      left: 8,
      '&::after': {
        content: '""',
        display: 'block',
        width: 4,
        height: 24,
        backgroundColor: colors.textBody,
        position: 'absolute',
        bottom: 6,
        marginLeft: -14,
        left: 5,
      },
    },
    textSubTitle: {
      position: 'relative',
      fontFamily: 'Roboto',
      paddingBottom: 40,
      color: colors.textBody,
      letterSpacing: '0.1px',
      lineHeight: '24px',
      fontWeight: 400,
      fontSize: 18,
      opacity: 0.5,
    },
    IconNavite: {
      color: colors.accentColor,
      width: 44,
      height: 44,
      border: `1px solid ${colors.accentColor}`,
      borderRadius: '4px 4px 4px 4px',
      '&:hover': {
        backgroundColor: colors.cardAccentColor,
        color: colors.textAccentColor,
      },
      '& .MuiIconButton-label': {
        width: 'auto',
      },
    },
    textIcon: {
      fontSize: 18,
      marginTop: 20,
      lineHeight: 20,
    },
    icon: {
      width: 24,
      height: 24,
    },
    textLegend: {
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      minWidth: 'fit-content',
      margin: '0px 9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(30),
        textAlign: 'center',
      },
    },
    contentLegend: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    barSeparate: {
      width: '100%',
      height: 0,
      border: '0.5px solid #555555',
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    textInfo: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.accentColor,
      textAlign: 'center',
      marginBottom: 12,
    },
    legendCard: {
      fontSize: theme.typography.pxToRem(22),
      fontFamily: 'Roboto',
      color: colors.accentColor,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  return (
    <Box id="atendimento" className={classes.main}>
      <Box className={classes.contentLegend}>
        <div className={classes.barSeparate} />
        <Typography className={classes.textLegend}>
          Canais de atendimento
        </Typography>
        <div className={classes.barSeparate} />
      </Box>
      <Typography className={classes.textInfo}>
        Dúvidas? Entre em contato
      </Typography>
      <Box
        display="flex"
        maxWidth="1440px"
        width="100%"
        gridGap={10}
        justifyContent="center"
        flexWrap="wrap"
      >
        <Box
          className={classes.card}
        >
          <a
            className={classes.linkCard}
            href={(window as any)._env_.REACT_APP_LINK_ACESSO_INFORMACAO}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdInfo size={60} color={colors.accentColor} />
            <Typography className={classes.legendCard}>
              Acesso à informação
            </Typography>
          </a>
        </Box>
        <Box
          className={classes.card}
        >
          <a
            href={(window as any)._env_.REACT_APP_LINK_TELEFONES_UTEIS}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkCard}
          >
            <FaPhoneSquare size={60} color={colors.accentColor} />
            <Typography className={classes.legendCard}>
              Telefones úteis
            </Typography>
          </a>
        </Box>
        <Box className={classes.card}>
          <a
            className={classes.linkCard}
            href={(window as any)._env_.REACT_APP_LINK_ACESSO_OUVIDORIA}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoChatbubbles size={60} color={colors.accentColor} />
            <Typography className={classes.legendCard}>
              Ouvidoria
            </Typography>
          </a>
        </Box>
        <Box className={classes.card}>
          <a
            href="/"
            className={classes.linkCard}
          >
            <AiFillBank size={60} color={colors.accentColor} />
            <Typography className={classes.legendCard}>
              Atendimentos Presenciais
            </Typography>
          </a>
        </Box>
      </Box>
    </Box>
  );
}
