import React, { useContext } from 'react';
import {
  Box, Card, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { News } from '../..';
import colorContext from '../../../../Context/colorContext';

interface Props {
  listNews: News;
}

export default function ServiceList({
  listNews,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
    },
    card: {
      width: '100%',
      minHeight: 240,
      color: colors.textBody,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 12,
      cursor: 'pointer',
      background: colors.colorBackgroundSecundary,
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      border: `1px solid ${colors.borderShadowsColor}`,
      '&:hover': {
        color: colors.accentColor,
      },
    },
    text: {
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(13),
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
    title: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      fontFamily: 'Roboto',
    },
    organ: {
      fontWeight: 300,
      fontSize: theme.typography.pxToRem(16),
    },
    descriptionSubTitle: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 400,
    },
    description: {
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'Roboto',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
    },
    img: {
      margin: 24,
      width: 192,
      height: 192,
      objectFit: 'cover',
      borderRadius: 5,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(min-width:660px)');

  const redirectToNew = (slug) => {
    if ((window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL === 'true') {
      (window as any).open(`${(window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL_URL}/pt/web/guest/-/${slug}`);
    } else {
      history.push(`/noticias/${slug}`);
    }
  };

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        flexWrap="wrap"
        margin="0px 10px"
      >
        {listNews.results.map((newItem) => (
          <Card
            onClick={(): void => redirectToNew(newItem.slug)}
            key={newItem.id}
            className={classes.card}
            style={{ flexDirection: !matches ? 'column-reverse' : undefined }}
          >
            {newItem.imagem_destaque_url && (
            <img src={newItem.imagem_destaque_url} className={classes.img} alt="img notícia" />
            )}
            <Box
              padding="20px"
              justifyContent="space-between"
              flexDirection="column"
              display="flex"
            >
              <Typography
                className={classes.text}
              >
                {newItem.subtopico_titulo}
              </Typography>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.title}>
                    {newItem.titulo}
                  </Typography>
                  {newItem.orgao_nome && (
                    <Typography
                      className={classes.organ}
                    >
                      Órgão
                      {' '}
                      {newItem.orgao_nome}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box className={classes.descriptionSubTitle}>
                <div dangerouslySetInnerHTML={{ __html: newItem.resumo }} />
              </Box>
              <Typography className={classes.description}>
                <span className="material-icons" style={{ color: colors.textBody, marginRight: 5 }}>
                  edit_calendar
                </span>
                Publicado
                {' '}
                {format(new Date(newItem.created_at), 'MMM d, yyyy', { locale: ptBR })}
                {' '}
                - Atualizado
                {' '}
                {format(new Date(newItem.updated_at), 'MMM d, yyyy', { locale: ptBR })}
              </Typography>
            </Box>

          </Card>
        ))}
      </Box>
    </Box>
  );
}
