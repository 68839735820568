import React, { useEffect, useState } from 'react';
import {
  Box, CircularProgress, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import { ES } from '../Home';
import {
  Breadcrumb,
  PaginationService,
  ServiceList, Title,
} from './components';
import FilterSchedule from './components/FilterSchedule';
import { historico, IParamsHistory } from '../../services/agendamento';
import NavBarLink from '../QuickAccess/components/NavBarLink';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    minHeight: 400,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
}));

interface Params {
  slug: string;
}

export interface ServicoUnidadeI {
  id: number;
  agendamento: boolean;
  atendimento: boolean;
  unidade: {
    ativo: boolean;
    bairro: string;
    complemento: string;
    endereco: string;
    nome: string;
    slug_unidade: string;
    source: string;
  };
}

export interface HistoryI {
  id: number;
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: {
    ativo: boolean;
    created_at: string;
    id: number;
    ordem: number;
    setor: number;
    tipo: string;
    updated_at: string;
    user: Array<number>;
  };
  hora: string;
  hora_inicio: string | null;
  prioridade: false
  rastreamento_avaliacao_atendimento: [];
  orgao_responsavel: string;
  servico: {
    acesso_externo: boolean;
    agendavel: boolean;
    ativo: boolean;
    created_at: string;
    custo: string;
    descricao: string;
    online: boolean;
    publico: string;
    setor: number;
    slug: string;
    tema: number;
    tempo: number;
    tempo_online: number;
    tempo_total: number;
    tipo_tempo: string;
    titulo: string;
    updated_at: string;
    url_externo: string | null;
    user: number;
  };
  setor: undefined;
  status: string;
  ticket: string;
  tipo: string;
  unidade: {
    ativo: boolean;
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string | null;
    created_at: string;
    endereco: string;
    nome: string;
    orgao: number;
    slug_unidade: string;
    source: string;
    updated_at: string;
    user: number;
  };
  updated_at: string;
  user: {
    first_name: string;
    id: number;
    last_name: string | null;
    username: string;
  };
}

export interface ScheduleHist extends ES {
  results: HistoryI[];
}

export default function MySchedules(): JSX.Element {
  const classes = useStyles();
  const params: Params = useParams();
  const [loading, setLoading] = useState < boolean >(false);
  const [filterParams, setFilterParams] = useState < IParamsHistory | null >(null);
  const [scheduleHistory, setScheduleHistory] = useState < ScheduleHist >();
  const getHistory = async (page: number, params?: IParamsHistory): Promise<void> => {
    (window as any).scrollTo(0, 0);
    setScheduleHistory(undefined);
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    setLoading(true);
    if (token) {
      try {
        const { data } = await historico(token, page, params);
        setScheduleHistory(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (filterParams) {
      getHistory(1, filterParams);
    } else {
      getHistory(1);
    }
  }, [filterParams]);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Agendamentos" />
      <NavBarLink />
      <Title slug={params.slug} />
      <FilterSchedule setFilter={setFilterParams} />
      {loading && (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {scheduleHistory?.results.length ? (
        <>
          <ServiceList listSchedules={scheduleHistory} />
          <PaginationService getSearch={getHistory} listSearch={scheduleHistory} />
        </>
      ) : null}
      {(scheduleHistory?.results.length === 0 && !loading) && (
        <>

          <Box
            marginBottom="60px"
            minHeight="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              style={{ textAlign: 'center' }}
              variant="h4"
            >
              Nenhum agendamento encontrado
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
