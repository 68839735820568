import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import typeServices from '../../utils/typeServiceOfProfile';

interface Props{
  type: 'cidadao' | 'empresa' | 'servidor';
  count: number | undefined;
}

export default function BoxPerfil({
  type,
  count,
}: Props): JSX.Element {
  const { colors, typeTheme } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    boxServicePerfil: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 345,
      height: 334,
      borderRadius: 10,
      border: `1.5px solid ${typeTheme === 'contraste' ? colors.textAccentColor : typeServices[type].cardColor}`,
      cursor: 'pointer',
      backgroundColor: colors.colorBackground,
    },
    img: {
      width: 130,
      height: 130,
    },
    textPerfil: {
      margin: '16px 0px',
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(40),
      lineHeight: '3rem',
      textAlign: 'center',
      fontWeight: 500,
      color: typeTheme === 'contraste' ? colors.textAccentColor : typeServices[type].cardColor,
    },
    textService: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: '1.313rem',
      textAlign: 'center',
      color: colors.textBody,
    },
  }));
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box
      onClick={(): void => history.push(`/servico/perfil/${type}`)}
      className={classes.boxServicePerfil}
    >
      <img
        alt="cidadão"
        src={typeServices[type].img}
        width="100%"
        height="auto"
        className={classes.img}
      />
      <Typography className={classes.textPerfil}>
        {typeServices[type].title}
      </Typography>
      <span className={classes.textService}>
        {(count || count === 0) ? `${count} Serviços` : <CircularProgress size={25} />}
      </span>
    </Box>
  );
}
