import React, { useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { RouteLayout, ScrollToTop } from './Components';
import {
  Home,
  LoginCallback,
  OrganDetail,
  OrgansList,
  SeachService,
  ServiceInfo,
  MySchedules,
  MyAttendences,
  SchedulePrint,
  NotFound,
  AttendanceOnlineChat,
  News,
  newDetail,
  Workspace,
  QuickAccess,
  Terms,
  MyInformations,
  CategoryList,
  VideoList,
  Accessibility,
  ServiceByProfile,
} from './Pages';
import WithHeader from './Components/Layouts/WithHeader';
import BlankLayout from './Components/Layouts/BlankLayout';
import ServiceInfoPrint from './Pages/ServiceInfoPrint';
import Reschedule from './Pages/Reschedule';
import ColorContext from './Context/colorContext';
import DocumentValidation from './Pages/DocumentValidation';
import AttendanceOnlineMobileRedirect from './Pages/AttendanceOnlineMobileRedirect';
import CategoryService from './Pages/CategoryService';

interface ColorContextProps {
  accentColor: string;
  accentColorBackground: string;
  colorBackground: string;
  colorBackgroundSecundary: string;
  textBody: string;
  textAccentColor: string;
  badgeColor: string;
  cardAccentColor: string;
  accordionTextColor: string;
  serviceCardBackgroundColor: string;
  favoriteBoxColor: string;
  breadcrumbButtonColorHover: string;
  borderShadowsColor: string;
  buttonSuccessColor: string;
  buttonErrorColor: string;
  accentColorSecondary: string;
  iconStepColor: string;
  stepLabelColor: string;
  menuItemColor: string;
  subTitleColor: string;
  colorBackgroundSearch: string;
}

function App(): JSX.Element {
  const [typeTheme, setTypeTheme] = useState<string>('normal');

  const handleThemeColor = useCallback((type: string) => {
    localStorage.setItem('@portalunico/themeColor', type);
    setTypeTheme(type);
  }, []);

  const [colors, setColors] = useState<ColorContextProps>({
    accentColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    accentColorBackground: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    colorBackground: (window as any)._env_.REACT_APP_COLOR_SECONDARY,
    colorBackgroundSecundary: '#ffffff',
    textBody: '#6B6D6E',
    textAccentColor: '#ffffff',
    badgeColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    cardAccentColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: (window as any)._env_.REACT_APP_COLOR_SECONDARY,
    iconStepColor: '#94C120',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#706E7A',
    colorBackgroundSearch: '#FAFCF9',
  });

  useEffect(() => {
    switch (typeTheme) {
      case 'normal':
        setColors({
          accentColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
          accentColorBackground: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
          colorBackground: '#ffffff',
          colorBackgroundSecundary: '#ffffff',
          textBody: '#333333',
          textAccentColor: '#ffffff',
          badgeColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
          cardAccentColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
          accordionTextColor: '#272727',
          serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
          favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
          breadcrumbButtonColorHover: '#d4d4d4',
          borderShadowsColor: '#E1E6EF',
          buttonSuccessColor: '#3CD278',
          buttonErrorColor: '#F03D3D',
          accentColorSecondary: '#00A97A',
          iconStepColor: '#94C120',
          stepLabelColor: '#ccc',
          menuItemColor: '#d4d4d4',
          subTitleColor: '#706E7A',
          colorBackgroundSearch: '#FAFCF9',
        });
        break;

      case 'contraste':
        setColors({
          accentColor: '#FFFF1A',
          accentColorBackground: '#1F1F1F',
          colorBackground: '#121212',
          colorBackgroundSecundary: '#1F1F1F',
          textBody: '#FFFF1A',
          textAccentColor: '#FFFF1A',
          badgeColor: '#121212',
          cardAccentColor: '#1F1F1F',
          accordionTextColor: '#FFFF1A',
          serviceCardBackgroundColor: '#1F1F1F',
          favoriteBoxColor: '#1F1F1F',
          breadcrumbButtonColorHover: '#1F1F1F',
          borderShadowsColor: '#FFFF1A',
          buttonSuccessColor: '#FFFF1A',
          buttonErrorColor: '#FFFF1A',
          accentColorSecondary: '#121212',
          iconStepColor: '#1F1F1F',
          stepLabelColor: '#ccc',
          menuItemColor: '#121212',
          subTitleColor: '#FFFF1A',
          colorBackgroundSearch: '#1F1F1F',
        });
        break;

      default:
        break;
    }
  }, [typeTheme]);

  useEffect(() => {
    const getThemeLocal = () => {
      const theme = localStorage.getItem('@portalunico/themeColor');
      const themeInLocal = theme || 'normal';
      setTypeTheme(themeInLocal);
    };

    getThemeLocal();
  }, []);

  return (
    <ColorContext.Provider value={{ colors, setTypeTheme: handleThemeColor, typeTheme }}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <RouteLayout
            path="/"
            exact
            component={Home}
            layout={WithHeader}
          />
          <RouteLayout
            path="/buscar/q=:slug"
            exact
            component={SeachService}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servico/:slug"
            exact
            component={ServiceInfo}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servico/perfil/cidadao"
            exact
            component={ServiceByProfile}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servico/perfil/empresa"
            exact
            component={ServiceByProfile}
            layout={WithHeader}
          />
          <RouteLayout
            path="/servico/perfil/servidor"
            exact
            component={ServiceByProfile}
            layout={WithHeader}
          />
          <Route
            path="/servico/:slug/imprimir"
            exact
            component={ServiceInfoPrint}
          />
          <RouteLayout
            path="/servico/:slug/presencial=:modal"
            exact
            component={ServiceInfo}
            layout={WithHeader}
          />
          <RouteLayout
            path="/categoria/:slug/:public"
            exact
            component={CategoryService}
            layout={WithHeader}
          />
          <RouteLayout
            path="/categorias"
            exact
            component={CategoryList}
            layout={WithHeader}
          />
          <RouteLayout
            path="/meus_agendamentos"
            exact
            component={MySchedules}
            layout={WithHeader}
            isAuth
          />
          <RouteLayout
            path="/meus_atendimentos"
            exact
            component={MyAttendences}
            layout={WithHeader}
            isAuth
          />
          <Route path="/login/callback/" component={LoginCallback} />
          <RouteLayout exact path="/videos" layout={WithHeader} component={VideoList} />
          <RouteLayout exact path="/orgaos" layout={WithHeader} component={OrgansList} />
          <RouteLayout exact path="/orgao/:slug" layout={WithHeader} component={OrganDetail} />
          <RouteLayout exact path="/acessibilidade" layout={WithHeader} component={Accessibility} />
          <Route exact path="/agendamento/comprovante/:id" component={SchedulePrint} />
          <RouteLayout exact path="/validacao_documento" layout={WithHeader} component={DocumentValidation} />
          <RouteLayout
            exact
            path="/atendimento_online/:id"
            layout={WithHeader}
            component={AttendanceOnlineChat}
          />
          <RouteLayout
            exact
            path="/mobile/atendimento_online/:id/receive_token/:tokenSSO"
            layout={BlankLayout}
            component={AttendanceOnlineMobileRedirect}
          />
          <RouteLayout
            exact
            path="/mobile/atendimento_online/:id"
            layout={BlankLayout}
            component={AttendanceOnlineChat}
          />
          <RouteLayout exact path="/noticias" layout={WithHeader} component={News} />
          <RouteLayout exact path="/noticias/:slug" layout={WithHeader} component={newDetail} />
          <RouteLayout
            exact
            path="/workspace"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/termos"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/permissoes"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/meus_agendamentos"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/atividades"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/minha-area"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/minha-area/privacidade"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/minha-area/meus-dados"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/favoritos"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/servico-digital"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/formularios/:slug"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/servico-digital/:tema_slug/:tema"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/atendimento_online/:id"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/seguranca"
            isAuth
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            path="/workspace/minhas_solicitacoes"
            exact
            isAuth
            component={Workspace}
            layout={BlankLayout}
          />
          <RouteLayout
            path="/workspace/minhas_solicitacoes/view_doc/:uuid_doc/:identificador_solcitacao"
            exact
            isAuth
            component={Workspace}
            layout={BlankLayout}
          />

          <RouteLayout
            exact
            path="/workspace/avaliar_atendimento_presencial/:ticket"
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/avaliar_atendimento_interno/:ticket"
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            path="/workspace/avaliar_atendimento_online/:ticket"
            exact
            layout={BlankLayout}
            component={Workspace}
          />

          <RouteLayout
            exact
            path="/workspace/avaliar_atendimento_diario/:ticket"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/avaliar_servico/:servico_slug/:perfil?"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/buscar_servico/avaliar"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/workspace/buscar_servico/avaliar/:servico_slug"
            layout={BlankLayout}
            component={Workspace}
          />
          <RouteLayout
            exact
            path="/acesso-rapido"
            isAuth
            layout={WithHeader}
            component={QuickAccess}
          />
          <RouteLayout
            exact
            path="/termos"
            isAuth
            layout={WithHeader}
            component={Terms}
          />
          <RouteLayout
            exact
            path="/meus_dados"
            isAuth
            layout={WithHeader}
            component={MyInformations}
          />
          <RouteLayout
            path="/reagendamentos/:url"
            exact
            component={Reschedule}
            layout={BlankLayout}
          />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ColorContext.Provider>
  );
}

export default App;
