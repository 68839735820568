/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ServiceProfileI } from '../..';
import colorContext from '../../../../Context/colorContext';
import MostAccessedServices from '../../../../Components/MostAccessedServices';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  listServiceProfile: ServiceProfileI;
}

export default function ServiceProfile({
  listServiceProfile,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      padding: '0px 5px',
      flexDirection: 'column',
    },
    textLegend: {
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      minWidth: 'fit-content',
      margin: '0px 9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(30),
        textAlign: 'center',
      },
    },
    contentLegend: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    barSeparate: {
      width: '100%',
      height: 0,
      border: '0.5px solid #555555',
      margin: '0px 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box id="servicos" className={classes.main}>
      <Box className={classes.contentLegend}>
        <div className={classes.barSeparate} />
        <Typography className={classes.textLegend}>
          Serviços em destaque
        </Typography>
        <div className={classes.barSeparate} />
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        maxWidth="1440px"
        marginBottom="43px"
        flexWrap="wrap"
      >
        <MostAccessedServices
          type="cidadao"
          list={listServiceProfile?.cidadao}
        />
        <MostAccessedServices
          type="empresa"
          list={listServiceProfile?.empresa}
        />
        <MostAccessedServices
          type="servidor"
          list={listServiceProfile?.servidor}
        />
      </Box>
    </Box>
  );
}
