import { AxiosResponse } from 'axios';
import { apinoticias } from './api';

export const getAllNews = (page: number): Promise<AxiosResponse> => {
  if ((window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL === 'true') {
    return apinoticias.get(
      `${(window as any)._env_.REACT_APP_NOTICIAS_EXTERNAL_ROUTER}?page=${page}`
    );
  }
  return apinoticias.get(`/cms/noticias/?page=${page}`);
};

export const getNew = (slug: string): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/noticias/?slug=${slug}`);

export const getNewForCategory = (
  category_slug: string,
  page: number
): Promise<AxiosResponse> =>
  apinoticias.get(
    `/cms/noticias/?categoria_slug=${category_slug}&page=${page}`
  );

export const searchNew = (text: string, page: number) =>
  apinoticias.get(
    `/cms/noticias/?search=${text.toLocaleUpperCase()}&page=${page}`
  );
