import React from 'react';

export default function Close({ color = '#868686' }: { color: string}): JSX.Element {
  return (
    <svg width="44" height="45" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.7438 19.969L19.3027 45.41" stroke={color} />
      <path d="M19.3027 19.969L44.7438 45.41" stroke={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.0183 64.0239C49.3237 64.0239 63.3527 49.9949 63.3527 32.6892C63.3527 15.3837 49.3237 1.35474 32.0183 1.35474C14.7126 1.35474 0.683594 15.3837 0.683594 32.6892C0.683594 49.9949 14.7126 64.0239 32.0183 64.0239Z" stroke={color} />
    </svg>
  );
}
