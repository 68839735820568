import React, { useContext } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { MdOutlineCalendarToday, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import Slider, { Settings, CustomArrowProps } from 'react-slick';
import { News } from '../../../News';
import colorContext from '../../../../Context/colorContext';

interface Props {
  newsList: News;
}

export default function NewsComponent({
  newsList,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      alignItems: 'center',
      padding: '100px 5px',
    },
    textTitle: {
      position: 'relative',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      padding: '10px 0px 0px 3px',
      marginBottom: 5,
      fontWeight: 'bold',
      color: '#333333',
      left: 8,
      '&::after': {
        content: '""',
        display: 'block',
        width: 4,
        height: 24,
        backgroundColor: '#373F41',
        position: 'absolute',
        bottom: 6,
        marginLeft: -14,
        left: 5,
      },
    },
    card: {
      width: '100%',
      height: 374,
      borderRadius: 0,
      position: 'relative',

    },
    tagName: {
      fontSize: 13,
      textTransform: 'capitalize',
      height: 30,
      background: colors.badgeColor,
      color: colors.textAccentColor,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0 25px 25px 0',
      padding: '5px 16px',
      width: 'max-content',
      position: 'absolute',
      marginTop: -16,

    },
    tagDate: {
      height: 24,
      color: colors.accentColor,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      paddingRight: 20,
      lineHeight: 16,
      fontSize: 14,
      width: 'max-content',
      marginTop: 33,

    },
    text: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(18),
      color: theme.palette.text.primary,
    },
    textResume: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(16),
      color: '#333',
      marginTop: 20,
      lineHeight: 24,
      opacity: '0.9px',

    },
    textMore: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      color: colors.accentColor,
      marginTop: 20,
      marginBottom: 15,
      textDecoration: 'underline',
    },
    BoxConfigMoz: {
      display: '-webkit-flex',
      flexDirection: 'column',
    },
    cardNews: {
      maxWidth: 336,
      margin: '0 10px 0px 0px',
      display: 'flex',
    },
    IconNavite: {
      color: colors.accentColor,
      width: 44,
      height: 44,
      border: `1px solid ${colors.accentColor}`,
      borderRadius: '4px 4px 4px 4px',
      '&:hover': {
        backgroundColor: colors.accentColor,
        color: colors.colorBackground,
      },
    },
    barDivider: {
      width: '100%',
      marginTop: 8,
      height: 4,
      backgroundColor: colors.textBody,
    },
    fullTitle: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 24,
      color: colors.textBody,
      textTransform: 'uppercase',
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const NextArrow = ({ onClick, style }: CustomArrowProps): JSX.Element => (
    <IconButton
      className={classes.IconNavite}
      color="primary"
      onClick={onClick}
      style={{
        position: 'absolute',
        left: 42,
        bottom: '-15%',
      }}
    >
      <MdNavigateNext style={{ width: 24, height: 24 }} />
    </IconButton>
  );

  const PrevArrow = ({ onClick, style }: CustomArrowProps): JSX.Element => (
    <IconButton
      className={classes.IconNavite}
      color="primary"
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: '-15%',
        left: 0,
      }}
    >
      <MdNavigateBefore style={{ width: 24, height: 24 }} />
    </IconButton>
  );
  const settings: Settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 549,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        width="100%"
        flexDirection="column"
      >
        <Box
          display="flex"
          maxWidth="1052px"
          width="100%"
          justifyContent="space-between"
          flexDirection="column"
          marginBottom="80px"
        >
          <Typography className={classes.fullTitle}>Notícias</Typography>
          <Box className={classes.barDivider} />
          {/* <Button
            style={{
              width: 'max-content',
              alignSelf: 'center',
            }}
            onClick={(): void => history.push('/noticias')}
            color="primary"
          >
            <Typography
              style={{
                marginRight: 8,
                marginLeft: 8,
                textTransform: 'uppercase',

              }}
            >
              Ver todas
            </Typography>
            <NavigateNextOutlined />
          </Button> */}
        </Box>
        <Box width="100%">
          <Slider {...settings}>
            {
            newsList.results.slice(0, 3).map((newItem) => (
              <Box className={classes.cardNews}>
                <Card key={newItem.id} style={{ backgroundColor: colors.colorBackgroundSecundary }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Noticias"
                      height="208"
                      image={newItem.imagem_destaque_url}
                      title="Noticias"
                    />
                    <Box
                      className={classes.tagName}
                    >
                      {newItem.categoria_titulo}
                    </Box>
                    <CardContent>

                      <Box
                        className={classes.tagDate}
                      >
                        <MdOutlineCalendarToday style={{ marginRight: 4, width: 20, fontSize: 24 }} />
                        {format(
                          parseISO(newItem.created_at),
                          "dd/MM/yyy' às ' hh'h'mm",
                          {
                            locale: ptBR,
                          },
                        )}

                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          marginTop: 20,
                          fontWeight: 500,
                          color: colors.textBody,
                        }}
                      >
                        {newItem.titulo}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={():void => history.push(`/noticias/${newItem.slug}`)}
                      className={classes.textMore}
                    >
                      Leia Mais
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            ))
          }
          </Slider>
        </Box>
      </Box>
    </Box>
  );
}
