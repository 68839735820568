import React, { useEffect, useState } from 'react';
import {
  Box, CircularProgress, Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import { ES } from '../Home';
import {
  Breadcrumb,
  PaginationService,
  ServiceList, Title,
} from './components';
import FilterAttendence from './components/FilterAttendence';
import { IParamsHistory } from '../../services/agendamento';

import { meusAtendimentos } from '../../services/atendimentos';
import NavBarLink from '../QuickAccess/components/NavBarLink';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    minHeight: 400,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
}));

interface Params {
  slug: string;
}

export interface IMyAttendences {
  id: string;
  cpf: string;
  status: string;
  orgao_responsavel: string | undefined;
  created_at: string;
  respostas: Array<{
    lido: boolean;
    user: {
      username: string;
    };
  }>
  guiche: {
    ativo: boolean;
    created_at: string;
    id: number;
    ordem: number;
    setor: number;
    tipo: string;
    updated_at: string;
    user: Array<number>;
  };
  servico: {
    acesso_externo: boolean;
    agendavel: boolean;
    ativo: boolean;
    created_at: string;
    custo: string;
    descricao: string;
    online: boolean;
    publico: string;
    setor: number;
    slug: string;
    tema: number;
    tempo: number;
    tempo_online: number;
    tempo_total: number;
    tipo_tempo: string;
    titulo: string;
    updated_at: string;
    url_externo: string | null;
    user: number;
  };
  unidade: {
    ativo: boolean;
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string | null;
    created_at: string;
    endereco: string;
    nome: string;
    orgao: number;
    slug_unidade: string;
    source: string;
    updated_at: string;
    user: number;
  };
  user: {
    first_name: string;
    id: number;
    last_name: string | null;
    username: string;
  };

}

export interface IAttendenceResponse extends ES {
  results: IMyAttendences[];
}

export default function MyAttendences(): JSX.Element {
  const classes = useStyles();
  const params: Params = useParams();
  const [loading, setLoading] = useState < boolean >(false);
  const [filterParams, setFilterParams] = useState < IParamsHistory | null >(null);
  const [myAttendencesData, setMyAttendencesData] = useState < IAttendenceResponse >();

  const getMyAttendences = async (page: number, params?: IParamsHistory): Promise<void> => {
    (window as any).scrollTo(0, 0);
    setMyAttendencesData(undefined);
    const token: string | null = localStorage.getItem('gov_access_token_siseci');
    setLoading(true);
    if (token) {
      try {
        const { data } = await meusAtendimentos(token, page, params);
        setMyAttendencesData(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (filterParams) {
      getMyAttendences(1, filterParams);
    } else {
      getMyAttendences(1);
    }
  }, [filterParams]);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Atendimentos" />
      <NavBarLink />
      <Title slug={params.slug} />
      <FilterAttendence setFilter={setFilterParams} />
      {loading && (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {myAttendencesData?.results.length ? (
        <>
          <ServiceList listAttendences={myAttendencesData} />
          <PaginationService getSearch={getMyAttendences} listSearch={myAttendencesData} />
        </>
      ) : null}
      {(myAttendencesData?.results.length === 0 && !loading) && (
        <>
          <Box
            marginBottom="60px"
            minHeight="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              style={{ textAlign: 'center' }}
              variant="h4"
            >
              Nenhum atendimento encontrado
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
