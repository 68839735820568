/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react';
import {
  Box, Typography, IconButton, MenuItem, Menu, Snackbar,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdMenu, MdAccountBalance } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  FaFacebookF, FaLink, FaTwitter, FaWhatsapp,
} from 'react-icons/fa';
import {
  FacebookShareButton, TwitterShareButton, WhatsappShareButton,
} from 'react-share';
import { OrgansI } from '../../../OrgansList';
import colorContext from '../../../../Context/colorContext';

interface Props {
  organ: OrgansI;
  listOrgan: {
    id: number;
    nome: string;
    sigla: string;
    slug: string;
  }[]
}

export default function Title({
  organ,
  listOrgan,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      height: 270,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
      marginBottom: 80,
      marginTop: 1,
    },
    container: {
      color: colors.textAccentColor,
      fontFamily: 'Roboto',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
      textAlign: 'left',
    },
    boxMenu: {
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
    },
    buttonMenu: {
      color: colors.textAccentColor,
      '&:hover': {
        background: 'none',
      },
    },
    textMenu: {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      textAlign: 'left',
    },
    boxIcon: {
      '&:hover': {
        background: 'none',
      },
    },
    boxSocialMedia: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    btnSocial: {
      color: theme.palette.text.primary,
      margin: '0 -4px',
    },
    menuOption: {
      color: colors.accentColor,
      textTransform: 'capitalize',
      whiteSpace: 'normal',
      backgroundColor: colors.colorBackground,
      '&:hover': {
        color: colors.accentColorBackground,
      },
    },
    time: {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
      color: colors.textAccentColor,
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const url: string = (window as any).location;
  const [copy, setCopy] = useState<boolean>(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrganFilter = (event: any) => {
    history.push(event.target.getAttribute('value'));
  };

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        alignItems="center"
        width="100%"
        justifyContent="center"
        flexDirection="column"
        className={classes.container}
      >
        <Box className={classes.boxMenu}>
          <IconButton aria-controls="simple-menu" classes={{ root: classes.buttonMenu }} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MdMenu />
            <Typography className={classes.textMenu}>Menu</Typography>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {listOrgan.map((list) => (
              <MenuItem
                key={list.id}
                value={list.slug}
                className={classes.menuOption}
                onClick={handleOrganFilter}
              >
                {list.nome.toLowerCase()}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <MdAccountBalance style={{ width: 60, height: 60, color: colors.textAccentColor }} />
        <Typography
          style={{
            textAlign: 'center',
            fontSize: 24,
            fontWeight: 'bold',
          }}
          className={classes.container}
        >
          {organ.sigla.toUpperCase()}
        </Typography>
        <Typography align="center" className={classes.container} style={{ marginTop: 20 }}>
          {organ.nome}
        </Typography>
        <Box className={classes.boxSocialMedia}>
          <Typography className={classes.time}>
            Compartilhe:
          </Typography>
          <FacebookShareButton url={url}>
            <IconButton color="primary">
              <FaFacebookF style={{ color: colors.textAccentColor, width: 16, height: 16 }} />
            </IconButton>
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <IconButton color="primary">
              <FaTwitter style={{ color: colors.textAccentColor, width: 16, height: 16 }} />
            </IconButton>
          </TwitterShareButton>
          <WhatsappShareButton url={url}>
            <IconButton color="primary">
              <FaWhatsapp style={{ color: colors.textAccentColor, width: 16, height: 16 }} />
            </IconButton>
          </WhatsappShareButton>
          <IconButton
            onClick={() => { navigator.clipboard.writeText(url); setCopy(true); }}
            color="primary"
          >
            <FaLink style={{ color: colors.textAccentColor, width: 16, height: 16 }} />
          </IconButton>
        </Box>
      </Box>
      <Snackbar
        open={copy}
        autoHideDuration={2000}
        onClose={(): void => setCopy(false)}
        message="Copiado para área de transferência"
      />
    </Box>
  );
}
