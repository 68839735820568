/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import { Jutsu } from 'react-jutsu';
import {
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserData } from '../../../../services/user';
import { UserDataProps } from '../../../AttendanceOnlineMobileRedirect';

interface Props {
  hideChat: boolean;
  showVideo: boolean;
  roomName: string;
  user?: UserDataProps;
}

const useStyles = makeStyles(() => ({
  main: {
    minWidth: '',
    height: '594px',
    backgroundColor: '#ccc',
    width: '100%',
    marginBottom: '50px',
  },
}));

const Video = ({
  hideChat, showVideo, roomName, user,
}: Props): JSX.Element => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:928px)');
  const dataStorage: string | null = localStorage.getItem('gov_user_data');
  const userData: UserData | null = dataStorage
    ? JSON.parse(dataStorage)
    : null;

  const jitsiConfig = {
    roomName,
    password: 'dattebayo',
    subject: 'fan',
    parentNode: 'jitsi-container',
    userInfo: {
      email: (userData || user).contato.email,
      displayName: (userData || user).nome.charAt(0).toUpperCase() + (userData || user).nome.slice(1),
    },
    interfaceConfigOverwrite: {
      MOBILE_APP_PROMO: false,
      SHOW_CHROME_EXTENSION_BANNER: false,
      SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile'],
      TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'etherpad', 'settings',
        'videoquality', 'filmstrip', 'stats', 'shortcuts',
        'tileview', 'download', 'help',
      ],
    },
    configOverwrite: {
      enableCalendarIntegration: false,
      disableDeepLinking: true,
      startWithAudioMuted: false,
      defaultLanguage: 'pt-br',
      disableInviteFunctions: true,
      disableProfile: true,
      enableWelcomePage: true,
    },
  };

  return (
    <Box
      className={classes.main}
      minWidth={matches ? '0px' : '650px'}
      marginRight={matches || !hideChat ? '0px' : '10px'}
      display={showVideo ? 'flex' : 'none'}
      hidden={false}
    >
      <Jutsu
        domain={(window as any)._env_.REACT_APP_MEET_DOMAIN ?? 'meet.jit.si'}
        containerStyles={{ width: '100%', height: '100%' }}
        roomName={jitsiConfig.roomName}
        password={jitsiConfig.password}
        displayName={jitsiConfig.userInfo.displayName}
        onMeetingEnd={() => console.log('Meeting has ended')}
        loadingComponent={<p>loading ...</p>}
        errorComponent={<p>Oops, something went wrong</p>}
        configOverwrite={jitsiConfig.configOverwrite}
        interfaceConfigOverwrite={jitsiConfig.interfaceConfigOverwrite}
      />
    </Box>
  );
};
export default memo(Video);
