const imgCidadao = '/assets/images/cidadao.svg';
const imgEmpresa = '/assets/images/empresa.svg';
const imgServidor = '/assets/images/servidor.svg';

const typeServices = {
  cidadao: {
    title: 'Cidadão',
    color: '#18A54B',
    cardColor: '#195128',
    img: imgCidadao,
    colorHighlights: '#9FB144',
    background: 'rgba(0, 158, 117, 0.1)',
    apiURL: 'Cidadão',
    nameLowerCase: 'cidadao',
  },
  empresa: {
    title: 'Empresa',
    color: 'rgba(247, 197, 20, 0.8)',
    cardColor: 'rgba(247, 197, 20, 0.8)',
    img: imgEmpresa,
    colorHighlights: '#F7C514',
    background: 'rgba(247, 197, 20, 0.3)',
    apiURL: 'Empresa',
    nameLowerCase: 'empresa',
  },
  servidor: {
    title: 'Servidor',
    color: '#032876',
    cardColor: '#032876',
    img: imgServidor,
    colorHighlights: '#062379',
    background: 'rgba(15, 111, 183, 0.3)',
    apiURL: 'Servidor',
    nameLowerCase: 'servidor',
  },
};

export default typeServices;
