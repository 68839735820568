import React, { useContext, useState } from 'react';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MdEventAvailable, MdMouse } from 'react-icons/md';
import { SearchI, ServicoUnidadeI } from '../../../SearchService';
import colorContext from '../../../../Context/colorContext';

interface Props {
  service: SearchI;
}

export default function Localization({
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tag: {
      backgroundColor: colors.accentColor,
      color: colors.textAccentColor,
      padding: '5px 16px',
      display: 'flex',
      alignItems: 'center',
      height: 30,
      borderRadius: 25,
      margin: '0px 8px',
    },
    labelText: {
      color: colors.textBody,
      fontWeight: 'normal',
      '&:hover': {
        color: colors.accentColor,
      },
    },
    boxTitle: {
      width: '100%',
      padding: '0 5px',
    },
    textTitle: {
      marginTop: 120,
      fontWeight: 700,
      fontFamily: 'Roboto',
      fontSize: 24,
      textTransform: 'uppercase',
      color: colors.textBody,
    },
    mainLocation: {
      margin: 24,
      width: '100%',
    },
    cardLocalization: {
      width: '100%',
      maxWidth: 688,
      borderRadius: 4,
      backgroundColor: colors.colorBackgroundSecundary,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      border: `1px solid ${colors.borderShadowsColor}`,
    },
    localeTitle: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 18,
      marginBottom: 20,
      color: colors.textBody,
    },
    localizationTitle: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 16,
      color: colors.textBody,
      marginTop: 16,
    },
    localizationSubTitle: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 16,
      color: colors.textBody,
      margin: '5px 0px',
    },
    colorIcon: {
      color: colors.textBody,
    },
    radio: {
      color: colors.accentColor,
      '&.Mui-checked': {
        color: colors.accentColor,
      },
    },
    tagBox: {
      backgroundColor: 'rgba(15, 111, 183, 0.05)',
      color: colors.accentColor,
      padding: '5px 16px 5px 4px',
      marginBottom: 10,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      height: 30,
      borderRadius: 25,
      '@media (max-width: 660px)': {
        padding: '2px 5px',
      },
    },
    textTag: {
      display: 'block',
      '@media (max-width: 660px)': {
        display: 'none',
      },
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 700,
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
  }));
  const classes = useStyles();
  const [unidadeSelected, setUnidadeSelected] = useState<ServicoUnidadeI>(
    service.servicosUnidade[0],
  );
  const matches = useMediaQuery('(min-width:865px)');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unidadeClick = service.servicosUnidade.filter(
      // eslint-disable-next-line radix
      (unidade) => unidade.id === parseInt(event.target.value),
    );
    setUnidadeSelected(unidadeClick[0]);
  };

  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1440px"
        padding="0px 15px"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
        marginBottom="80px"
      >
        <Box className={classes.boxTitle}>
          <Typography
            className={classes.textTitle}
          >
            Onde solicitar
          </Typography>
          <Box
            height="4px"
            width="100%"
            marginTop="5px"
            marginBottom="56px"
            style={{
              backgroundColor: colors.textBody,
            }}
          />
        </Box>

        <Box
          flexDirection={matches ? 'row' : 'column'}
          display="flex"
          width="100%"
          justifyContent="center"
        >
          <Card style={{
            width: matches ? 327 : '100%',
            height: 300,
            borderRadius: 4,
            border: `1px solid ${colors.borderShadowsColor}`,
            boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
            backgroundColor: colors.colorBackgroundSecundary,
          }}
          >
            <Box>
              <Typography
                style={{
                  marginLeft: 24,
                  marginTop: 30,
                  color: colors.textBody,
                  fontWeight: 500,
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  marginBottom: 20,
                }}
              >
                {/* {unidadeSelected.unidade.nome.toLowerCase()} */}
                Selecione a unidade
              </Typography>
            </Box>
            <Box
              maxHeight="100%"
              display="flex"
              flexDirection="column"
              paddingLeft="24px"
              style={{
                overflowY: 'auto',
              }}
            >
              <FormControl style={{ marginBottom: 40, height: 182 }} component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={unidadeSelected.id}
                  onChange={handleChange}
                >
                  {service.servicosUnidade.map((unidade) => (
                    <FormControlLabel
                      key={unidade.id}
                      className={classes.labelText}
                      value={unidade.id}
                      control={<Radio className={classes.colorIcon} classes={{ root: classes.radio }} />}
                      label={<Typography style={{ fontWeight: 'normal', textTransform: 'capitalize' }}>{unidade.unidade.nome.toLowerCase()}</Typography>}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Card>
          <Box className={classes.cardLocalization}>
            <Box className={classes.mainLocation}>
              <Typography className={classes.localeTitle}>{unidadeSelected.unidade.nome}</Typography>
              <Box style={{ marginBottom: 15 }}>
                {unidadeSelected.unidade.source.indexOf('https://www.google.com/maps/embed?') !== -1 ? (
                  <iframe
                    width="100%"
                    height="226px"
                    src={unidadeSelected.unidade.source}
                    frameBorder="0"
                    title="endereço"
                  />
                ) : (
                  <Typography
                    style={{
                      color: colors.textBody,
                      marginLeft: 20,
                    }}
                  >
                    Não foi possível carregar o mapa.
                  </Typography>

                )}
              </Box>
              <Box>
                <Typography className={classes.localizationTitle}>Endereço</Typography>
                <Typography className={classes.localizationSubTitle}>
                  {unidadeSelected.unidade.endereco}
                  {' '}
                  -
                  {' '}
                  {unidadeSelected.unidade.bairro}
                  {' '}
                  -
                  {' '}
                  {unidadeSelected.unidade.complemento && (
                    <>
                      {unidadeSelected.unidade.complemento}
                    </>
                  )}
                </Typography>
                <Typography className={classes.localizationTitle}>Tags</Typography>
                <Typography className={classes.localizationSubTitle}>
                  <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    {unidadeSelected.agendamento && (
                    <Box className={classes.tagBox}>
                      <MdEventAvailable style={{ width: 24, height: 24 }} />
                      <Typography
                        className={classes.textTag}
                        style={{ marginLeft: 4 }}
                      >
                        Agendável
                      </Typography>
                    </Box>
                    )}
                    {unidadeSelected.atendimento && (
                    <Box className={classes.tagBox}>
                      <MdMouse style={{ width: 24, height: 24 }} />
                      <Typography
                        className={classes.textTag}
                        style={{ marginLeft: 4 }}
                      >
                        Digital
                      </Typography>
                    </Box>
                    )}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
