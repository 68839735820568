/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

interface ColorContextProps {
  typeTheme: string;
  colors: {
    accentColor: string;
    accentColorBackground: string;
    colorBackground: string;
    colorBackgroundSecundary: string;
    textBody: string;
    textAccentColor: string;
    badgeColor: string;
    cardAccentColor: string;
    accordionTextColor: string;
    serviceCardBackgroundColor: string;
    favoriteBoxColor: string;
    breadcrumbButtonColorHover: string;
    borderShadowsColor: string;
    buttonSuccessColor: string;
    buttonErrorColor: string;
    accentColorSecondary: string;
    iconStepColor: string;
    stepLabelColor: string;
    menuItemColor: string;
    subTitleColor: string;
    colorBackgroundSearch: string;
  };
  setTypeTheme?: (type: string) => void;
}

const colorContext = createContext<ColorContextProps>({
  typeTheme: 'normal',
  colors: {
    accentColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    accentColorBackground: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    colorBackground: '#E8EBEF',
    colorBackgroundSecundary: '#ffffff',
    textBody: '#6B6D6E',
    textAccentColor: '#ffff',
    badgeColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    cardAccentColor: (window as any)._env_.REACT_APP_COLOR_PRIMARY,
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: '#00A97A',
    iconStepColor: '#94C120',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#706E7A',
    colorBackgroundSearch: '#FAFCF9',
  },
});

export default colorContext;
