import React, { useContext } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import './style.css';
import colorContext from '../../../../Context/colorContext';

interface Props {
  content: string;
}

export default function ContentNew({
  content,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: '1052px',
      backgroundColor: colors.colorBackground,
      marginBottom: 40,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
    },
    text: {
      color: colors.textBody,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
    },
    time: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textBody,
      fontFamily: 'Roboto',
      fontWeight: 500,
      textAlign: 'justify',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Typography
        dangerouslySetInnerHTML={{ __html: content }}
        className={classes.time}
      />
    </Box>
  );
}
