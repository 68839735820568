import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colorContext from '../../../../Context/colorContext';

interface Props {
  organ: string;
  category: string;
  tags: string;
}

export default function OriginNew({
  organ,
  category,
  tags,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const [tagsItem, setTagsItems] = useState<Array<string>>([]);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: '1052px',
      backgroundColor: colors.colorBackground,
      marginBottom: 60,
      '& .Mui-selected': {
        color: '#fff',
        borderRadius: 0,
      },
    },
    text: {
      color: colors.textBody,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
    },
    time: {
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(18),
      color: colors.textBody,
    },
    timeCategoria: {
      display: 'flex',
      fontFamily: 'Roboto',
      fontSize: 20,
      fontWeight: 500,
      alignItems: 'center',
      color: colors.textBody,
      margin: '8px 0px',
    },
    tagBox: {
      backgroundColor: 'rgba(15, 111, 183, 0.05)',
      color: colors.accentColor,
      padding: '5px 16px 5px 4px',
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      height: 30,
      borderRadius: 25,
      '@media (max-width: 660px)': {
        padding: '2px 5px',
      },
    },
    textTag: {
      display: 'block',
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 700,
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
    textInfo: {
      textTransform: 'uppercase',
      color: colors.accentColor,
      marginLeft: 5,
      fontSize: 16,
      fontWeight: 500,
    },
  }));

  function handleTagFormatting(unformatted_tags: string) {
    const tagsFormatted = unformatted_tags.split(',');
    setTagsItems(tagsFormatted);
  }

  useEffect(() => {
    if (tags) handleTagFormatting(tags);
  }, [tags]);

  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box display="flex" flexDirection="column">
        {organ && (
          <Typography className={classes.time}>
            <span style={{ fontWeight: 'bold' }}>Órgão: </span>
            {organ}
          </Typography>
        )}
        <Box className={classes.timeCategoria}>
          <Typography>
            Categoria:
          </Typography>
          <Box className={classes.tagBox}>
            <Typography
              className={classes.textTag}
              style={{ marginLeft: 4 }}
            >
              {category}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.timeCategoria}>
          <Typography>
            Tags:
          </Typography>
          {tagsItem.length === 0
            && (
              <Box className={classes.tagBox}>
                <Typography
                  className={classes.textTag}
                  style={{ marginLeft: 4 }}
                >
                  Não informada.
                </Typography>
              </Box>
            )}
          {tagsItem && tagsItem.map((tag) => (
            <Box className={classes.tagBox}>
              <Typography
                className={classes.textTag}
                style={{ marginLeft: 4 }}
              >
                {tag.trim()}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
