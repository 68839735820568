import React, { useContext } from 'react';
import {
  Box, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaTwitter,
} from 'react-icons/fa';
import colorContext from '../../Context/colorContext';
import theme from '../../services/theme-service';

export default function Footer(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 120,
      display: 'flex',
      justifyContent: 'center',
      background: colors.accentColorSecondary,
      alignItems: 'center',
    },
    container: {
      maxWidth: 1440,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '0px 15px',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
    },
    contentLogo: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      height: '100%',
    },
    logo: {
      maxWidth: '14.042rem',
      minHeight: '3.75rem',
    },
    contentSocials: {
      display: 'flex',
      flex: 1,
    },
    button: {
      padding: 0,
      width: 30,
      height: 30,
      margin: '0 0.625rem',
    },
    icon: {
      color: colors.textAccentColor,
      fontSize: '1.875rem',
    },
  }));
  const classes = useStyles();
  const linkFace = (window as any)._env_.REACT_APP_LINK_FACEBOOK ?? '';
  const linkInsta = (window as any)._env_.REACT_APP_LINK_INSTAGRAM ?? '';
  const linkYoutube = (window as any)._env_.REACT_APP_LINK_YOUTUBE ?? '';
  const linkTwitter = (window as any)._env_.REACT_APP_LINK_TWITTER ?? '';

  return (
    <Box className={classes.main}>
      <Box className={classes.container}>
        <Box className={classes.contentLogo}>
          <img
            alt="portal logo"
            className={classes.logo}
            src={(window as any)._env_.REACT_APP_LOGO_FOOTER}
          />
        </Box>
        <Box className={classes.contentSocials}>
          <IconButton
            className={classes.button}
            href={linkFace}
            target="_blank"
          >
            <FaFacebook className={classes.icon} />
          </IconButton>
          <IconButton
            className={classes.button}
            href={linkInsta}
            target="_blank"
          >
            <FaInstagram className={classes.icon} />
          </IconButton>
          <IconButton
            className={classes.button}
            href={linkTwitter}
            target="_blank"
          >
            <FaTwitter className={classes.icon} />
          </IconButton>
          <IconButton
            className={classes.button}
            href={linkYoutube}
            target="_blank"
          >
            <FaYoutube className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
