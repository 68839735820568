import React, { useContext, useEffect, useState } from 'react';
import {
  Box, CircularProgress,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Breadcrumb,
  SocialAndAuthor,
  Title,
  ImageOrVideo,
  ContentNew,
  OriginNew,
  ContentRelation,
} from './components';
import { News, NewsI } from '../News';
import { getNew, getNewForCategory } from '../../services/noticias';
import colorContext from '../../Context/colorContext';

interface Params {
  slug: string;
}

export default function ServiceInfo(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [newDetail, setNewDetail] = useState < NewsI >();
  const [newForCategory, setNewForCategory] = useState < News >();
  const [loading, setLoading] = useState < boolean >(false);
  const getNewEspecific = async () => {
    setNewDetail(undefined);
    setLoading(true);

    try {
      const { data } = await getNew(params.slug);
      console.log(data);
      setNewDetail(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getNewForCategorySlug = async (category_slug: string) => {
    setNewForCategory(undefined);
    setLoading(true);

    try {
      const { data } = await getNewForCategory(category_slug, 1);
      setNewForCategory(data);
      console.log(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNewEspecific();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    if (newDetail) {
      getNewForCategorySlug(newDetail.categoria_slug);
    }
  }, [newDetail]);

  const url: string = (window as any).location;

  return (
    <Box className={classes.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{newDetail?.titulo}</title>
        <link rel="canonical" href={url} />
        <meta property="og:image" content={newDetail?.imagem_destaque_url} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:title" content={newDetail?.titulo} />
        <meta property="og:url" content={url} />
        <meta name="author" content="DC4G" />
      </Helmet>
      {loading && (
        <Box
          marginBottom="60px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {newDetail && !loading && newForCategory?.results.length && (
        <>
          <Breadcrumb slug={newDetail.titulo} />
          <Title resume={newDetail.resumo} title={newDetail.titulo} />
          <SocialAndAuthor
            slug={newDetail.slug}
            resume={newDetail.resumo}
            author={newDetail.autor_imagem}
            created={newDetail.created_at}
            updated={newDetail.updated_at}
          />
          <Box padding="10px">
            <ImageOrVideo
              detailImage={newDetail.descricao_imagem}
              detailVideo={newDetail.descricao_video}
              image={newDetail.imagem_destaque_url}
              video={newDetail.youtube_videoId}
            />
            <ContentNew content={newDetail.conteudo} />
            <OriginNew
              organ={newDetail.orgao_nome}
              category={newDetail.categoria_titulo}
              tags={newDetail.tags_noticia}
            />
            <ContentRelation newsRelation={newForCategory} />

          </Box>
        </>
      )}
    </Box>
  );
}
